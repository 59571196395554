import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SectionA from './Components/SectionA';
import SectionB from './Components/SectionB';
import SectionC from './Components/SectionC';
import SectionD from './Components/SectionD';
import SectionE from './Components/SectionE-Pricing';
import FAQs from './Components/Faqs';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import sessionstorage from "sessionstorage";

function HomeNew() {
  const [width, setWidth] = useState(window.innerWidth);
  const { user } = useSelector((state) => state.auth);

  const navigateTo = useNavigate();

  useEffect(() => {
    if (user) {
      sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")

      navigateTo('/dashboard')
    }
  }, [user]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  
  const scrollToElement = (elementId) => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      const offset = 0;
      const targetPosition = targetElement.offsetTop + offset;

      window.scroll({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };
  
  useEffect(()=>{
    const navScroll = localStorage.getItem('navScroll');
    if(navScroll){
      scrollToElement(navScroll);
      localStorage.removeItem('navScroll');
    }
  },[])


  return (
    <div>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionD />
      <SectionE />
      <FAQs />
    </div>
  );
}

export default HomeNew;
