import React, { useState, useEffect } from 'react'
import OnBoardingSuccessPopup from '../../Global-Components/OnBoardingSuccessPopup/OnBoardingSuccessPopup';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import backend from '../../api'

export default function CompanyIndependent() {
    const [onBoardingSuccessPopup, setonBoardingSuccessPopup] = useState(false);
    const handleCloseOnboardingSuccessPopup = () => {
        setonBoardingSuccessPopup(false);
    }

    const onBoarding = useSelector((state) => state.companyInfo.info);
    const companyId = onBoarding?._id;

    useEffect(() => {
        const handleOnboarding = async () => {
            if (onBoarding?.isFirstInteraction) {
                setonBoardingSuccessPopup(true);
            }
        };

        handleOnboarding();
    }, [onBoarding]);

    const popup = localStorage.getItem("popup")

    useEffect(() => {
        if (popup === true) {
            setonBoardingSuccessPopup(true)
        }
    }, [popup])

    return (
        <div>
            <AnimatePresence>
                {onBoardingSuccessPopup && (
                    <OnBoardingSuccessPopup closePopup={handleCloseOnboardingSuccessPopup} />
                )}
            </AnimatePresence>
        </div>
    )
}
