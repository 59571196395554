import React, { useState, useRef, useEffect } from 'react'
import Classes from './DropDown.module.css'
import { AnimatePresence, motion } from 'framer-motion';

export default function DropDown({ selection, handleAccApprovedClick, handleAccRejectedClick, handleCompApprovedClick, handleCompRejectedClick, handleInvestApprovedClick, handleInvestRejectedClick }) {
  const [dropDown, setdropDown] = useState(false);
  const handleClosedropdown = () => {
    setdropDown(false);
  }

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setdropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={Classes.dropDown}
      ref={dropdownRef}
    >
      {/* Dropdown Head */}
      <div
        className={Classes.dropDownHead}
        onClick={() => setdropDown(!dropDown)}
      >
        <p style={{ textTransform: 'capitalize' }} >Select template</p>
        <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
      </div>

      {/* Dropdown Body */}
      <AnimatePresence>
        {dropDown && (
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{
              duration: 0.15,
            }}
            className={Classes.dropDownBody}
          >
            <div className={Classes.ddPointerCon}>
              <div className={Classes.dropDownPointer}></div>
            </div>
            <div className={Classes.dropDownBodyInner}>
              {/* Dropdown Options */}
              <div
                className={`${Classes.dropDownElem} ${selection === 'accApproved' || selection === 'accRejected' ? Classes.activeDropElem : ''}`}
              >
                <h5>Accelerator</h5>
                <div className={Classes.dropDownBody2} >
                  <div className={Classes.dropDownBodyInner}>
                    <div
                      className={`${Classes.dropDownElem} ${selection === 'accApproved' ? Classes.activeDropElem : ''}`}
                      onClick={() => { handleAccApprovedClick(); setdropDown(false) }}
                    >
                      <h5>Approve</h5>

                    </div>
                    <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                    <div
                      className={`${Classes.dropDownElem} ${selection === 'accRejected' ? Classes.activeDropElem : ''}`}
                      onClick={() => { handleAccRejectedClick(); setdropDown(false) }}
                    >
                      <h5>Reject</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
              <div
                className={`${Classes.dropDownElem} ${selection === 'compApproved' || selection === 'compRejected' ? Classes.activeDropElem : ''}`}
              >
                <h5 >Competition</h5>
                <div className={Classes.dropDownBody2} >
                  <div className={Classes.dropDownBodyInner}>
                    <div
                      className={`${Classes.dropDownElem} ${selection === 'compApproved' ? Classes.activeDropElem : ''}`}
                      onClick={() => { handleCompApprovedClick(); setdropDown(false) }}
                    >
                      <h5>Approve</h5>

                    </div>
                    <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                    <div
                      className={`${Classes.dropDownElem} ${selection === 'compRejected' ? Classes.activeDropElem : ''}`}
                      onClick={() => { handleCompRejectedClick(); setdropDown(false) }}
                    >
                      <h5>Reject</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
              <div
                className={`${Classes.dropDownElem} ${selection === 'investApproved' || selection === 'investRejected' ? Classes.activeDropElem : ''}`}
              >
                <h5>Investment Firm</h5>
                <div className={Classes.dropDownBody2} >
                  <div className={Classes.dropDownBodyInner}>
                    <div
                      className={`${Classes.dropDownElem} ${selection === 'investApproved' ? Classes.activeDropElem : ''}`}
                      onClick={() => { handleInvestApprovedClick(); setdropDown(false) }}
                    >
                      <h5>Approve</h5>

                    </div>
                    <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                    <div
                      className={`${Classes.dropDownElem} ${selection === 'investRejected' ? Classes.activeDropElem : ''}`}
                      onClick={() => { handleInvestRejectedClick(); setdropDown(false) }}
                    >
                      <h5>Reject</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Hidden input for accessibility */}
      <input
        type="hidden"
        value={selection}
        aria-label="Without label"
        readOnly
      />
    </div>
  )
}
