import React, { useEffect, useState } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import backend from '../../api';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';
import { Editor, EditorState, RichUtils, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import DropDown from './Comp/DropDown';
import { motion } from 'framer-motion'

const SvgX = (props) => (
    <svg
        id="fi_2723639"
        viewBox="0 0 32 32"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 3"
        {...props}
        fill='white'
        cursor='pointer'
    >
        <path d="M17.414 16l6.293-6.293a1 1 0 0 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a1 1 0 0 0 1.414-1.414z" />
    </svg>
);

const SvgArrow = (props) => (
    <svg {...props} fill='white' height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_10322482"><path d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z"></path></svg>
);

const AcceleratorApprovedrawContent = {
    blocks: [
        {
            key: '3',
            text: 'Dear [Recipient\'s Name],',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 5, style: 'BOLD' }],
        },
        {
            key: '2',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '4',
            text:
                'We are excited to inform you that [Company Name] has been selected to join the [Cohort Name/Year] of the [Accelerator Name] program! Your innovative vision and business potential stood out, and we are thrilled to support you as you continue to grow and scale.',
            type: 'unstyled',
        },
        {
            key: '25',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '5',
            text: 'What’s Next?',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 12, style: 'BOLD' }],
        },
        {
            key: '26',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '6',
            text: 'Program Start Date: [Insert Date]',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 19, style: 'BOLD' }],
        },
        {
            key: '7',
            text: 'Kickoff Event: [Insert Date and Time] (details will follow)',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 13, style: 'BOLD' }],
        },
        {
            key: '27',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '8',
            text: 'Key Program Benefits:',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 20, style: 'BOLD' }],
        },
        {
            key: '9',
            text: 'Access to top mentors in the industry',
            type: 'unordered-list-item',
        },
        {
            key: '10',
            text: 'Tailored workshops and resources',
            type: 'unordered-list-item',
        },
        {
            key: '11',
            text: 'Networking with investors, potential partners, and fellow founders',
            type: 'unordered-list-item',
        },
        {
            key: '12',
            text: '[Insert any additional program benefits]',
            type: 'unordered-list-item',
        },
        {
            key: '13',
            text:
                'We’ll be sending you more details in the coming days, including an onboarding package to help you prepare for the program. Please confirm your participation by [Insert Date] by replying to this email or completing [this form/link].',
            type: 'unstyled',
        },
        {
            key: '28',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '14',
            text:
                'Once again, congratulations! We’re looking forward to supporting [Company Name] on your journey to success.',
            type: 'unstyled',
        },
        {
            key: '29',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '15',
            text: 'If you have any questions, feel free to reach out to [Contact Person] at [Contact Information].',
            type: 'unstyled',
        },
        {
            key: '30',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '16',
            text: 'Warm regards,\n[Your Full Name]',
            type: 'unstyled',
        },
    ],
    entityMap: {},
};

const AcceleratorRejectedrawContent = {
    blocks: [
        {
            key: '1',
            text: 'Dear [Recipient\'s Name],',
            type: 'unstyled',
            inlineStyleRanges: [
                { offset: 0, length: 6, style: 'BOLD' }, // Bold for "Email:"
            ],
        },
        {
            key: '2',
            text: '', // Blank line after the greeting
            type: 'unstyled',
        },
        {
            key: '3',
            text: 'Thank you for submitting your application to the [Cohort Name/Year] of the [Accelerator Name] program. After careful consideration, we regret to inform you that [Company Name] has not been selected for this cohort.',
            type: 'unstyled',
            inlineStyleRanges: [],
        },
        {
            key: '4',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '5',
            text: 'This decision in no way reflects a lack of potential. In fact, we were impressed by your vision and the work you’re doing in [Industry/Space]. Unfortunately, due to the high volume of competitive applications, we had to make some difficult decisions.',
            type: 'unstyled',
            inlineStyleRanges: [],
        },
        {
            key: '6',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '7',
            text: 'While we couldn’t offer you a spot in this cohort, we encourage you to keep pushing forward. There are plenty of other opportunities ahead, and we\'d love to stay in touch. We invite you to reapply for future programs or explore any upcoming events we offer that could provide value to your company.',
            type: 'unstyled',
            inlineStyleRanges: [],
        },
        {
            key: '8',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '9',
            text: 'If you’d like more detailed feedback on your application, feel free to reach out, and we’d be happy to provide some insights.',
            type: 'unstyled',
            inlineStyleRanges: [],
        },
        {
            key: '10',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '11',
            text: 'Thank you again for your interest in [Accelerator Name]. We look forward to following [Company Name]\'s growth and success!',
            type: 'unstyled',
            inlineStyleRanges: [],
        },
        {
            key: '12',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '13',
            text: 'Wishing you all the best,\n[Your Full Name]',
            type: 'unstyled',
            inlineStyleRanges: [],
        },
    ],
    entityMap: {},
}

const CompetitionApprovedrawContent = {
    blocks: [
        {
            key: '1',
            text: 'We’re excited to announce that [Company Name] has been selected to participate in the upcoming [Pitch Competition Name]! Your application stood out, and we’re thrilled to give you the opportunity to showcase your innovative idea in front of our panel of judges, investors, and industry leaders.',
            type: 'unstyled',
        },
        {
            key: '2',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '3',
            text: 'Here’s what to expect next:',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 28, style: 'BOLD' }],
        },
        {
            key: '4',
            text: 'Competition Date: [Insert Date]',
            type: 'unordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 18, style: 'BOLD' }],
        },
        {
            key: '5',
            text: 'Venue/Location: [Insert Location or Virtual Details]',
            type: 'unordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 16, style: 'BOLD' }],
        },
        {
            key: '6',
            text: 'Pitch Guidelines: You will have [Insert Time Limit] to present your pitch, followed by [Insert Time for Q&A, if applicable].',
            type: 'unordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 16, style: 'BOLD' }],
        },
        {
            key: '7',
            text: 'Next Steps: We will send you additional details about the event, including your pitch time slot, in the coming days.',
            type: 'unordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 11, style: 'BOLD' }],
        },
        {
            key: '9',
            text: 'Key Dates:',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 10, style: 'BOLD' }],
        },
        {
            key: '10',
            text: 'Final submission for any presentation materials: [Insert Deadline]',
            type: 'unordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 45, style: 'BOLD' }],
        },
        {
            key: '11',
            text: 'Mandatory pitch rehearsal: [Insert Date, if applicable]',
            type: 'unordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 26, style: 'BOLD' }],
        },
        {
            key: '13',
            text: 'We are excited to see what [Company Name] brings to the competition! If you have any questions or need further information, feel free to reach out to [Contact Person] at [Contact Information].',
            type: 'unstyled',
        },
        {
            key: '14',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '15',
            text: 'Once again, congratulations, and we look forward to seeing you at [Pitch Competition Name]!',
            type: 'unstyled',
        },
        {
            key: '16',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '17',
            text: 'Best regards,\n[Your Full Name]',
            type: 'unstyled',
        },
    ],
    entityMap: {},
};

const CompetitionRejectedrawContent = {
    blocks: [
        {
            key: '1',
            text: 'Dear [Recipient\'s Name],',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 5, style: 'BOLD' }],
        },
        {
            key: '2',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '3',
            text: 'Thank you for submitting your pitch to the [Pitch Competition Name]. After reviewing many impressive applications, we regret to inform you that [Company Name] was not selected to participate in this year\'s competition.',
            type: 'unstyled',
        },
        {
            key: '4',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '5',
            text: 'This decision was a tough one, given the quality of your application and the exciting work you\'re doing in [Industry/Field]. Unfortunately, due to the competitive nature of the selection process, we were unable to extend an invitation to all applicants.',
            type: 'unstyled',
        },
        {
            key: '6',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '7',
            text: 'We encourage you to keep moving forward with your vision, and we’d love to see you reapply for future competitions. Your work has tremendous potential, and we\'re excited to see where you take it.',
            type: 'unstyled',
        },
        {
            key: '8',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '9',
            text: 'If you would like feedback on your application or have any questions, please don’t hesitate to reach out to us at [Contact Information]. We’d be happy to provide insights that may help you in future opportunities.',
            type: 'unstyled',
        },
        {
            key: '10',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '11',
            text: 'Thank you again for your interest, and we wish [Company Name] continued success.',
            type: 'unstyled',
        },
        {
            key: '12',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '13',
            text: 'Best regards,\n[Your Full Name]',
            type: 'unstyled',
        },
    ],
    entityMap: {},
}

const InvestmentApprovedrawContent = {
    blocks: [
        {
            key: '1',
            text: 'Dear [Recipient\'s Name],',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 5, style: 'BOLD' }],
        },
        {
            key: '2',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '3',
            text: 'Congratulations again on your recent approval for investment from [Investment Firm Name]! We are excited to move forward and support [Company Name] on your journey to success.',
            type: 'unstyled',
        },
        {
            key: '4',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '5',
            text: 'Next Steps:',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 10, style: 'BOLD' }],
        },
        {
            key: '7',
            text: 'Documentation: We will send you the necessary investment agreements and legal documents by [Insert Date]. Please review these carefully and reach out if you have any questions.',
            type: 'ordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 14, style: 'BOLD' }],
        },
        {
            key: '8',
            text: 'Initial Funding: Once the documents are signed, we will initiate the transfer of funds. Expect to see the funds in your account by [Insert Timeline].',
            type: 'ordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 15, style: 'BOLD' }],
        },
        {
            key: '9',
            text: 'Kickoff Meeting: We would like to schedule a kickoff meeting to discuss our partnership, key milestones, and any immediate support you may need. Please let us know your availability for the week of [Insert Date].',
            type: 'ordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 16, style: 'BOLD' }],
        },
        {
            key: '10',
            text: 'Ongoing Communication: We believe in maintaining open communication. Our team will check in regularly to offer support and discuss progress, but feel free to reach out anytime with updates or questions.',
            type: 'ordered-list-item',
            inlineStyleRanges: [{ offset: 0, length: 22, style: 'BOLD' }],
        },
        {
            key: '12',
            text: 'We are thrilled to partner with you and look forward to seeing [Company Name] thrive! If you have any questions or need further clarification, please don’t hesitate to contact me at [Contact Information].',
            type: 'unstyled',
        },
        {
            key: '13',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '14',
            text: 'Best regards,\n[Your Full Name]',
            type: 'unstyled',
        },
    ],
    entityMap: {},
};

const InvestmentRejectedrawContent = {
    blocks: [
        {
            key: '1',
            text: 'Dear [Recipient\'s Name],',
            type: 'unstyled',
            inlineStyleRanges: [{ offset: 0, length: 5, style: 'BOLD' }],
        },
        {
            key: '2',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '3',
            text: 'Thank you for submitting your proposal to [Investment Firm Name]. After careful consideration, we regret to inform you that we will not be moving forward with an investment in [Company Name] at this time.',
            type: 'unstyled',
        },
        {
            key: '4',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '5',
            text: 'This decision was difficult due to the high quality of the applications we received. While we were impressed by your vision and the potential of your business, we have to be selective in our investment choices to ensure alignment with our current strategic focus.',
            type: 'unstyled',
        },
        {
            key: '6',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '7',
            text: 'We encourage you to keep pursuing your goals and consider reapplying in the future. Your work has significant merit, and we would love to stay in touch as you continue to grow.',
            type: 'unstyled',
        },
        {
            key: '8',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '9',
            text: 'If you would like feedback on your proposal or have any questions, please feel free to reach out. We’re happy to provide insights that may help you in your future endeavors.',
            type: 'unstyled',
        },
        {
            key: '13',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '10',
            text: 'Thank you once again for your interest in [Investment Firm Name]. We wish you and [Company Name] all the best in your journey ahead.',
            type: 'unstyled',
        },
        {
            key: '11',
            text: '', // Blank line
            type: 'unstyled',
        },
        {
            key: '12',
            text: 'Warm regards,\n[Your Full Name]',
            type: 'unstyled',
        },
    ],
    entityMap: {},
};

function EmailEditorPopup({ closePopup, success, userEmail, userCompany, decision }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [subject, setsubject] = useState('');
    const [email, setemail] = useState('');
    const [selection, setselection] = useState('');
    const [loading, setLoading] = useState(false);

    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    usePreventScrolling();

    // Initialize the editor with the default content
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    // Handle style changes (e.g., bold, italic)
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    // Apply inline style (bold, italic, underline)
    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    // Apply block type (bullet or numbered list)
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const handleUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };

    const handleRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const handleAccApprovedClick = () => {
        setsubject(`Congratulations! You're Approved to Join Our Upcoming Cohort`);
        setEditorState(EditorState.createWithContent(convertFromRaw(AcceleratorApprovedrawContent)));
        setselection('accApproved');
    }

    const handleAccRejectedClick = () => {
        setsubject(`Thank You for Applying to [Accelerator Name]`);
        setEditorState(EditorState.createWithContent(convertFromRaw(AcceleratorRejectedrawContent)));
        setselection('accRejected');
    }

    const handleCompApprovedClick = () => {
        setsubject(`Congratulations! You’ve Been Selected for the [Pitch Competition Name]`);
        setEditorState(EditorState.createWithContent(convertFromRaw(CompetitionApprovedrawContent)));
        setselection('compApproved');
    }

    const handleCompRejectedClick = () => {
        setsubject(`Thank You for Your Application to [Pitch Competition Name]`);
        setEditorState(EditorState.createWithContent(convertFromRaw(CompetitionRejectedrawContent)));
        setselection('compRejected');
    }

    const handleInvestApprovedClick = () => {
        setsubject(`Next Steps for Your Investment with [Investment Firm Name]`);
        setEditorState(EditorState.createWithContent(convertFromRaw(InvestmentApprovedrawContent)));
        setselection('investApproved');
    }

    const handleInvestRejectedClick = () => {
        setsubject(`Update on Your Investment Proposal with [Investment Firm Name]`);
        setEditorState(EditorState.createWithContent(convertFromRaw(InvestmentRejectedrawContent)));
        setselection('investRejected');
    }

    const handleConfirmSubmit = async (e) => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();
        const rawHTML = stateToHTML(contentState);
        console.log(rawHTML);

        setLoading(true);
        const companyName = onBoarding?.companyName;
        const logo = onBoarding?.logo;

        const body = {
            mail_user: onBoarding.email,
            mail_password: onBoarding.app_password,
            message: rawHTML,
            subject: subject,
            email: userEmail,
            companyName: companyName,
            logo: logo,
        };

        try {
            // Send email
            const emailResponse = await backend.post("/email/companymail", body);
            console.log(emailResponse);
            console.log("Email Response Status:", emailResponse.status);

            if (emailResponse.status === 200) {
                closePopup();
                success();
            } else {
                console.error("Failed to send email. Status code:", emailResponse.status);
            }
        } catch (emailError) {
            console.error("Error sending email:", emailError.message);
        } finally {
            setLoading(false); // Ensure loading state is reset in all cases
        }
    };

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.header} style={{ background: onBoarding.color ? onBoarding.color : "black" }}>
                        <div className={Classes.flexAlign} >
                            <SvgArrow />
                            <h5 className={Classes.title} > Email to {userCompany} </h5>
                        </div>
                        <div className={Classes.flexAlignEnd} >
                            <SvgX onClick={closePopup} />
                        </div>
                    </div>
                    <form onSubmit={handleConfirmSubmit} className={Classes.body}  >
                        <input
                            type='text'
                            placeholder='Subject'
                            className={Classes.input}
                            onChange={(e) => { setsubject(e.target.value) }}
                            value={subject}
                        />
                        <hr className={Classes.line} />
                        <div className={Classes.editor} >
                            <Editor
                                editorState={editorState}
                                handleKeyCommand={handleKeyCommand}
                                onChange={setEditorState}
                                placeholder="Enter your message here or select a template..."
                            />
                        </div>
                        <hr className={Classes.line} />
                        <div className={Classes.toolbar}>
                            <div className={Classes.toolbarCategory} >
                                <button type="button" onClick={() => toggleInlineStyle('BOLD')}>
                                    <img src='/bold.png' alt='bold' />
                                </button>
                                <button type="button" onClick={() => toggleInlineStyle('ITALIC')}  >
                                    <img src='/italic.png' alt='italic' style={{ height: '14px' }} />
                                </button>
                                <button type="button" onClick={() => toggleInlineStyle('UNDERLINE')} >
                                    <img src='/underline.png' alt='underline' style={{ height: '15px' }} />
                                </button>
                                <button type="button" onClick={() => toggleInlineStyle('STRIKETHROUGH')} >
                                    <img src='/strikethrough.png' alt='strikethrough' style={{ height: '14px' }} />
                                </button>
                                <button type="button" onClick={() => toggleInlineStyle('CODE')} >
                                    <img src='/coding.png' alt='coding' style={{ height: '16px' }} />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('code-block')}>
                                    <img src='/code-block.png' alt='code-block' style={{ height: '17px' }} />
                                </button>
                            </div>
                            <div className={Classes.toolbarCategory} >
                                <button type="button" onClick={() => toggleBlockType('header-one')} >
                                    <img src='/h1.png' alt='h1' />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('header-two')} >
                                    <img src='/h2.png' alt='h2' />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('header-three')} >
                                    <img src='/h3.png' alt='h3' />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('header-four')} >
                                    <img src='/h4.png' alt='h4' />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('header-five')} >
                                    <img src='/h5.png' alt='h5' />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('blockquote')}>
                                    <img src='/quotes.png' alt='quotes' style={{ height: '15px' }} />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('unordered-list-item')}>
                                    <img src='/bullet-list.png' alt='bullet-list' style={{ height: '16px' }} />
                                </button>
                                <button type="button" onClick={() => toggleBlockType('ordered-list-item')}>
                                    <img src='/ordered-list.png' alt='ordered-list' style={{ height: '17px' }} />
                                </button>
                            </div>
                            <div className={Classes.toolbarCategory} >
                                <button type="button" onClick={handleUndo}>
                                    <img src='/undo.png' alt='ordered-list' style={{ height: '17px' }} />
                                </button>
                                <button type="button" onClick={handleRedo}>
                                    <img src='/redo.png' alt='ordered-list' style={{ height: '17px' }} />
                                </button>
                            </div>
                        </div>
                        <div className={Classes.flexBetween} >
                            <DropDown selection={selection}
                                handleAccApprovedClick={handleAccApprovedClick}
                                handleAccRejectedClick={handleAccRejectedClick}
                                handleCompApprovedClick={handleCompApprovedClick}
                                handleCompRejectedClick={handleCompRejectedClick}
                                handleInvestApprovedClick={handleInvestApprovedClick}
                                handleInvestRejectedClick={handleInvestRejectedClick}
                            />
                            <button type='submit' disabled={loading} className={Classes.btn} style={{ background: onBoarding.color ? onBoarding.color : "black" }} > <h5> {loading ? 'Sending...' : 'Send email'} </h5> </button>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
        </>
    )
}

export default EmailEditorPopup
