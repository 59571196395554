import React, { useEffect, useRef, useState } from 'react'
import Classes from './Scoring.module.css'
import { useSelector } from 'react-redux';

import { CircularProgress } from '@mui/material';
import { setDeckId } from '../../redux-store/auth/actions';
import { useDispatch } from 'react-redux';
import sessionstorage from 'sessionstorage';
import { useNavigate } from 'react-router-dom';
import backend from '../../api';
import { AnimatePresence, motion } from 'framer-motion';
import { updateDeckInfo } from '../../redux-store/auth/actions';
import SeeMorePopup from '../../Global-Components/SeeMorePopup/SeeMorePopup';
import NotifyCompanyPopup from '../../Global-Components/NotifyCompanyPopup/NotifyCompanyPopup';
import EmailSuccessPopup from '../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import EmailEditorPopup from '../../Global-Components/EmailEditorPopup/EmailEditorPopup';

export default function DemoRowComp({ value, index, setdecisionLoading }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
    const [loading, setLoading] = useState(false);
    

    const [openConfirmPopup, setopenConfirmPopup] = useState(false);

    
    const handleCloseConfirmPopup = () => {
        setopenConfirmPopup(false);
    }

    const [openNotifyCompanyPopup, setopenNotifyCompanyPopup] = useState(false);
    const handleCloseNotifyCompanyPopup = () => {
        setopenNotifyCompanyPopup(false);
    }

    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    const handleCloseEmailSentPopup = () => {
        setopenEmailSentPopup(false);
    }

    const handleEmailSentPopupOpen = () => {
        setopenEmailSentPopup(true);
    }

    const [openEmailEditorPopup, setopenEmailEditorPopup] = useState(false);
    const handleCloseEmailEditorPopup = () => {
        setopenEmailEditorPopup(false);
    }

    const handleEmailEditerOpen = () => {
        setopenEmailEditorPopup(true);
    }

    const [openSummary, setopenSummary] = useState(false);
    const handleCloseSummary = () => {
        setopenSummary(false);
    }

    const [openReportPopup, setopenReportPopup] = useState(false);
    const handleCloseReportPopup = () => {
        setopenReportPopup(false);
    }



    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

    const [dropDown, setdropDown] = useState(false);
    const handleClosedropdown = () => {
        setdropDown(false);
    }

    // Parse scores and calculate the total score dynamically
    const scores = {
        deckStructure: parseFloat(value?.deckStructureScoreMatch) || 0,
        marketOpportunity: parseFloat(value?.marketPotentialScoreMatch) || 0,
        marketingSales: parseFloat(value?.marketingSalesScoreMatch) || 0,
        businessModel: parseFloat(value?.businessModelScoreMatch) || 0,
        competitiveLandscape: parseFloat(value?.competitiveLandscapeScoreMatch) || 0,
        team: parseFloat(value?.teamScoreMatch) || 0,
        traction: parseFloat(value?.tractionScoreMatch) || 0
    };

    // Calculate totalScore
    let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
        return acc + (scores[criteria.name] * weight);
    }, 0);

    totalScore = +(totalScore?.toFixed(2)); // Round to two decimal places

    useEffect(() => {
        if (onBoarding) {
            document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
        }
    }, [onBoarding]);

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [decision, setdecision] = useState(value?.decision);
    const handleDecisionChange = async (value, id) => {
        setdecisionLoading(true);
        try {
            console.log(value);
            setdecision(value);
            const resp = await backend.put(`/deck/${id}`, { decision: value });

            if (resp.status === 200) {
                await dispatch(updateDeckInfo(resp?.data))
                setdropDown(false);
                setopenNotifyCompanyPopup(true);
            }
        } catch (error) {
            setdecisionLoading(false);
            console.error("An error occurred while updating the decision:", error);
            // Handle the error appropriately, e.g., show an error message to the user
        } finally {
            setdecisionLoading(false);
        }
    };

    const [openSeeMorePopup, setopenSeeMorePopup] = useState(false);
    const handleCloseSeeMore = async() => {
        setopenSeeMorePopup(false);
        await dispatch(updateDeckInfo({data: "Updating scored decks"}))
        
    }

    console.log(value)

    const countScores = (feedbackArray) => {
        return feedbackArray.filter(feedback => feedback.score !== undefined).length;
    };

    const calculateAverageScore = (feedbackArray) => {
        // Filter out feedbacks that have a valid numeric score
        const validFeedbacks = feedbackArray.filter(feedback => typeof feedback.score === 'number');

        // If there are no valid feedbacks, return 0 to avoid division by zero
        if (validFeedbacks.length === 0) return 0;

        // Calculate the sum of all the scores
        const totalScore = validFeedbacks.reduce((acc, feedback) => acc + feedback.score, 0);

        // Calculate the average score
        const averageScore = totalScore / validFeedbacks.length;

        return averageScore;
    };


    console.log(value)



    return loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
    </div> : (
        <>
            <AnimatePresence>
                {openSeeMorePopup &&
                    <SeeMorePopup closePopup={handleCloseSeeMore} deck={value}
                        setdecisionLoading={setdecisionLoading}
                    />
                }
            </AnimatePresence>

            <AnimatePresence>
            {openNotifyCompanyPopup && <NotifyCompanyPopup closePopup={handleCloseNotifyCompanyPopup} openEmailEditor={handleEmailEditerOpen} />}
            </AnimatePresence>
            
            <AnimatePresence>
            {openEmailSentPopup && <EmailSuccessPopup closePopup={handleCloseEmailSentPopup}
                title="WOOHOO!"
                description="Your email has been sent successfully."
                buttonText="Continue screening"
            />}
            </AnimatePresence>

             <AnimatePresence>
                {openEmailEditorPopup && <EmailEditorPopup closePopup={handleCloseEmailEditorPopup} success={handleEmailSentPopupOpen}
                    userEmail={value?.userId?.email}
                    decision={decision}
                    userCompany={value?.userId?.companyName}
                />}
            </AnimatePresence>
       

            <div className={Classes.row} >
                <div className={Classes.rowHeader} >
                    <h5>{index + 1}</h5>
                    <h5>{value?.userId?.companyName}</h5>
                    <h5>{value?.feedback[0]?.userId?.fullname}...</h5>
                    <h5>{countScores(value?.feedback)}</h5>
                    <h5 className={Classes.score}
                        onClick={() => setopenSeeMorePopup(true)}
                    >
                        {calculateAverageScore(value.feedback).toFixed(2)} {'>'}
                    </h5>
                    <div className={Classes.dropDown}   ref={dropdownRef}     >
                        {/* Dropdown Head */}
                        <div
                            className={Classes.dropDownHead}
                            onClick={() => setdropDown(!dropDown)}
                        >
                            <p style={{ textTransform: 'capitalize' }} >{value?.decision === 'approve' ? 'Approve' : value?.decision === 'reject' ? 'Reject' : 'Select'}</p>
                            <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
                        </div>

                        {/* Dropdown Body */}
                        <AnimatePresence>
                            {dropDown && (
                                <motion.div
                                    initial={{ opacity: 0, y: -5 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -5 }}
                                    transition={{
                                        duration: 0.15,
                                    }}
                                    className={Classes.dropDownBody}
                                >
                                    <div className={Classes.ddPointerCon}>
                                        <div className={Classes.dropDownPointer}></div>
                                    </div>
                                    <div className={Classes.dropDownBodyInner}>
                                        {/* Dropdown Options */}
                                        <div
                                            className={`${Classes.dropDownElem} ${value?.decision == 'approve' ? Classes.activeDropElem : ''}`}
                                            onClick={() => handleDecisionChange('approve', value?._id)}
                                            style={{ padding: '5px 10px' }} // Mimic MUI padding style
                                        >
                                            <h5>Approve</h5>
                                        </div>
                                        <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                                        <div
                                            className={`${Classes.dropDownElem} ${value?.decision == 'reject' ? Classes.activeDropElem : ''}`}
                                            onClick={() => handleDecisionChange('reject', value?._id)}
                                            style={{ padding: '5px 10px' }}
                                        >
                                            <h5>Reject</h5>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        {/* Hidden input for accessibility */}
                        <input
                            type="hidden"
                            value={decision}
                            aria-label="Without label"
                            readOnly
                        />
                    </div>
                    <div className='flex' >
                        <h5 className={Classes.seemoreTxt} onClick={() => setopenSeeMorePopup(true)} style={{ color: onBoarding?.color }} >See more</h5>
                    </div>
                </div>
            </div >
        </>
    )
}
