// src/components/Pipeline/Pipeline.jsx

import React, { useState, useRef, useEffect } from 'react';
import Classes from './Pipeline.module.css';
import { useSelector } from 'react-redux';
import MobileVersionPopup from '../../Global-Components/MobileVersionPopup/MobileVersionPopup';
import { motion, AnimatePresence } from 'framer-motion';
import Slider from './Slider';
import AddCompanyPopup from '../../Global-Components/AddCompanyPopup/AddCompanyPopup';
import UpgradeFreePopup from '../../Global-Components/BasicNotifyPopup copy/UpgradeFreePopup';
import { CircularProgress } from '@mui/material';
import Notifications from '../../On-boarding/Global-Components/Notification/Notifcation';
import backend from '../../api';

export default function Pipeline() {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);

    const [openNotifications, setOpenNotifications] = useState(false);
    const NotificationsRef = useRef(null);
    const deckUpdate = useSelector((state) => state.deck.data);

    // Initialize state variables as empty arrays
    const [approvedDecks, setApprovedDeck] = useState([]);
    const [deferredDecks, setDeferredDeck] = useState([]);
    const [rejectedDecks, setRejectedDeck] = useState([]);
    const [requestInfoDecks, setRequestInfoDeck] = useState([]);
    const [reviewDecks, setReviewDeck] = useState([]);
    const [loading, setLoading] = useState(true)

    const companyId = onBoarding?._id;


    // Helper function to filter decks
    const filterActiveDecks = (pipeline) => {
        if (!pipeline || !pipeline.decks) return null;
        const filteredDecks = pipeline.decks.filter(deckInPipeline =>
            deckInPipeline.deck && !deckInPipeline.deck.adminDelete
        );
        return { ...pipeline, decks: filteredDecks };
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                if (!companyId) {
                    console.warn('Company ID is undefined.');
                    return;
                }

                // Fetch all pipelines for the given companyId with decks populated
                const pipelineResponse = await backend.get(`/pipelines/bycompany?companyId=${companyId}`);
                const pipelines = pipelineResponse?.data || [];

                // Helper function to extract deck objects from a pipeline
                // const extractDecks = (pipeline) => {
                //     return pipeline?.decks?.map(deckSubdoc => deckSubdoc.deck) || [];
                // };

                console.log("All pipeline decks", pipelineResponse)

                // Find each pipeline by name and extract decks
                const approvedPipeline = pipelines.find(pipeline => pipeline.pipelineName === "approved");
                setApprovedDeck(filterActiveDecks(approvedPipeline));

                const rejectedPipeline = pipelines.find(pipeline => pipeline.pipelineName === "rejected");
                setRejectedDeck(filterActiveDecks(rejectedPipeline));

                const requestInfoPipeline = pipelines.find(pipeline => pipeline.pipelineName === "requestInfo");
                setRequestInfoDeck(filterActiveDecks(requestInfoPipeline));

                const reviewPipeline = pipelines.find(pipeline => pipeline.pipelineName === "review");
                setReviewDeck(filterActiveDecks(reviewPipeline));

                const deferredPipeline = pipelines.find(pipeline => pipeline.pipelineName === "deferred");
                setDeferredDeck(filterActiveDecks(deferredPipeline));
                setLoading(false)

            } catch (error) {
                console.error('Error fetching pipelines:', error);
                setLoading(false)
            } finally {
                setLoading(false)
            }
        };

        fetchData();

    }, [companyId, deckUpdate]); // Dependency array ensures fetch runs when companyId or deckUpdate changes

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setOpenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleNotifications = (event) => {
        event.stopPropagation(); // Prevents event propagation to document
        setOpenNotifications(prevState => !prevState);
    };

    const [openAddCompanyPopup, setOpenAddCompanyPopup] = useState(false);
    const handleCloseAddCompanyPopup = () => {
        setOpenAddCompanyPopup(false);
    };

    useEffect(() => {
        if (onBoarding) {
            document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
        }
    }, [onBoarding]);

    console.log(approvedDecks)

    // loading ? (
    //     <div style={{ background: 'white' }} >
    //         <div className={Classes.banner} >

    //         </div>
    //         <div className={Classes.mainRightBg} >
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     height: "100vh",
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <div id="loading-bar-spinner" class="spinner">
    //                     <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // ) : (

    return (
        <>
            <MobileVersionPopup />
            <AnimatePresence>
                {openAddCompanyPopup && (
                    <AddCompanyPopup closePopup={handleCloseAddCompanyPopup} />
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(onBoarding?.subscription === "free") && (
                    <UpgradeFreePopup />
                )}
            </AnimatePresence>
            <div style={{ background: 'white' }}>
                <div className={Classes.banner}>
                    <div className={Classes.flexCenter}>
                        {/* Placeholder for potential content */}
                    </div>
                    <div className={Classes.flexAlign}>
                        {/* Placeholder for potential content */}
                    </div>
                    <div className={Classes.bannerRight}>
                        <Notifications />
                    </div>
                </div>
                <div className={Classes.mainRightBg}>
                    <div className={Classes.hero2}>
                        <div className={Classes.searcCon}>
                            <img src="/icons/search.png" alt="icon" />
                            <input type="text" placeholder="Search..." />
                        </div>
                        <div>
                            <button onClick={() => { setOpenAddCompanyPopup(true) }} className={Classes.addBtn} type='button'>
                                <h5>+ Add company</h5>
                            </button>
                        </div>
                    </div>
                    <div className={Classes.hero3}>
                        <Slider
                            approvedDecks={approvedDecks}
                            rejectedDecks={rejectedDecks}
                            reviewDecks={reviewDecks}
                            requestInfoDecks={requestInfoDecks}
                            deferredDecks={deferredDecks}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
