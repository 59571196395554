import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Classes from '../styles/style.module.css'
import { motion } from 'framer-motion'
import Color from 'color';

export default function ContinueDashboard({ direction, handleSubmit }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const convertToRgba = (colorInput, opacity) => {
        try {
            const color = Color(colorInput);
            return color.alpha(opacity).rgb().string();
        } catch (error) {
            console.error("Invalid color format:", colorInput);
            return `rgba(0, 0, 0, ${opacity})`;
        }
    };

    const [copied, setCopied] = useState(false);
    const handleCopyText = () => {
        const textToCopy = `${onBoarding.companyName}.evala.ai`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };

    return (
        <>
            <motion.div
                initial={{ x: direction === "forward" ? 20 : -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.25 }}
                className={Classes.cardCon}
            >
                <h5 className={`${Classes.text2} ${Classes.m1r}`}>
                    Send out this link to your applicants to start <br /> screening potential companies!
                </h5>
                <div className={Classes.miniCon}>
                    <div className={Classes.bannerMid} >
                        <div className={Classes.bmLeft} >
                            <h5>{onBoarding.companyName}</h5>
                        </div>
                        <div className={Classes.bmRight} style={{ background: convertToRgba(onBoarding.color, 0.10) }} >
                            <h5>.evala.ai</h5>
                        </div>
                    </div>
                    <div>
                        {copied ?
                            <svg xmlns="http://www.w3.org/2000/svg" height="17px" width="17px" viewBox="0 0 448 512"><path fill="#000000" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                            :
                            <svg onClick={handleCopyText} xmlns="http://www.w3.org/2000/svg" height="17px" width="17px" cursor="pointer" viewBox="0 0 448 512"><path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" /></svg>
                        }
                    </div>
                </div>
                <button type="button" onClick={handleSubmit} className={Classes.btn2} style={{ background: onBoarding.color || "black", border: `1px solid ${onBoarding.color}` }}>
                    <h5> Continue to dashboard </h5>
                </button>
            </motion.div>
        </>
    )
}
