import React, { useState, useEffect, useRef } from 'react';
import Classes from '../../Pipeline.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { updateDeckInfo } from '../../../../redux-store/auth/actions';
import { CircularProgress } from '@mui/material';
import backend from '../../../../api';


const Eclipse = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="25px" fill='black' viewBox="0 0 448 512"><path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z" /></svg>
    )
}

export default function PipelineMenu({ data, setdecisionLoading, pipelineName, setLoading }) {
    const [dropDown, setdropDown] = useState(false);

    const dispatch = useDispatch();

    console.log("Menu data is", data)
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [decision, setdecision] = useState('Approve');
    const handleDecisionChange = async (targetPipelineName, deckId) => {
        setLoading(true)
        try {
            // API Endpoint - Adjust the base URL as needed
            const response = await backend.post('/pipelines/move-deck', {
                deckId: deckId,
                targetPipelineName: targetPipelineName
            });

            console.log(response)

            if (response.status === 200) {
                // Notify parent component or perform any state updates
                await dispatch(updateDeckInfo(response?.data))
                setLoading(false)

            }
        } catch (error) {
            console.error("An error occurred while moving the deck:", error);
        } finally {
            setLoading(false)
            setdropDown(false);
        }
    };

    return (
        <>
            <div className={Classes.dropDown} >
                <div
                    className={Classes.dropDownHead}
                    onClick={() => setdropDown(!dropDown)}
                    ref={dropdownRef}
                >
                    <Eclipse />
                </div>

                {/* Dropdown Body */}
                <AnimatePresence>
                    {dropDown && (
                        <motion.div
                            initial={{ opacity: 0, y: -5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -5 }}
                            transition={{
                                duration: 0.15,
                            }}
                            className={Classes.dropDownBody}
                        >
                            <div className={Classes.ddPointerCon}>
                                <div className={Classes.dropDownPointer}></div>
                            </div>
                            <div className={Classes.dropDownBodyInner}>
                                {/* Dropdown Options */}
                                <h5 className={Classes.dropDownMiniTitle} >Move to {'->'}</h5>
                                <div
                                    className={`${Classes.dropDownElem} ${pipelineName === 'review' ? Classes.activeDropElem : ''}`}
                                    onClick={() => handleDecisionChange('review', data?.deck?._id)}
                                    style={{ padding: '5px 10px' }}
                                >
                                    <h5>Reviewing Material</h5>
                                </div>
                                <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                                <div
                                    className={`${Classes.dropDownElem} ${pipelineName === 'requestInfo' ? Classes.activeDropElem : ''}`}
                                    onClick={() => handleDecisionChange('requestInfo', data?.deck?._id)}
                                    style={{ padding: '5px 10px' }}
                                >
                                    <h5>Requested Information</h5>
                                </div>
                                <div className={Classes.dropDownLine}></div> {/* Optional line separator */}

                                <div className={`${Classes.dropDownElem} ${pipelineName === 'approved' ? Classes.activeDropElem : ''}`}
                                    onClick={() => handleDecisionChange('approved', data?.deck?._id)}
                                    style={{ padding: '5px 10px' }}
                                >
                                    <h5>Approved</h5>
                                </div>
                                <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                                <div
                                    className={`${Classes.dropDownElem} ${pipelineName === 'rejected' ? Classes.activeDropElem : ''}`}
                                    onClick={() => handleDecisionChange('rejected', data?.deck?._id)}
                                    style={{ padding: '5px 10px' }}
                                >
                                    <h5>Rejected</h5>
                                </div>
                                <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                                <div
                                    className={`${Classes.dropDownElem} ${pipelineName === 'deferred' ? Classes.activeDropElem : ''}`}
                                    onClick={() => handleDecisionChange('deferred', data?.deck?._id)}
                                    style={{ padding: '5px 10px' }} // Mimic MUI padding style
                                >
                                    <h5>Deferred</h5>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
                {/* Hidden input for accessibility */}
                <input
                    type="hidden"
                    value={decision}
                    aria-label="Without label"
                    readOnly
                />
            </div>

        </>
    )
}
