import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from './SectionE.module.css';
import './pricing.css'

import {
  Button,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const SectionE = () => {
  const sectionRef = useRef(null); // Reference to the section

  const navigateTo = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const { user } = useSelector((state) => state.auth);

  return (
    <section id='pricing' ref={sectionRef} className={Classes.section}>
      <div className={Classes.flexContainer}>
        <div className={Classes.flexItem}>
          <AnimateTopToBottom delay={0.3}>
            <Button variant="contained" className="howItWorksBtn" > Pricing </Button>
          </AnimateTopToBottom>
          <AnimateTopToBottom delay={0.4}>
            <h1 className={Classes.heading}> Custom solutions </h1>
          </AnimateTopToBottom>
        </div>
      </div>
      <AnimateTopToBottom delay={0.6}>
        <div className={Classes.card}>
          <div className={Classes.cardLeft} >
            <h2 > Enterprise solutions </h2>
            <button> <h5 >Book demo</h5> </button>
          </div>
          <div className={Classes.cardRight} >
            <h5 className={Classes.text} >Custom upload limits for:</h5>
            <div className={Classes.list} >
              <div className={Classes.listItem} >
                <img src='/icons/tick.png' alt='tick' />
                <h5 className={Classes.text} >Accelerators</h5>
              </div>
              <div className={Classes.listItem} >
                <img src='/icons/tick.png' alt='tick' />
                <h5 className={Classes.text} >Incubators</h5>
              </div>
              <div className={Classes.listItem} >
                <img src='/icons/tick.png' alt='tick' />
                <h5 className={Classes.text} >Competitions</h5>
              </div>
              <div className={Classes.listItem} >
                <img src='/icons/tick.png' alt='tick' />
                <h5 className={Classes.text} >Investment firms</h5>
              </div>
            </div>
          </div>
        </div>
      </AnimateTopToBottom>
    </section >
  );
}

export default SectionE;
