import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { CircularProgress, Box } from '@mui/material';
import Classes from '../styles/style.module.css'
import ScoringRatePopup from '../../ScoringRatePopup/ScoringRatePopup';
import NotifyCompanyPopup from '../../../Global-Components/NotifyCompanyPopup/NotifyCompanyPopup';
import EmailSuccessPopup from '../../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import { useDispatch } from 'react-redux';
import { updateDeckInfo } from '../../../redux-store/auth/actions';
import backend from '../../../api';
import EmailEditorPopup from '../../EmailEditorPopup/EmailEditorPopup';


function CircularProgressWithLabel({ value, fill }) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={value} size={80} thickness={5}
                sx={{
                    height: '50px !important',
                    width: '50px !important',
                    color: `${fill} !important`,
                }}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <h5 className={Classes.title1} >
                    {`${Math.round(value)}`}
                </h5>
            </Box>
        </Box>
    );
}

const InfoSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 512 512",
        }}
        viewBox="0 0 512 512"
        {...props}
        cursor="pointer"
    >
        <path d="M290.211 360.727a24.896 24.896 0 0 1-15.011-3.375 15.593 15.593 0 0 1-4.305-12.8 66.49 66.49 0 0 1 1.396-11.636c.781-4.394 1.79-8.744 3.025-13.033l13.731-47.244a71.535 71.535 0 0 0 2.793-14.313c0-5.236.698-8.844.698-10.938a30.602 30.602 0 0 0-10.822-24.32 45.5 45.5 0 0 0-30.72-9.309 79.72 79.72 0 0 0-23.273 3.84c-8.223 2.56-16.873 5.624-25.949 9.193l-3.956 15.36c2.676-.931 5.935-1.978 9.658-3.142a39.572 39.572 0 0 1 10.938-1.629 22.343 22.343 0 0 1 14.778 3.607 16.875 16.875 0 0 1 3.84 12.684 55.19 55.19 0 0 1-1.28 11.636c-.815 4.073-1.862 8.378-3.142 12.916L218.763 325.7a135.651 135.651 0 0 0-2.676 13.382 83.118 83.118 0 0 0-.815 11.636 30.369 30.369 0 0 0 11.636 24.087 46.55 46.55 0 0 0 31.185 9.542 71.78 71.78 0 0 0 23.273-3.375c6.594-2.25 15.399-5.469 26.415-9.658l3.724-14.662a64.283 64.283 0 0 1-9.193 3.025 44.19 44.19 0 0 1-12.101 1.05zM304.756 136.727A33.163 33.163 0 0 0 281.483 128a33.396 33.396 0 0 0-23.273 8.727c-11.632 10.03-12.931 27.591-2.9 39.224a27.762 27.762 0 0 0 2.9 2.9c13.252 11.853 33.294 11.853 46.545 0 11.632-10.129 12.851-27.769 2.722-39.401a27.754 27.754 0 0 0-2.721-2.723z" />
        <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm0 488.727C127.468 488.727 23.273 384.532 23.273 256S127.468 23.273 256 23.273 488.727 127.468 488.727 256 384.532 488.727 256 488.727z" />
    </svg>
)

const BellIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 0c-17.7 0-32 14.3-32 32l0 19.2C119 66 64 130.6 64 208l0 25.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416l400 0c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4l0-25.4c0-77.4-55-142-128-156.8L256 32c0-17.7-14.3-32-32-32zm0 96c61.9 0 112 50.1 112 112l0 25.4c0 47.9 13.9 94.6 39.7 134.6L72.3 368C98.1 328 112 281.3 112 233.4l0-25.4c0-61.9 50.1-112 112-112zm64 352l-64 0-64 0c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z" /></svg>
)

const ScoringIcon = (props) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg>
    )
}

export default function ScoresComp({ deck, setdecisionLoading }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [openNotifyCompanyPopup, setopenNotifyCompanyPopup] = useState(false);

    const [companyUsers, setCompanyUsers] = useState();

    const dispatch = useDispatch();

    const companyId = onBoarding._id;


    // Function to store removed users by deckId in localStorage
    const storeRemovedUsersByDeck = (deckId, removedUsers) => {
        const storedData = JSON.parse(localStorage.getItem('removedUsers')) || {};
        storedData[deckId] = removedUsers;
        localStorage.setItem('removedUsers', JSON.stringify(storedData));
    };

    // Function to get removed users by deckId from localStorage
    const getRemovedUsersByDeck = (deckId) => {
        const storedData = JSON.parse(localStorage.getItem('removedUsers')) || {};
        return storedData[deckId] || [];
    };

    const [removedUsers, setRemovedUsers] = useState(getRemovedUsersByDeck(deck._id));

    const handleRemoveUser = (userId) => {
        const updatedRemovedUsers = [...removedUsers, userId];
        setRemovedUsers(updatedRemovedUsers);
        storeRemovedUsersByDeck(deck._id, updatedRemovedUsers);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await backend.get(`api/user/all`);
                if (response?.data) {
                    const filteredUsers = response?.data?.data?.filter(user =>
                        user?.companyId?.toString() === companyId?.toString() &&
                        user?.companySubAdmin === true
                    );
                    const visibleUsers = filteredUsers.filter(user => !removedUsers.includes(user._id)); // Exclude removed users for the specific deck
                    setCompanyUsers(visibleUsers);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchUsers();
    }, [companyId, removedUsers, deck._id]); // Re-fetch when removed users or deck changes



    const handleCloseNotifyCompanyPopup = () => {
        setopenNotifyCompanyPopup(false);
    }

    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    const handleCloseEmailSentPopup = () => {
        setopenEmailSentPopup(false);
    }

    const handleEmailSentPopupOpen = () => {
        setopenEmailSentPopup(true);
    }

    const [openEmailEditorPopup, setopenEmailEditorPopup] = useState(false);
    const handleCloseEmailEditorPopup = () => {
        setopenEmailEditorPopup(false);
    }

    const handleEmailEditerOpen = () => {
        setopenEmailEditorPopup(true);
    }


    const [decision, setdecision] = useState(deck?.decision);
    const handleDecisionChange = async (value, id) => {
        setdecisionLoading(true);
        try {
            console.log(value);
            setdecision(value);
            const resp = await backend.put(`/deck/${id}`, { decision: value });


            if (resp.status === 200) {
                await dispatch(updateDeckInfo(resp?.data))
                setdropDown(false);
                setopenNotifyCompanyPopup(true);


            }
        } catch (error) {
            setdecisionLoading(false);
            console.error("An error occurred while updating the decision:", error);
            // Handle the error appropriately, e.g., show an error message to the user
        } finally {
            setdecisionLoading(false);
        }
    };

    const [dropDown, setdropDown] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [openScoringRatePopup, setopenScoringRatePopup] = useState(false);
    const handleCloseScoring = () => {
        setopenScoringRatePopup(false);
    }



    const calculateAverageScore = (feedbackArray) => {
        // Filter out feedbacks that have a valid numeric score
        const validFeedbacks = feedbackArray.filter(feedback => typeof feedback.score === 'number');

        // If there are no valid feedbacks, return 0 to avoid division by zero
        if (validFeedbacks.length === 0) return 0;

        // Calculate the sum of all the scores
        const totalScore = validFeedbacks.reduce((acc, feedback) => acc + feedback.score, 0);

        // Calculate the average score
        const averageScore = totalScore / validFeedbacks.length;

        return averageScore;
    };


    const countScores = (feedbackArray) => {
        return feedbackArray.filter(feedback => feedback.score !== undefined).length;
    };

    const formatDate = (createdAt) => {
        const date = new Date(createdAt);

        // Options for formatting the date
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        // Format the date using the options
        const formattedDate = date.toLocaleDateString('en-US', options);

        return formattedDate;
    };


    const message = `Please review deck of this company early as soon as possible: CompanyName: ${deck?.userId?.companyName}`

    const handleSendEmail = async (user) => {
        // setLoading(true);
        const companyName = onBoarding?.companyName;
        const logo = onBoarding?.logo;


        const body = {
            mail_user: onBoarding.email,
            mail_password: onBoarding.app_password,
            message: message,
            email: user.email,
            companyName: companyName,
            logo: logo,
        };

        try {
            // Send email
            const emailResponse = await backend.post("/email/reminder", body);
            console.log(emailResponse);
            console.log("Email Response Status:", emailResponse.status);

            if (emailResponse.status === 200) {
                // closePopup();
                // success();
            } else {
                console.error("Failed to send email. Status code:", emailResponse.status);
            }
        } catch (emailError) {
            console.error("Error sending email:", emailError.message);
        } finally {
            // setLoading(false); // Ensure loading state is reset in all cases
        }
    };


    return (
        <>
            <AnimatePresence>
                {openScoringRatePopup && <ScoringRatePopup closePopup={handleCloseScoring} deck={deck} />}
            </AnimatePresence>
            <AnimatePresence>
                {openNotifyCompanyPopup && <NotifyCompanyPopup closePopup={handleCloseNotifyCompanyPopup} openEmailEditor={handleEmailEditerOpen} />}
            </AnimatePresence>
            <AnimatePresence>
                {openEmailSentPopup && <EmailSuccessPopup closePopup={handleCloseEmailSentPopup}
                    title="WOOHOO!"
                    description="Your email has been sent successfully."
                    buttonText="Continue screening"
                />}
            </AnimatePresence>
            <AnimatePresence>
                {openEmailEditorPopup && <EmailEditorPopup closePopup={handleCloseEmailEditorPopup} success={handleEmailSentPopupOpen}
                    userEmail={deck?.userId?.email}
                    decision={decision}
                    userCompany={deck?.userId?.companyName}
                />}
            </AnimatePresence>

            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0 }} // Animation when component enters
                    animate={{ opacity: 1 }}   // Animation when component is open
                    exit={{ opacity: 0 }}    // Animation when component exits
                    transition={{ duration: 0.3 }}
                    className={Classes.cardCon}
                >
                    <div className={Classes.cardMiniHead} >
                        <div>
                            <h5 className={`${Classes.title1} ${Classes.mb5}`} >Decision</h5>
                            <div className={Classes.dropDown} ref={dropdownRef}                            >
                                {/* Dropdown Head */}
                                <div
                                    className={Classes.dropDownHead}
                                    onClick={() => setdropDown(!dropDown)}
                                >
                                    <p style={{ textTransform: 'capitalize' }} >{decision === 'approve' ? 'Approve' : decision === 'reject' ? 'Reject' : 'Select'}</p>
                                    <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
                                </div>

                                {/* Dropdown Body */}
                                <AnimatePresence>
                                    {dropDown && (
                                        <motion.div
                                            initial={{ opacity: 0, y: -5 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -5 }}
                                            transition={{
                                                duration: 0.15,
                                            }}
                                            className={Classes.dropDownBody}
                                        >
                                            <div className={Classes.ddPointerCon}>
                                                <div className={Classes.dropDownPointer}></div>
                                            </div>
                                            <div className={Classes.dropDownBodyInner}>
                                                {/* Dropdown Options */}
                                                <div
                                                    className={`${Classes.dropDownElem} ${decision == 'approve' ? Classes.activeDropElem : ''}`}
                                                    onClick={() => handleDecisionChange('approve', deck?._id)}
                                                    style={{ padding: '5px 10px' }} // Mimic MUI padding style
                                                >
                                                    <h5>Approve</h5>
                                                </div>
                                                <div className={Classes.dropDownLine}></div>
                                                <div
                                                    className={`${Classes.dropDownElem} ${decision == 'reject' ? Classes.activeDropElem : ''}`}
                                                    onClick={() => handleDecisionChange('reject', deck?._id)}
                                                    style={{ padding: '5px 10px' }}
                                                >
                                                    <h5>Reject</h5>
                                                </div>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                {/* Hidden input for accessibility */}
                                <input
                                    type="hidden"
                                    value={decision}
                                    aria-label="Without label"
                                    readOnly
                                />
                            </div>
                            <div className={`${Classes.flexAlign} ${Classes.mb5}`} >
                                <h5 className={Classes.title1} >Times scored:</h5>
                                <span className={Classes.fw13} >{countScores(deck?.feedback)}</span>
                            </div>
                        </div>
                        <div className={Classes.flexColoumn} >
                            <CircularProgressWithLabel value={calculateAverageScore(deck?.feedback)}
                                fill={
                                    calculateAverageScore(deck?.feedback) < 60
                                        ? "#ff0000"   // for 0-59
                                        : calculateAverageScore(deck?.feedback) < 80
                                            ? "#FF9D00"   // for 60-79
                                            : "#00DB0F"   // for 80-100
                                }
                            />
                            <h5 className={Classes.text3} >Overall Score</h5>
                        </div>
                    </div>
                    <div className={Classes.cardScroll} >
                        {deck.feedback && deck.feedback.map((feedback, index) => (
                            <div key={index} className={Classes.cardScrollElem}>
                                <div className={Classes.flexColoumn} style={{ alignItems: 'start' }}>
                                    <div className={Classes.flexAlign}>
                                        {/* Display the user's full name from feedback.userId.fullname */}
                                        <h5 className={Classes.bigTitle}>
                                            {feedback.userId.role==="company"?"Admin": feedback.userId.fullname }
                                        </h5>
                                        {/* For now, just a placeholder for "yesterday" or calculate from createdAt */}
                                        <span className={Classes.statusText}> {formatDate(feedback?.updatedAt)} </span>
                                    </div>
                                    <h5 className={Classes.text4}>{feedback?.userId?.role == "company" ? "Admin" : "Scorer"} </h5>
                                </div>
                                <div>
                                    {/* Display the feedback score */}
                                    <h5 className={Classes.scoreTxt}>{feedback.score}</h5>
                                </div>
                            </div>
                        ))}

                        {/* Check if user's feedback is missing */}
                        {companyUsers && companyUsers.map((user) => {
                            const feedbackExists = deck?.feedback?.some(feedback => feedback.userId._id === user._id);
                            if (!feedbackExists) {
                                return (
                                    <div className={Classes.cardScrollElemPending} key={user._id}>
                                        <div className={Classes.flexColoumn} style={{ alignItems: 'start' }}>
                                            <div className={Classes.flexAlign}>
                                                <h5 className={Classes.bigTitle}>{user.fullname}</h5>
                                                <InfoSvg height='12px' fill='red' />
                                                <span className={Classes.pendingText}>pending review</span>
                                            </div>
                                            <h5 className={Classes.text4}>Scorer</h5>
                                        </div>
                                        <div className={Classes.flexAlign2}>
                                            <button className={Classes.reminderBtn} style={{ borderColor: onBoarding?.color }}>
                                                <BellIcon height='13px' fill={onBoarding?.color} />
                                                <h5 style={{ color: onBoarding?.color }}
                                                    onClick={() => { handleSendEmail(user); handleRemoveUser(user._id) }}
                                                >Send a reminder</h5>
                                            </button>
                                            <div className='flex'>
                                                <svg
                                                    height='15px'
                                                    fill='black'
                                                    cursor='pointer'
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 384 512"
                                                    onClick={() => handleRemoveUser(user._id)} // On click, remove the user
                                                >
                                                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}

                    </div>
                    <div className={Classes.cardBottom} >
                        <button className={Classes.normalBtn} onClick={() => { setopenScoringRatePopup(true) }} >
                            <ScoringIcon fill={'#7a7a7a'} height="12px" />
                            <h5>Add Score</h5>
                        </button>
                    </div>
                </motion.div>
            </AnimatePresence>
        </>
    )
}
