import React, { useState, useEffect, useRef } from 'react'
import Classes from './Scoring.module.css'
import Color from 'color';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import sessionstorage from "sessionstorage";
import DemoRowComp from './DemoRowComp';
import backend from '../../api';
import { setCompanyInfo } from '../../redux-store/auth/actions';
import UpgradePopup from '../../Global-Components/UpgradePopup/UpgradePopup';
import BasicNotifyPopup from '../../Global-Components/BasicNotifyPopup/BasicNotifyPopup';
import UpgradeFreePopup from '../../Global-Components/BasicNotifyPopup copy/UpgradeFreePopup';
import Notifications from '../../On-boarding/Global-Components/Notification/Notifcation';

import NoDataRow from './NoDataRow';
import ContentLoader from 'react-content-loader';

const RowSkeleton = () => (
    <div style={{ height: '3.1rem', padding: '9px 20px', display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            height="32"
        >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="32" />
        </ContentLoader>
    </div>
);
const SmallRowSkeleton = (props) => (
    <div style={{ height: '1.7em', width: props.width, display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="4" ry="4" width="97.5%" {...props} />
        </ContentLoader>
    </div>
);

export default function JudgingDashboard() {
    const navigateTo = useNavigate();
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [deckData, setDeckData] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [last30DaysDeck, setLast30DaysDeck] = useState(0);
    const [avgDeckScore, setAverageDeckScore] = useState(0);
    const [filter, setFilter] = useState('All');
    const [selectedButton, setSelectedButton] = useState('');
    const [Industry, setIndustry] = useState('SaaS');
    const deckUpdate = useSelector((state) => state.deck.data);


    const companyId = onBoarding?._id;

    const [decisionLoading, setdecisionLoading] = useState(false);

    const [openNotifications, setopenNotifications] = useState(false);


    const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);

    const [openEmailConnectedPopup, setopenEmailConnectedPopup] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const [filteredDecks, setFilteredDecks] = useState([]); // Data to display



    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = deckData?.filter((deck) => {
            // Safely check if companyName exists and convert it to lowercase
            const companyName = deck?.userId?.companyName?.toLowerCase() || "";

            // Filter based on companyName that starts with the search term
            return companyName.startsWith(value);
        });

        console.log(filtered);

        setFilteredDecks(filtered);
    };



    const userID = user?._id
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);


    const itemsPerPage = 10;



    // Calculate total pages
    const totalPages = Math.ceil(filteredDecks?.length / itemsPerPage);

    // Get current items
    const currentItems = filteredDecks?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // Calculate the range of items currently being shown
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, filteredDecks?.length);
    const totalItems = filteredDecks?.length;


    const [openUpgradePopup, setopenUpgradePopup] = useState(false);
    const handleCloseUpgradePopup = () => {
        setopenUpgradePopup(false);
    }

    const handleUpgradeSubmit = () => {
        navigateTo('/pricing');
    }

    const NotificationsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setopenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleNotifications = (event) => {
        event.stopPropagation(); // Prevents event propagation to document
        setopenNotifications(prevState => !prevState);
    };



    useEffect(() => {
        if (selectedButton === 'Setting') {
            sessionstorage.setItem("navBtn", "Setting")
            navigateTo('/setting');
        }
    }, [selectedButton])

    const handleCloseEmailConnectedPopup = () => {
        setopenEmailConnectedPopup(false);
    }

    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    const handleCloseEmailSentPopup = () => {
        setopenEmailSentPopup(false);
    }

    const handleEmailSentPopupOpen = () => {
        setopenEmailSentPopup(true);
    }

    const [openEmailEditorPopup, setopenEmailEditorPopup] = useState(false);
    const handleCloseEmailEditorPopup = () => {
        setopenEmailEditorPopup(false);
    }

    const handleEmailEditerOpen = () => {
        setopenEmailEditorPopup(true);
    }


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setopenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleSettingClick = () => {
        setSelectedButton('Setting');
    }

    const convertToRgba = (colorInput, opacity) => {
        try {
            const color = Color(colorInput);
            return color.alpha(opacity).rgb().string();
        } catch (error) {
            console.error("Invalid color format:", colorInput);
            return `rgba(0, 0, 0, ${opacity})`;
        }
    };

    const [copied, setCopied] = useState(false);
    const handleCopyText = () => {
        const textToCopy = `${onBoarding.companyName}.evala.ai`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };



    const handleFilterChange = (newFilter) => {
        // If the new filter is the same as the current filter, set it to "All"
        if (filter === newFilter) {
            setFilter('All');
        } else {
            // Otherwise, update to the new filter
            setFilter(newFilter);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true)
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

                // Filter the response data where adminDelete is false
                const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");
                const industries = filteredData?.map(item => item?.userId?.industry) || ['SaaS'];
                console.log(industries)

                const chosenName = getMostFrequentOrRandomCompanyName(industries);
                setIndustry(chosenName)



                // Calculate the date 30 days ago
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo?.getDate() - 30);

                // Filter the data for the last 30 days
                const last30DaysData = filteredData?.filter(item => new Date(item?.createdAt) >= thirtyDaysAgo);

                // Log the filtered data
                console.log(filteredData);
                setDeckData(filteredData);
                setFilteredDecks(filteredData)

                // Log or set the length of the last 30 days data
                console.log(last30DaysData?.length);
                setLast30DaysDeck(last30DaysData?.length);

                // Calculate total score for each deck
                const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
                const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

                const calculateTotalScore = (deck) => {
                    const scores = {
                        deckStructure: parseFloat(deck?.deckStructureScoreMatch) || 0,
                        marketOpportunity: parseFloat(deck?.marketPotentialScoreMatch) || 0,
                        marketingSales: parseFloat(deck?.marketingSalesScoreMatch) || 0,
                        businessModel: parseFloat(deck?.businessModelScoreMatch) || 0,
                        competitiveLandscape: parseFloat(deck?.competitiveLandscapeScoreMatch) || 0,
                        team: parseFloat(deck?.teamScoreMatch) || 0,
                        traction: parseFloat(deck?.tractionScoreMatch) || 0
                    };

                    let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
                        return acc + (scores[criteria.name] * weight);
                    }, 0);

                    return +(totalScore?.toFixed(2)); // Round to two decimal places
                };

                // Add totalScore to each deck and sort by totalScore in descending order
                const decksWithScores = filteredData?.map(deck => ({
                    ...deck,
                    totalScore: calculateTotalScore(deck)
                })).sort((a, b) => b.totalScore - a.totalScore);



                const getFilteredDecks = () => {
                    const topCounts = {
                        'Top 10': 10,
                        'Top 25': 25,
                        'Top 100': 100
                    };

                    if (filter === 'All') {
                        return decksWithScores;
                    }

                    const count = topCounts[filter]; // Default to Top 10 if no filter is selected


                    return decksWithScores.slice(0, count);
                };

                const filteredDecks = getFilteredDecks();

                // Update the state with the sorted array
                setDeckData(filteredDecks);
                setFilteredDecks(filteredData)

                // Calculate the average score of all decks
                const totalScoresSum = decksWithScores?.reduce((acc, deck) => acc + deck.totalScore, 0);
                const averageScore = totalScoresSum / decksWithScores.length;

                // Log or use the average score
                console.log('Average Score:', averageScore);
                setAverageDeckScore(averageScore);

                // Use filtered data as needed
                // ...
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const fetchUsers = async () => {
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/api/user/bycompany?companyId=${companyId}`);
                console.log(response.data)
                if (response?.data) {
                    setTotalUser(response?.data?.length)
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        fetchUsers();

    }, [companyId, onBoarding, filter, deckUpdate]); // Dependency on companyId ensures useEffect runs when companyId changes


    const getMostFrequentOrRandomCompanyName = (industries) => {


        if (industries.length === 1) {
            return industries[0]; // Only one item, return it
        }

        // Count occurrences of each company name
        const nameCounts = industries?.reduce((acc, name) => {
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});

        // Find the maximum occurrence count
        const maxCount = Math.max(...Object.values(nameCounts));

        // Get all names with the maximum count
        const mostFrequentNames = Object.keys(nameCounts).filter(name => nameCounts[name] === maxCount);

        if (mostFrequentNames.length === 1) {
            return mostFrequentNames[0]; // Return the most frequent name
        } else {
            // If there's a tie, pick one randomly
            const randomIndex = Math.floor(Math.random() * mostFrequentNames.length);
            return mostFrequentNames[randomIndex];
        }
    };

    // Example usage
    // const companyNames = ["CompanyA", "CompanyB", "CompanyA", "CompanyC", "CompanyB", "CompanyA"];
    // console.log(chosenName);

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Check if companyName exists in database
                const response = await backend.get(`/onboarding/?userID=${userID}`);
                // console.log(response.data[0])
                // If response.data is not empty, companyName exists in database
                if (response.data.length > 0) {
                    dispatch(setCompanyInfo(response.data[0]))
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs once on component mount

    // Function to handle update and filter data
    const handleDelete = async (deckId) => {
        try {
            setLoading(true)
            // Update backend to mark adminDelete as true for the deckId
            await backend.put(`/deck/${deckId}`, { adminDelete: true });

            // Refetch and filter data
            RefetchData();
            setLoading(false)


        } catch (error) {
            console.error('Error updating or fetching data:', error);
        }
    };

    const RefetchData = async () => {

        try {
            // Check if companyName exists in database
            const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

            // Filter the response data where adminDelete is false
            const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");
            const industries = filteredData?.map(item => item?.userId?.industry) || ['SaaS'];

            console.log(industries)
            const chosenName = getMostFrequentOrRandomCompanyName(industries);
            setIndustry(chosenName)

            // Calculate the date 30 days ago
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo?.getDate() - 30);

            // Filter the data for the last 30 days
            const last30DaysData = filteredData?.filter(item => new Date(item?.createdAt) >= thirtyDaysAgo);

            // Log the filtered data
            console.log(filteredData);
            setDeckData(filteredData);

            // Log or set the length of the last 30 days data
            console.log(last30DaysData?.length);
            setLast30DaysDeck(last30DaysData?.length);

            // Calculate total score for each deck
            const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
            const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

            const calculateTotalScore = (deck) => {
                const scores = {
                    deckStructure: parseFloat(deck?.deckStructureScoreMatch) || 0,
                    marketOpportunity: parseFloat(deck?.marketPotentialScoreMatch) || 0,
                    marketingSales: parseFloat(deck?.marketingSalesScoreMatch) || 0,
                    businessModel: parseFloat(deck?.businessModelScoreMatch) || 0,
                    competitiveLandscape: parseFloat(deck?.competitiveLandscapeScoreMatch) || 0,
                    team: parseFloat(deck?.teamScoreMatch) || 0,
                    traction: parseFloat(deck?.tractionScoreMatch) || 0
                };

                let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
                    return acc + (scores[criteria.name] * weight);
                }, 0);

                return +(totalScore?.toFixed(2)); // Round to two decimal places
            };

            // Add totalScore to each deck and sort by totalScore in descending order
            const decksWithScores = filteredData.map(deck => ({
                ...deck,
                totalScore: calculateTotalScore(deck)
            })).sort((a, b) => b.totalScore - a.totalScore);

            // Update the state with the sorted array
            setDeckData(decksWithScores);
            setFilteredDecks(filteredData)

            // Calculate the average score of all decks
            const totalScoresSum = decksWithScores?.reduce((acc, deck) => acc + deck.totalScore, 0);
            const averageScore = totalScoresSum / decksWithScores.length;

            // Log or use the average score
            console.log('Average Score:', averageScore);
            setAverageDeckScore(averageScore);

            // Use filtered data as needed
            // ...

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    console.log(activeScoringCriteria)
    // Function to filter and flatten objects based on active scoring criteria
    const filterAndFlattenObject = (obj) => {
        const activeCriteriaNames = activeScoringCriteria.map(criteria => criteria.name);
        const weight = activeScoringCriteria.length ? 1 / activeScoringCriteria.length : 0;



        // Parse scores and calculate the total score dynamically
        const scores = {
            deckStructure: parseFloat(obj?.deckStructureScoreMatch) || 0,
            marketOpportunity: parseFloat(obj?.marketPotentialScoreMatch) || 0,
            marketingSales: parseFloat(obj?.marketingSalesScoreMatch) || 0,
            businessModel: parseFloat(obj?.businessModelScoreMatch) || 0,
            competitiveLandscape: parseFloat(obj?.competitiveLandscapeScoreMatch) || 0,
            team: parseFloat(obj?.teamScoreMatch) || 0,
            traction: parseFloat(obj?.tractionScoreMatch) || 0
        };

        // Calculate totalScore
        let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
            return acc + (scores[criteria.name] * weight);
        }, 0);
        totalScore = +(totalScore?.toFixed(2)); // Round to two decimal places

        const filteredObject = {
            user_id: obj.userId?._id,
            user_email: obj.userId?.email,
            user_founders: obj.userId?.founders,
            user_website: obj.userId?.website,
            user_companyName: obj.userId?.companyName,
            user_industry: obj.userId?.industry,
            user_companyDescription: obj.userId?.companyDescription,
            total_score: totalScore,

        };

        // Map the criteria to corresponding scores and paragraphs
        if (activeCriteriaNames.includes('businessModel')) {
            filteredObject.businessModelScoreMatch = obj.businessModelScoreMatch;
            filteredObject.businessModelParagraphMatch = obj.businessModelParagraphMatch;
        }
        if (activeCriteriaNames.includes('deckStructure')) {
            filteredObject.deckStructureScoreMatch = obj.deckStructureScoreMatch;
            filteredObject.deckStructureParagraphMatch = obj.deckStructureParagraphMatch;
        }
        if (activeCriteriaNames.includes('marketOpportunity')) {
            filteredObject.marketPotentialScoreMatch = obj.marketPotentialScoreMatch;
            filteredObject.marketPotentialParagraphMatch = obj.marketPotentialParagraphMatch;
        }
        if (activeCriteriaNames.includes('marketingSales')) {
            filteredObject.marketingSalesScoreMatch = obj.marketingSalesScoreMatch;
            filteredObject.marketingSalesParagraphMatch = obj.marketingSalesParagraphMatch;
        }
        if (activeCriteriaNames.includes('competitiveLandscape')) {
            filteredObject.competitiveLandscapeScoreMatch = obj.competitiveLandscapeScoreMatch;
            filteredObject.competitiveLandscapeParagraphMatch = obj.competitiveLandscapeParagraphMatch;
        }
        if (activeCriteriaNames.includes('team')) {
            filteredObject.teamScoreMatch = obj.teamScoreMatch;
            filteredObject.teamParagraphMatch = obj.teamParagraphMatch;
        }
        if (activeCriteriaNames.includes('traction')) {
            filteredObject.tractionScoreMatch = obj.tractionScoreMatch;
            filteredObject.tractionParagraphMatch = obj.tractionParagraphMatch;
        }

        return filteredObject;
    };

    // Function to convert data to CSV format
    const convertToCSV = (data) => {
        const filteredData = data.map(item => filterAndFlattenObject(item));
        const headers = Object.keys(filteredData[0]).join(','); // Extract headers
        const rows = filteredData.map(obj =>
            Object.values(obj).map(val => `"${val || ''}"`).join(',')
        );
        return [headers, ...rows].join('\n');
    };

    // Function to download the CSV file
    const downloadCSV = (data, filename = `${onBoarding?.companyName}-decks.csv`) => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        console.log(openNotifications)
    }, [openNotifications])


    const exportDeckDataToCSV = () => {
        if (!deckData || !deckData.length) {
            alert('No data available to export.');
            return;
        }

        // Define CSV headers
        const csvHeaders = [
            'Deck URL',
            'User Name',
            'User Email',
            'Company Name',
            'Status',
            'Decision',
            'Created At',
            'Updated At',
            'Feedback',
            'Admin Comments'
        ];

        // Initialize an array to hold CSV rows
        const csvRows = [];

        // Add the headers row
        csvRows.push(csvHeaders.join(','));

        // Function to escape special characters
        const escapeCSV = (text) => {
            if (text == null) {
                return '';
            }
            return `"${String(text).replace(/"/g, '""')}"`;
        };

        // Iterate over deckData
        deckData.forEach((deck) => {
            // Extract basic fields
            const deckURL = escapeCSV(deck.url);
            const userName = escapeCSV(deck.userId?.fullname);
            const userEmail = escapeCSV(deck.userId?.email);
            const companyName = escapeCSV(deck.companyId?.companyName);
            const status = escapeCSV(deck.status);
            const decision = escapeCSV(deck.decision);
            const createdAt = escapeCSV(new Date(deck.createdAt).toLocaleString());
            const updatedAt = escapeCSV(new Date(deck.updatedAt).toLocaleString());

            // Handle feedback array
            const feedback = (deck.feedback || []).map((fb) => {
                const fbUserName = fb.userId?.fullname || 'Unknown User';
                const fbScore = fb.score != null ? fb.score : 'N/A';
                const fbComment = fb.comment || '';
                return `User: ${fbUserName}, Score: ${fbScore}, Comment: "${fbComment}"`;
            }).join(' | ');
            const escapedFeedback = escapeCSV(feedback);

            // Handle AdminComments array
            const adminComments = (deck.AdminComments || []).map((ac) => {
                const adminName = ac.userId?.fullname || 'Unknown Admin';
                const adminComment = ac.comment || '';
                return `Admin: ${adminName}, Comment: "${adminComment}"`;
            }).join(' | ');
            const escapedAdminComments = escapeCSV(adminComments);

            // Create a CSV row
            const row = [
                deckURL,
                userName,
                userEmail,
                companyName,
                status,
                decision,
                createdAt,
                updatedAt,
                escapedFeedback,
                escapedAdminComments
            ];

            // Add the row to csvRows
            csvRows.push(row.join(','));
        });

        // Combine all rows into a single CSV string
        const csvString = csvRows.join('\n');

        // Trigger CSV download
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', 'deckData.csv');
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // loading ?
    //     <div style={{ background: 'white' }} >
    //         <div className={Classes.banner} >

    //         </div>
    //         <div className={Classes.mainRightBg} >
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     height: "100vh",
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <div id="loading-bar-spinner" class="spinner">
    //                     <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     :

    return (
        <>

            <AnimatePresence>
                {(onBoarding?.subscription === "free") &&
                    <UpgradeFreePopup />
                }
            </AnimatePresence>
            <div style={{ background: 'white' }} >
                <div className={Classes.banner} >
                    <div className={Classes.flexCenter} >
                        {/* <h5 className={Classes.fw600} >Team!</h5> */}
                    </div>
                    <div className={Classes.flexAlign} >

                    </div>
                    <div className={Classes.bannerRight} >
                        <Notifications />
                    </div>
                </div>
                <div className={Classes.mainRightBg} >
                    <div className={Classes.hero2} >
                        <div className={Classes.searcCon} >
                            <img src="/icons/search.png" alt="icon" />
                            <input type="text" placeholder="Search by company name"
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        {loading ? <SmallRowSkeleton height={'1.2rem'} width={'5rem'} /> :
                            <div className={`${Classes.flexAlign10} pointer ${Classes.mr10}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                                <h5 onClick={exportDeckDataToCSV}>Export</h5>
                            </div>
                        }
                    </div>
                    <div className={Classes.hero3} >
                        <div className={Classes.tableCon} >
                            <div className={Classes.tableHeader} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <div> <h5>Rank</h5> </div>
                                <div> <h5>Company name</h5> </div>
                                <div> <h5>Assigned scorers</h5> </div>
                                <div> <h5>Times scored</h5> </div>
                                <div> <h5>Overall score</h5> </div>
                                <div> <h5>Decision</h5> </div>
                                <div> <h5></h5> </div>
                            </div>
                            <div className={Classes.tableBody} >
                                {currentItems && currentItems.length > 0 ? currentItems?.map((item, index) => (
                                    <DemoRowComp key={item._id} value={item} index={(currentPage - 1) * itemsPerPage + index}
                                        setdecisionLoading={setdecisionLoading} />
                                )) :
                                    loading ? Array.from({ length: 5 }).map((_, index) => (
                                        <RowSkeleton key={index} />
                                    )) :
                                        <NoDataRow setdecisionLoading={setdecisionLoading} value={'Approve'} />
                                }
                                {decisionLoading &&
                                    <div className={Classes.loadingPopup} > <h5>Loading...</h5> </div>
                                }
                            </div>
                            <div className={Classes.pagFooter} >
                                <h5 style={{ height: '1.2rem' }} > {loading ? <SmallRowSkeleton height={'1.2rem'} width={'10rem'} /> : `Showing ${startItem} to ${endItem} of ${totalItems} results`} </h5>
                                {loading ? <SmallRowSkeleton height={'1.2rem'} width={'5rem'} /> :
                                    <Stack spacing={2} className={Classes.pagination}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            shape="rounded"
                                        />
                                    </Stack>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}