import * as React from 'react';
import { Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';

export default function DateTimePickup({DateValue, setDateValue}) {
    const companyInfo = useSelector((state) => state.companyInfo?.info);
    const theme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: companyInfo.color, // Change border color on focus
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&.Mui-focused': {
                            color: companyInfo.color, // Change label color on focus
                        },
                    },
                },
            },
        },
    });

    console.log(DateValue)
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ p: 2 }}>
                    <DateTimePicker
                        label="Date & Time picker"
                        value={DateValue}
                        onChange={(newValue) => setDateValue(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{
                                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: companyInfo.color, // Border color on focus
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: companyInfo.color, // Label color on focus
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
