// reducers.js
import { SET_COMPANY_INFO } from './actions';

const initialState = {
  info: process.env.NODE_ENV === 'development' ? {
    _id: '66c72fdd1f38c8f23e46ef67',
    logo: "https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/devzona-1724329946353.png",
    subscription: "demo_yearly_basic",
    companyName: "devzona",
    remaining_users: 500,
    total_users: 100,
    isFirstInteraction: false,
    videoSubmission: true,
    companyWebsite: 'devzona.com',
    color: "#3b2287"
  } : {}
};

const companyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      return state;
  }
};

export default companyInfoReducer;
