import React, { useState, useRef, useCallback, useEffect } from 'react'
import Classes from './Pricing.module.css'
import Color from 'color';
import { useSelector } from 'react-redux';
import MobileVersionPopup from '../../Global-Components/MobileVersionPopup/MobileVersionPopup';
import backend from '../../api';
import { motion, easeOut, AnimatePresence } from 'framer-motion';

const CheckSvg = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
)

export default function Setting() {

    const onBoarding = useSelector((state) => state.companyInfo?.info);
    const companyId = onBoarding?._id;
    const { user } = useSelector((state) => state.auth);
    const [isYearly, setisYearly] = useState(true);

    const email = user?.email;
    const convertToRgba = (colorInput, opacity) => {
        try {
            const color = Color(colorInput);
            return color.alpha(opacity).rgb().string();
        } catch (error) {
            console.error("Invalid color format:", colorInput);
            return `rgba(0, 0, 0, ${opacity})`;
        }
    };

    const onPaymentMonthlyBasic = async () => {

        const total_users = onBoarding?.total_users
        const remaining_users = 100;
        const companyName = onBoarding?.companyName
        const allowed_admins = 10;

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await backend.post(`/create-checkout-session`, {
            subscription: "monthly_basic",
            total_users: total_users,
            remaining_users: remaining_users,
            companyId: companyId,
            companyName: companyName,
            allowed_admins: allowed_admins,
            email: email
        })

        const url = response.data.url;
        window.location.href = url;
    }

    const onPaymentMonthlyEnterprise = async () => {

        const total_users = onBoarding?.total_users
        const remaining_users = 100000;
        const companyName = onBoarding?.companyName
        const allowed_admins = 100000;

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await backend.post(`/create-checkout-session`, {
            subscription: "monthly_pro",
            total_users: total_users,
            remaining_users: remaining_users,
            companyId: companyId,
            companyName: companyName,
            allowed_admins: allowed_admins,
            email: email
        })

        const url = response.data.url;
        window.location.href = url;
    }

    const onPaymentYearlyBasic = async () => {

        const total_users = onBoarding?.total_users
        const remaining_users = 1200;
        const companyName = onBoarding?.companyName
        const allowed_admins = 10;

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await backend.post(`/create-checkout-session`, {
            subscription: "yearly_basic",
            total_users: total_users,
            remaining_users: remaining_users,
            companyId: companyId,
            companyName: companyName,
            allowed_admins: allowed_admins,
            email: email

        })

        const url = response.data.url;
        window.location.href = url;
    }

    const onPaymentYearlyEnterprise = async () => {

        const total_users = onBoarding?.total_users
        const remaining_users = 100000;
        const companyName = onBoarding?.companyName
        const allowed_admins = 100000;

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await backend.post(`/create-checkout-session`, {
            subscription: "yearly_pro",
            total_users: total_users,
            remaining_users: remaining_users,
            companyId: companyId,
            companyName: companyName,
            allowed_admins: allowed_admins,
            email: email

        })

        const url = response.data.url;
        window.location.href = url;
    }

    return (
        <>
            <MobileVersionPopup />
            <div className={Classes.banner} >
                <h5 className={Classes.fw600} >Pricing</h5>
            </div>
            <div style={{ background: 'white' }} >
                <div className={Classes.hero} >
                    <div className={Classes.flexButtons} >
                        <div className={Classes.pricingBtns} >
                            <button onClick={() => setisYearly(false)} className={Classes.toggleButton} style={{ background: isYearly ? 'transparent' : onBoarding?.color, color: isYearly ? 'black' : 'white' }} >
                                <h5>Monthly</h5>
                            </button>
                            <button onClick={() => setisYearly(true)} className={Classes.toggleButton} style={{ background: isYearly ? onBoarding?.color : 'transparent', color: isYearly ? 'white' : 'black' }} >
                                <h5>Yearly</h5>
                            </button>
                        </div>
                        <h5 className={Classes.saveText} >Save 15% by billing annually</h5>
                    </div>
                    <div className={Classes.pricingMain} >
                        <motion.div
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 10 }}
                            transition={{
                                delay: 0.3,
                                duration: 0.3,
                            }}
                        >
                            <div className={`${Classes.pricingElem} ${Classes.padding3}`} style={{ border: 'none' }} >
                                <div className={Classes.header} >

                                </div>
                                <div className={Classes.listingCon} >
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Member Seats </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Invite admins and other team members
                                                    to collaborate and track submitted
                                                    companies.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Registered Companies </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    The amount of companies that can
                                                    register on your landing page
                                                    and submit their pitch decks.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Unique Landing Page </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Your unique land page link that you
                                                    send out to applicants for them to
                                                    register and submit decks.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > White-Labeled Tool </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Screening tool customized for your
                                                    ecosystem with a custom logo
                                                    and color scheme.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Custom AI model </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Select from 7 different AI scoring
                                                    criteria to analyze and score
                                                    submitted pitch decks.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Deck Analysis </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Submitted pitch decks are thoroughly
                                                    analyzed based on the custom AI
                                                    scoring criteria you choose.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Personalized Scoring </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Invite admins or scorers to add
                                                    personalized human scores and
                                                    comments to company submissions.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={Classes.flexEnd}>
                                        <h5 className={Classes.textBold} > Pipeline CRM </h5>
                                        <div className={Classes.tooltipCon} >
                                            <div className={Classes.tooltipHead}> <h5>?</h5> </div>
                                            <div className={Classes.tooltipBody}>
                                                <h5>
                                                    Pipeline enables ecosystems to
                                                    defer, request information, review
                                                    material, and approve companies.
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 10 }}
                            transition={{
                                duration: 0.4,
                            }}
                        >
                            <div className={Classes.pricingElem} >
                                <div className={Classes.header} >
                                    <h5 className={Classes.title1} > Basic </h5>
                                    <h1 className={`${Classes.price} ${Classes.priceRep}`} > Free Forever </h1>
                                    <h5 className={Classes.textLightMini} > </h5>
                                </div>
                                <div className={Classes.listingCon} >
                                    <div className={Classes.flex}> <h5 className={Classes.text} > 1 </h5> </div>
                                    <div className={Classes.flex}> <h5 className={Classes.text} > 10 per month </h5> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> -- </div>
                                    <div className={Classes.flex}> -- </div>
                                </div>
                                <div className='flex' >
                                    <div className={Classes.btnCon} >
                                        {onBoarding?.subscription === 'free' ?
                                            <div className={Classes.btnCon} >
                                                <h5 className={`${Classes.textLight} ${Classes.btnHeight} flex`} > Your current plan </h5>
                                            </div>
                                            :
                                            <button className={Classes.btnHeight} style={{ background: onBoarding?.color }}
                                            > <h5>Go free</h5> </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 10 }}
                            transition={{
                                delay: 0.1,
                                duration: 0.5,
                            }}
                        >
                            <div className={Classes.pricingElem} style={{ background: convertToRgba(onBoarding?.color, 0.10) }} >
                                <div className={Classes.header} >
                                    <h5 className={Classes.title1} > Pro </h5>
                                    <h1 className={Classes.price} > {isYearly ? '$99' : '$114'} <span>/month</span> </h1>
                                    <h5 className={Classes.textLightMini} > Billed {isYearly ? 'annually' : 'monthly'} </h5>

                                </div>
                                <div className={Classes.listingCon} >
                                    <div className={Classes.flex}> <h5 className={Classes.text} > 10 </h5> </div>
                                    <div className={Classes.flex}> <h5 className={Classes.text} > {isYearly ? '1200 per year' : '100 per month'}  </h5> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                </div>
                                {isYearly ?
                                    <div className='flex' >
                                        <div className={Classes.btnCon} >
                                            {onBoarding?.subscription === 'yearly_basic' ?
                                                <h5 className={`${Classes.textLight} ${Classes.btnHeight} flex`} > Your current plan </h5>
                                                :
                                                <button className={Classes.btnHeight} style={{ background: onBoarding?.color }}
                                                    onClick={onPaymentYearlyBasic}
                                                > <h5>Go yearly</h5> </button>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='flex' >
                                        <div className={Classes.btnCon} >
                                            {onBoarding?.subscription === 'monthly_basic' ?
                                                <h5 className={`${Classes.textLight} ${Classes.btnHeight} flex`} > Your current plan </h5>
                                                :
                                                <button className={Classes.btnHeight} style={{ background: onBoarding?.color }}
                                                    onClick={onPaymentMonthlyBasic}
                                                > <h5>Go monthly</h5> </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: 10 }}
                            transition={{
                                delay: 0.2,
                                duration: 0.6,
                            }}
                        >
                            <div className={Classes.pricingElem} >
                                <div className={Classes.header} >
                                    <h5 className={Classes.title1} > Enterprise </h5>
                                    <h1 className={Classes.price} > {isYearly ? '$199' : '$228'} <span>/month</span> </h1>
                                    <h5 className={Classes.textLightMini} > Billed {isYearly ? 'annually' : 'monthly'} </h5>
                                </div>
                                <div className={Classes.listingCon} >
                                    <div className={Classes.flex}> <h5 className={Classes.text} > Unlimited </h5> </div>
                                    <div className={Classes.flex}> <h5 className={Classes.text} > Unlimited </h5> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                    <div className={Classes.flex}> <CheckSvg height={18} fill={onBoarding?.color} /> </div>
                                </div>
                                {isYearly ?
                                    <div className='flex' >
                                        <div className={Classes.btnCon} >
                                            {onBoarding?.subscription === 'yearly_pro' ?
                                                <h5 className={`${Classes.textLight} ${Classes.btnHeight} flex`} > Your current plan </h5>
                                                :
                                                <button className={Classes.btnHeight} style={{ background: onBoarding?.color }}
                                                    onClick={onPaymentYearlyEnterprise}
                                                > <h5>Go yearly</h5> </button>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className='flex' >
                                        <div className={Classes.btnCon} >
                                            {onBoarding?.subscription === 'monthly_pro' ?
                                                <h5 className={`${Classes.textLight} ${Classes.btnHeight} flex`} > Your current plan </h5>
                                                :
                                                <button className={Classes.btnHeight} style={{ background: onBoarding?.color }}
                                                    onClick={onPaymentMonthlyEnterprise}
                                                > <h5>Go monthly</h5> </button>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </>
    )
}
