import React, { useState, useEffect, useRef } from 'react';
import { motion, easeOut } from "framer-motion";
import Classes from './SectionB.module.css';
import { Animate1 } from './Animations/Animate1';
const videoLink = 'https://pitch-bucket-s3.s3.us-east-2.amazonaws.com/PitchPerfecter+Blue+Pitch+deck.mp4'; //new video

function SectionB() {
  const videoRef = useRef(null);
  const [autoplay, setAutoplay] = useState(false);

  return (
    <section className={Classes.howItWorks}>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.5,
          ease: easeOut,
          delay: 3.2
        }}
      >
        <div className={Classes.videoCon}>
          <video ref={videoRef} muted autoPlay={true} loop playsInline>
            <source src={videoLink} type="video/mp4" />
          </video>
        </div>
      </motion.div>
    </section>
  );
}

export default SectionB;
