import React, { useState, useEffect, useRef } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import CommentElem from './Comp/CommentElem';
import ScoresComp from './Comp/ScoresComp';
import CommentComp from './Comp/CommentComp';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';

function SeeMorePopup({ closePopup, deck, setdecisionLoading }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);



    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const handleConfirmSubmit = (e) => {
        e.preventDefault();
    }

    const [scoresnav, setscoresnav] = useState(true);

    usePreventScrolling();


    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div className={Classes.flexBetween} >
                            <div></div>
                            <h5 className={Classes.title} >{deck?.userId?.companyName}</h5>
                            <div className='flex pointer' onClick={closePopup} >
                                <svg height='20px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                            </div>
                        </div>
                        <div className={Classes.navBtns} >
                            <button type='button' className={Classes.btn1}
                                style={{ background: scoresnav ? 'rgb(0 0 0 / 20%)' : 'transparent' }}
                                onClick={() => { setscoresnav(true) }}
                            >
                                <h5>Scores</h5>
                            </button>
                            <button type='submit' className={Classes.btn2}
                                style={{ background: scoresnav ? 'transparent' : 'rgb(0 0 0 / 20%)' }}
                                onClick={() => { setscoresnav(false) }}
                            >
                                <h5>Comments</h5>
                            </button>
                        </div>
                    </div>
                    {scoresnav ?
                        <ScoresComp deck={deck} setdecisionLoading={setdecisionLoading}/>
                        :
                        <CommentComp deck={deck} setdecisionLoading={setdecisionLoading}/>
                    }
                </motion.div>
            </motion.div>
        </>
    )
}

export default SeeMorePopup
