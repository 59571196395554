import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from 'react-redux';

export default function InviteTable({ allIvites }) {
    const companyInfo = useSelector((state) => state.companyInfo?.info);

    return (
        <TableContainer component={Paper}
            sx={{
                maxWidth: '30rem',
                marginTop: '1rem',
                marginLeft: '2px'
            }}
        >
            <Table sx={{ minWidth: '20rem' }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 600, fontFamily: 'Poppins', color: companyInfo.color ? companyInfo.color : 'black' }} >Email</TableCell>
                        <TableCell sx={{ fontWeight: 600, fontFamily: 'Poppins', color: companyInfo.color ? companyInfo.color : 'black' }} >Decision</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        allIvites?.length > 0 ? (
                            allIvites.map((invite, index) => (
                                <>
                                    {invite?.inviteeEmail ? (
                                        <>
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" sx={{ fontFamily: 'Poppins' }} > {invite.inviteeEmail} </TableCell>
                                                <TableCell sx={{ color: invite.isAccepted ? "green" : "red", fontFamily: 'Poppins' }} > {invite.isAccepted ? "Accepted" : "Not-accepted"} </TableCell>
                                            </TableRow>
                                        </>
                                    ) : (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{ fontFamily: 'Poppins' }} > Invitee email not available </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            ))
                        ) : (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" sx={{ fontFamily: 'Poppins' }} > No invites found </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}