import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import Classes from '../styles/style.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import Checkbox from '@mui/material/Checkbox';

export default function PrevInvestDropDown({ selected, onChange }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const previousInvestmentsOptions = [
        { value: 'under_50K', label: '<$50,000' },
        { value: '50K_to_100K', label: '$50K - $100K' },
        { value: '100K_to_250K', label: '$100K - $250K' },
        { value: '250K_to_500K', label: '$250K - $500K' },
        { value: '500K_to_1M', label: '$500K - $1M' },
        { value: '1M_to_5M', label: '$1M - $5M' },
        { value: '5M_to_10M', label: '$5M - $10M' },
        { value: '10M_to_25M', label: '$10M - $25M' },
        { value: '25M_plus', label: '$25M+' },
    ];

    const [dropDown, setdropDown] = useState(false);
    const handleClosedropdown = () => {
        setdropDown(false);
    }

    const [selectedOption, setSelectedOption] = useState(null);
    const handleSelect = (option) => {
        let newSelection;
        if (selected.includes(option.value)) {
            newSelection = selected.filter(item => item !== option.value);
        } else {
            newSelection = [option.value];
        }
        onChange(newSelection);
    };

    useEffect(() => {
        if (onBoarding) {
            document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
        }
    }, [onBoarding]);

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className={Classes.dropDown} ref={dropdownRef}>
                {/* Dropdown Head */}
                <div
                    className={Classes.SelectinputField}
                    onClick={() => setdropDown(!dropDown)}
                >
                    <p style={{ textTransform: 'capitalize' }} > Previous investments </p>
                    <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
                </div>

                {/* Dropdown Body */}
                <AnimatePresence>
                    {dropDown && (
                        <motion.div
                            initial={{ opacity: 0, y: -5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -5 }}
                            transition={{
                                duration: 0.15,
                            }}
                            className={Classes.dropDownBody}
                        >
                            <div className={Classes.ddPointerCon}>
                                <div className={Classes.dropDownPointer}></div>
                            </div>
                            <div className={Classes.dropDownBodyInner}>
                                <div>
                                    {previousInvestmentsOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className={`${Classes.dropDownElem} ${selected.includes(option.value) ? Classes.activeDropElem : ''}`}
                                        >
                                            <label htmlFor={index} >
                                                <h5>{option.label}</h5>
                                            </label>
                                            <Checkbox
                                              checked={selected.includes(option.value)}
                                              onChange={() => handleSelect(option)}
                                            
                                                id={index}
                                                sx={{
                                                    color: onBoarding.color,
                                                    padding: '5px',
                                                    '&.Mui-checked': {
                                                        color: onBoarding.color,
                                                    },
                                                    '& .MuiSvgIcon-root': { fontSize: 17 }
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
                <input
                    type="hidden"
                    aria-label="Without label"
                    readOnly
                />
            </div>
        </>
    )
}
