import React from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import sessionstorage from "sessionstorage";
import { useNavigate } from "react-router-dom";
// import './Auth.css';
import { useLocation } from 'react-router-dom';
import { motion, easeOut } from 'framer-motion';
import Classes from './WrongURL.module.css'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CryptoJS from 'crypto-js';


function WrongURL() {

  function moveToContact() {
    window.location.href = "https://www.evala.ai/contact-us";
  }

  return (

    <div className={Classes.backgroundImage} >
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.3,
          ease: easeOut,
          delay: 1,
        }}
      >
        <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
          <div>
            <a className={Classes.logoLink} href="https://www.evala.ai">
              <img className={Classes.logoImage} src='/Blue.png' alt="Logo" />
            </a>
          </div>
          <nav className={Classes.navMenu}>

          </nav>
          <div>
            <a href="https://www.evala.ai">
              <h5 className={Classes.goBackTxt} >Go back to evala.ai</h5>
            </a>
          </div>
        </div>
      </motion.div>

      <section className={`${Classes.section}`}>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.3,
            ease: easeOut,
            delay: 1,
          }}
        >
          <div className={Classes.boxStyles}>
            <a href="https://www.evala.ai" className={Classes.miniLogo} >
              <img src="/site.png" alt="logo" />
            </a>
            <h1 className={Classes.heading}>Wrong URL</h1>
            <p className={Classes.subtitle} > This URL is not registered to a company. Please contact the administrator for more information.</p>
            <br />

            <p className={Classes.subtitle}>
              Any questions or concerns:
            </p>
            <button onClick={() => moveToContact()} style={{ background: "#0262DF" }} className={`${Classes.button} ${Classes.signInButton}`}>
              Contact Us
            </button>

          </div>
        </motion.div>
      </section>
    </div>
  )
}

export default WrongURL;