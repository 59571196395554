import React, { useState, useEffect } from "react";
import Classes from './LiveDemo.module.css';
import SummaryPopup from "./SummaryPopup";
import PageSelctorComp from "./PageSelctorComp";
import MobileVersionPopup from "../../Global-Components/MobileVersionPopup/MobileVersionPopup";
import Loading from "../../Global-Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import Color from 'color';
import { AnimatePresence } from "framer-motion";

const convertToRgba = (colorInput, opacity) => {
    try {
        const color = Color(colorInput);
        return color.alpha(opacity).rgb().string();
    } catch (error) {
        return `rgba(0, 0, 0, ${opacity})`;
    }
};

const styles = {
    fontFamily: "Manrope, sans-serif",
    fontSize: "16px",


    color: "black",
    whiteSpace: "pre-wrap", // allow wrapping of long lines
    wordWrap: "break-word", // break long words

};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto", // Adjust width as needed
    maxWidth: "70vw", // Maximum width of the modal is 80% of the viewport width
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto", // Enable scrolling if content is too long
    maxHeight: "65vh", // Maximum height is 80% of the viewport height
    // margin: "5vh", // Adds margin to the top & bottom of the modal (5% of the viewport height)
    borderRadius: "15px",
    outline: 'none',
};

function LiveDemo() {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const Navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [openSummary, setopenSummary] = useState(false);

    const handleCloseSummary = () => {
        setopenSummary(false);
    }

    const [Deck, setDeck] = useState([
        {
            name: 'Deck 1',
            imageUrl: '/demo/stripe-01.jpg',
            feedback: `The first slide effectively showcases your logo and slogan, setting a professional tone for the presentation.`,
            suggestions: `Consider adding a dynamic illustration of your payment platform in action to immediately engage your audience.`,
        },
        {
            name: 'Deck 2',
            imageUrl: '/demo/stripe-02.jpg',
            feedback: `Stripe's claim of being the best platform for internet business operations is strongly supported by its large-scale financial handling and global client base.`,
            suggestions: `It is recommended to include specific metrics or case studies that demonstrate customer satisfaction and operational success to further strengthen the credibility of the claim. Additionally, highlighting unique features or technological advancements could provide a clearer differentiation from competitors in the market.`,
        },
        {
            name: 'Deck 3',
            imageUrl: '/demo/stripe-03.jpg',
            feedback: `The content effectively communicates Stripe's mission and capabilities, emphasizing its technological approach to solving payment issues.`,
            suggestions: `To strengthen the pitch, consider including specific examples of new features added recently and testimonials from businesses that have benefited from using Stripe. Highlighting specific case studies can make the impact of Stripe’s offerings more tangible to potential clients or investors.`,
        },
        {
            name: 'Deck 4',
            imageUrl: '/demo/stripe-04.jpg',
            feedback: `Based on data in this slide, Stripe appears to support a wide range of business types including e-commerce & retail, B2B platforms, SaaS, B2C marketplaces, and non-profits & fundraising. This diversity suggests that Stripe offers versatile payment processing solutions that can be tailored to different business needs.`,
            suggestions: `To enhance the pitch, consider including specific case studies or testimonials from businesses in these categories that have successfully used Stripe. Additionally, highlighting key features or benefits that Stripe offers to each business type could further strengthen the slide's impact.`,
        },
        {
            name: 'Deck 5',
            imageUrl: '/demo/stripe-05.jpg',
            feedback: `The information clearly illustrates how online businesses are adapting to and overcoming global trade challenges. It highlights the role of technology in shaping modern globalization.`,
            suggestions: `Consider incorporating case studies or examples of specific companies that exemplify this new wave of globalization. Additionally, exploring the impact of regulatory changes on online businesses could provide deeper insights into the challenges faced by these companies.`,
        },
        {
            name: 'Deck 6',
            imageUrl: '/demo/stripe-06.jpg',
            feedback: `The slide titled "Our mission is to increase the GDP of the internet" effectively conveys the company's overarching goal. The message is clear and ambitious, highlighting a commitment to enhancing economic activity online.`,
            suggestions: `To enhance this slide further, consider incorporating a brief explanation or a couple of bullet points detailing how your company plans to achieve this mission. This could include specific strategies, tools, or services you offer that contribute to the growth of internet GDP. Additionally, including a visual element, such as an infographic or a symbolic representation, could make the slide more engaging and memorable, though you've requested no design elements in this instance.`,
        },
        {
            name: 'Deck 7',
            imageUrl: '/demo/stripe-07.jpg',
            feedback: `The slide presents a comprehensive structure of payment systems, each with a potential unique function within the overall architecture. Radar likely refers to a fraud detection service, Sigma might be related to data analytics, Billing could be the invoicing system, Atlas might suggest a business incorporation or entity management tool, Payment could be the core payment processing system, and Connect could be an API for integrating with other platforms or services.`,
            suggestions: `To enhance the slide's impact and clarity, consider including a brief description or a single sentence for each component to explain its role in the payment stack. This would help stakeholders quickly grasp the function and importance of each element in the system. Additionally, ensure that each component name is consistent in style and format to maintain professional aesthetics and readability.`,
        },
        {
            name: 'Deck 8',
            imageUrl: '/demo/stripe-08.jpg',
            feedback: `Based on data in this slide, Stripe is highlighted as a leading developer platform that emphasizes its rapid advancements in both code and design. It provides comprehensive tools and technology that help businesses innovate and improve their operations efficiently.`,
            suggestions: `Consider highlighting specific examples or case studies to showcase how Stripe's tools have directly impacted businesses. Also, including testimonials or quotes from current users could add a personal touch and give credibility to the claims about the platform's effectiveness. Additionally, expanding on how Stripe supports its developer community or details about its partner ecosystem could provide a clearer picture of the platform's reach and influence.`,
        },
        {
            name: 'Deck 9',
            imageUrl: '/demo/stripe-09.jpg',
            feedback: `based on data in this slide, the content highlights the collaboration of Stripe with various financial entities to simplify financial operations for businesses. By managing interactions with financial institutions, regulators, payment networks, banks, and consumer wallets, Stripe alleviates the need for businesses to do so directly.`,
            suggestions: `Ensure to represent each entity like Google Pay, Visa, Mastercard, etc., as operational partners clearly that Stripe collaborates with. Highlight the advantages such as multi-currency support, enhanced security with ISO, and simplified transaction processes with entities like American Express and JCB. This will strengthen the message of removing financial complexity and underline the comprehensive support Stripe provides. Include clear, concise data points or case studies that underscore the benefits businesses have realized by integrating Stripe's solutions.`,
        },
        {
            name: 'Deck 10',
            imageUrl: '/demo/stripe-10.jpg',
            feedback: `Based on data in this slide, Stripe was launched in 2011 and has grown to employ over 2,000 people across 14 global offices. The company serves millions of users worldwide.`,
            suggestions: `Ensure the slide visually highlights the growth trajectory and global reach of Stripe to emphasize the scale and impact of the company. Consider using a timeline or a global map to represent the expansion and user distribution effectively.`,
        },
        {
            name: 'Deck 11',
            imageUrl: '/demo/stripe-11.jpg',
            feedback: `Based on data in this slide, anthropogenic climate change is indeed accelerating, driven primarily by increased greenhouse gas emissions from human activities such as fossil fuel combustion, deforestation, and industrial processes. The evidence is manifested in rising global temperatures, shrinking ice masses, elevated sea level, and more frequent extreme weather events.`,
            suggestions: `It is crucial to intensify efforts in reducing carbon emissions, implementing sustainable energy solutions, and enforcing environmental regulations. Public awareness and education on climate change impacts and mitigation strategies should also be enhanced to drive collective action.`,
        },
        {
            name: 'Deck 12',
            imageUrl: '/demo/stripe-12.jpg',
            feedback: `The current slide effectively conveys the message of collaboration and growth within the internet economy through partnerships. The focus on technology and software that aids businesses in becoming more operational online is clear and aligns well with strategic goals.`,
            suggestions: `To enhance the slide, consider including specific examples or case studies that illustrate successful partnerships. This could provide a clearer picture of the potential benefits for new partners. Additionally, adding a few key statistics about the growth of the internet economy or the impact of your program could make the argument more compelling. It's also advisable to define what types of support or resources the program offers to partners to help them scale their impact. Finally, ensure that the call to action is prominent and clear, guiding potential partners on how to engage with your program effectively.`,
        },
        {
            name: 'Deck 13',
            imageUrl: '/demo/stripe-13.jpg',
            feedback: `The slide content includes various themes, primarily focusing on business insights, resource guides, recruiting strategies, and compliance protocols. It also touches on the specifics of payment facilitators, payment flows, and trends in online funding behavior. Each topic is critical for understanding different facets of managing and scaling a business in today's competitive environment.`,
            suggestions: `To enhance the clarity and impact of the slide, consider organizing the content into related groups or categories. For example, grouping all items related to 'Business Insights' together and similarly for 'Industry Updates'. This will help in delivering a more structured and digestible presentation. Additionally, integrating visual aids or icons next to each point could make the slide more engaging and easier to navigate, even though visuals are not to be added in this instance. Finally, ensure that each point is actionable and directly tied to resources or insights that the audience can utilize for immediate benefits.`,
        },
        {
            name: 'Deck 14',
            imageUrl: '/demo/stripe-14.jpg',
            feedback: `The slide provides a clear overview of Stripe's leadership team, showcasing the roles and responsibilities of top executives. This structure highlights the company's strong leadership foundation.`,
            suggestions: `To enhance the slide, consider including brief biographies or notable achievements for each executive to provide more context about their expertise and impact at Stripe. Additionally, incorporating visuals such as headshots could make the slide more engaging and personalized.`,
        },
        {
            name: 'Deck 15',
            imageUrl: '/demo/stripe-15.jpg',
            feedback: `The slide provides clear contact information for general queries and partnership opportunities. This is crucial for communication and helps in establishing initial contact with interested parties.`,
            suggestions: `Ensure that the email address is clickable to facilitate easy communication. Also, consider adding more specific contact details for different departments or reasons for contact to streamline communication further. Additionally, adding social media handles could enhance accessibility and engagement with your audience.`,
        },
        {
            name: 'Deck 16',
            imageUrl: '/demo/stripe-16.jpg',
            feedback: `The current slide is lacking in detailed information which is essential for a thorough analysis and better understanding of the purpose behind the logo.`,
            suggestions: `It would be beneficial to include more context or a brief description regarding the logo, such as its design elements, color scheme, and the branding message it intends to convey. This will enhance the presentation and provide clearer insights into how the logo contributes to the company's brand identity.`,
        },

    ]);

    const goToLogin = () => {
        Navigate('/login');
    }

    const goToHome = () => {
        Navigate('/');
    }

    const downloadPdf = () => {
        const link = document.createElement('a');
        link.href = 'https://pitchdecktemplate.s3.us-east-2.amazonaws.com/Ultimate+Pitch+Deck+Template.zip';
        link.setAttribute('download', '');
        // link.target = '_blank'
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const [pageIndex, setpageIndex] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => {
            setloading(false);
        }, 2500);
    }, []);


    return (
        <div className={Classes.container} >
            <>
                {loading && <Loading />}
                <MobileVersionPopup />
                <div className={Classes.innerCon} >
                    <div className={Classes.leftCon} >
                        <div className={Classes.listCon} >
                            {/* <br /> */}
                            <div className={Classes.logoCon} >
                                <img className={Classes.logo1} onClick={goToHome} src={onBoarding.logo} />
                                <img className={Classes.logo2} onClick={goToHome} src='/onBoarding/left-arrow.svg' />
                            </div>
                            <div className={Classes.scroll} >
                                {Deck.map((deck, index) => {
                                    return (<>
                                        <PageSelctorComp imageUrl={deck.imageUrl} key={index} index={index} pageIndex={pageIndex} setpageIndex={setpageIndex} />
                                    </>)
                                })
                                }
                                <br />
                            </div>
                        </div>
                    </div>
                    <div className={Classes.con1} >
                        <div className={Classes.swiperOuter} >
                            <div className={Classes.swiperCon} >
                                <div className={Classes.swiperUnloadedCon} >
                                    <img src={Deck[pageIndex].imageUrl} className={Classes.swiperImg} alt={`Sample Page 2`} />
                                </div>
                            </div>
                        </div>

                        <div className={Classes.boxesCon} >

                            <div className={Classes.feedbackCon} >
                                <div >
                                    <img src="/feedback.png" />
                                    <h3> Feedback </h3>
                                </div>
                                <p>
                                    <pre style={{
                                        ...styles,
                                        flex: "1",
                                        minWidth: "0",
                                        margin: '0',
                                    }}>
                                        {
                                            Deck[pageIndex].feedback
                                        }
                                    </pre>
                                </p>

                            </div>

                            <div className={Classes.suggestCon} >
                                <div >
                                    <img src="/icons/suggestion.png" />
                                    <h3> Suggestions </h3>
                                </div>
                                <p>
                                    <pre style={{
                                        ...styles,
                                        flex: "1",
                                        minWidth: "0",
                                        margin: '0',
                                    }}>
                                        {
                                            Deck[pageIndex].suggestions
                                        }
                                    </pre>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className={Classes.scoreBoxCon} >
                        <div className={Classes.scoreBox} >
                            <h3 className="fw700" >Scoring breakdown</h3>
                            <h5 onClick={() => { setopenSummary(true) }} style={{ color: onBoarding.color ? onBoarding.color : "red" }} >Read full summary {`>`}</h5>
                            <AnimatePresence>
                                {openSummary &&
                                    <SummaryPopup
                                        style={style}
                                        openSummary={openSummary}
                                        handleCloseSummary={handleCloseSummary}
                                    />
                                }
                            </AnimatePresence>
                            <div className={Classes.padCon} >
                                <hr />
                                <div className={Classes.linesCon} >
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/deck.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Deck Structure </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 80 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/market.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Market Opportunity </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle}> 75 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/marketing.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Marketing & Sales </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 78 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/business.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Business Model </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 85 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/competitive.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Competitive Landscape </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 74 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/team.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Team </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 82 </h4>
                                    </div>
                                    <div className={Classes.scoringGrid} >
                                        <div className={Classes.flex1} >
                                            <img src="/onBoarding/traction.png" alt="Image" />
                                            <h4 className={Classes.miniTitle} >Traction </h4>
                                        </div>
                                        <h4 className={Classes.miniTitle} > 85 </h4>
                                    </div>
                                </div>
                            </div>
                            <div className={`${Classes.overallCon} ${Classes.padCon}`} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <h4 className={Classes.miniTitle} > Overall deck score </h4>
                                <h4 className={Classes.miniTitle} >
                                    80
                                </h4>
                            </div>
                            {/* <div className={`${Classes.templateOuter} ${Classes.padCon} `} >
                                <div className={Classes.templateCon} style={{ background: convertToRgba(onBoarding.color, 0.05) }} >
                                    <img src="/icons/download.svg" alt="download" />
                                    <h4 className={Classes.download} >Download here</h4>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
}

export default LiveDemo;
