import React from 'react'
import Classes from './PitchAnalyzer.module.css'
import { useSelector } from 'react-redux';

function PageSelctorComp({imageUrl,index,pageIndex,setpageIndex}) {
    const handleClick = ()=>{
        setpageIndex(index);
    }
    
    const onBoarding = useSelector((state) => state.companyInfo.info);

    return (
        <>
            <div className={Classes.listBox} onClick={handleClick} style={{ border: pageIndex === index ? `2px solid ${onBoarding.color}` : '' }} >
                <div className={Classes.boxImgCon} >
                    <img src={imageUrl} />
                </div>
                <h5> Slide {index+1} </h5>
            </div>
        </>
    )
}

export default PageSelctorComp