import React, { useState, useRef, useCallback, useEffect } from 'react'
import Color from 'color';
import Classes from './Setting.module.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import backend from '../../api';
import axios from 'axios';
import { setCompanyInfo } from '../../redux-store/auth/actions';
import debounce from 'lodash.debounce';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useNavigate } from 'react-router-dom';
import GmailInstructions from './Components/GmailInstructions';
import OutlookInstructions from './Components/OutlookInstructions';
import YahooInstructions from './Components/YahooInstructions';
import DeleteIcon from '@mui/icons-material/Delete';
import OnBoarding from '../OnBoarding/OnBoarding';
import MobileVersionPopup from '../../Global-Components/MobileVersionPopup/MobileVersionPopup';
import InviteTable from './InviteTable';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import DateTimePickup from './Components/DateTimePickup';
import dayjs from 'dayjs';
import TextEditor from './Components/TextEditor';
import MsgEditorPopup from '../../Global-Components/MsgEditorPopup/MsgEditorPopup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CompanyCriteriaPopup from './Components/CompanyCriteriaPopup';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const InfoSvg = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        style={{
            enableBackground: "new 0 0 512 512",
        }}
        viewBox="0 0 512 512"
        {...props}
        height="1rem"
        cursor="pointer"
    >
        <path d="M290.211 360.727a24.896 24.896 0 0 1-15.011-3.375 15.593 15.593 0 0 1-4.305-12.8 66.49 66.49 0 0 1 1.396-11.636c.781-4.394 1.79-8.744 3.025-13.033l13.731-47.244a71.535 71.535 0 0 0 2.793-14.313c0-5.236.698-8.844.698-10.938a30.602 30.602 0 0 0-10.822-24.32 45.5 45.5 0 0 0-30.72-9.309 79.72 79.72 0 0 0-23.273 3.84c-8.223 2.56-16.873 5.624-25.949 9.193l-3.956 15.36c2.676-.931 5.935-1.978 9.658-3.142a39.572 39.572 0 0 1 10.938-1.629 22.343 22.343 0 0 1 14.778 3.607 16.875 16.875 0 0 1 3.84 12.684 55.19 55.19 0 0 1-1.28 11.636c-.815 4.073-1.862 8.378-3.142 12.916L218.763 325.7a135.651 135.651 0 0 0-2.676 13.382 83.118 83.118 0 0 0-.815 11.636 30.369 30.369 0 0 0 11.636 24.087 46.55 46.55 0 0 0 31.185 9.542 71.78 71.78 0 0 0 23.273-3.375c6.594-2.25 15.399-5.469 26.415-9.658l3.724-14.662a64.283 64.283 0 0 1-9.193 3.025 44.19 44.19 0 0 1-12.101 1.05zM304.756 136.727A33.163 33.163 0 0 0 281.483 128a33.396 33.396 0 0 0-23.273 8.727c-11.632 10.03-12.931 27.591-2.9 39.224a27.762 27.762 0 0 0 2.9 2.9c13.252 11.853 33.294 11.853 46.545 0 11.632-10.129 12.851-27.769 2.722-39.401a27.754 27.754 0 0 0-2.721-2.723z" />
        <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm0 488.727C127.468 488.727 23.273 384.532 23.273 256S127.468 23.273 256 23.273 488.727 127.468 488.727 256 384.532 488.727 256 488.727z" />
    </svg>
)
const GmailSvg = () => (
    <svg
        id="fi_5968534"
        enableBackground="new 0 0 512 512"
        height="30"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g>
            <path
                d="m34.909 448.047h81.455v-197.818l-53.338-93.138-63.026 5.865v250.182c0 19.287 15.622 34.909 34.909 34.909z"
                fill="#0085f7"
            ></path>
            <path
                d="m395.636 448.047h81.455c19.287 0 34.909-15.622 34.909-34.909v-250.182l-62.935-5.865-53.428 93.138v197.818z"
                fill="#00a94b"
            ></path>
            <path
                d="m395.636 98.956-47.847 91.303 47.847 59.97 116.364-87.273v-46.545c0-43.142-49.251-67.782-83.782-41.891z"
                fill="#ffbc00"
            ></path>
            <path
                clipRule="evenodd"
                d="m116.364 250.229-45.593-96.31 45.593-54.963 139.636 104.727 139.636-104.727v151.273l-139.636 104.727z"
                fill="#ff4131"
                fillRule="evenodd"
            ></path>
            <path
                d="m0 116.411v46.545l116.364 87.273v-151.273l-32.582-24.436c-34.531-25.891-83.782-1.251-83.782 41.891z"
                fill="#e51c19"
            ></path>
        </g>
    </svg>
);
const TickSvg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 448 512"><path fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
)

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Setting() {

    const companyInfo = useSelector((state) => state.companyInfo?.info);
    const { user } = useSelector((state) => state.auth);
    const [brochureLoading, setBrochureLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrollToElement = (elementId) => {
        const targetElement = document.getElementById(elementId);

        if (targetElement) {
            const offset = 0;
            const targetPosition = targetElement.offsetTop + offset;

            window.scroll({
                top: targetPosition,
                behavior: "smooth",
            });
        }
    };

    const [activeInstr, setactiveInstr] = useState('');
    const triggerInstr = (value) => {
        setactiveInstr(value);
        setTimeout(() => {
            scrollToElement('connectEmail');
        }, 500);
    }

    const convertToRgba = (colorInput, opacity) => {
        try {
            const color = Color(colorInput);
            return color.alpha(opacity).rgb().string();
        } catch (error) {
            console.error("Invalid color format:", colorInput);
            return `rgba(0, 0, 0, ${opacity})`;
        }
    };

    const [companyName, setcompanyName] = useState(companyInfo?.companyName);
    const [companyWebsite, setcompanyWebsite] = useState(companyInfo?.companyWebsite);
    const [scoringCriteria, setscoringCriteria] = useState(companyInfo?.scoringCriteria);
    const [colorScheme, setcolorScheme] = useState(companyInfo?.color);
    const [image, setImage] = React.useState(companyInfo?.logo);
    const [imageURL, setImageURL] = React.useState(companyInfo?.logo);
    const [announcement, setannouncement] = useState(companyInfo?.announcement);
    const [companyCriteria, setcompanyCriteria] = useState(companyInfo?.onboardingCriteria?.isActive);
    const [videoSubmission, setVideoSubmission] = useState(companyInfo?.videoSubmission);
    const [closeSubmission, setCloseSubmission] = useState(companyInfo?.closeSubmission?.isActive ?? false);
    const [enableRegistration, setEnableRegistration] = useState(companyInfo?.regMsg?.isActive ?? false);
    const dateObject = companyInfo?.closeSubmission?.value ?? new Date();  // Using the date from backend or defaulting to new Date

    const dayjsObject = dayjs(dateObject);  // Convert the date object to a dayjs object

    // Initialize DateValue state with the dayjs object
    const [DateValue, setDateValue] = useState(dayjsObject);
    console.log(dateObject)

    const [loading, setLoading] = useState(false);

    const userID = user?._id
    const userId = user?._id;
    const companyId = companyInfo?._id;

    const [inviteAdmin, setinviteAdmin] = useState('');

    const [email, setEmail] = useState('');
    const [inviteeName, setInviteeName] = useState('User');
    const [createdInviteId, setCreatedInviteId] = useState('');
    const [allIvites, setAllIvites] = useState([]);

    const onboardingId = companyInfo?._id;
    const handleToggleCloseSubmission = async () => {
        const newStatus = !closeSubmission;  // Toggle the status
        setCloseSubmission(newStatus);

        // Make the API request to update isActive status
        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                closeSubmission: {
                    isActive: newStatus,
                },
            });
            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))

            }

        } catch (error) {
            console.error("Error updating close submission status:", error);
        }
    };

    const [openCompanyCriteria, setopenCompanyCriteria] = useState(false);
    const existingCriteria = companyInfo?.onboardingCriteria || {}; // Fallback to an empty object if not available

    const handleCloseCompanyCriteriaPopup = () => {
        setopenCompanyCriteria(false);
    }
    const handleToggleCompanyCriteria = async () => {
        const newStatus = !companyCriteria;  // Toggle the status
        setcompanyCriteria(newStatus);
        setopenCompanyCriteria(newStatus);

        // Make the API request to update isActive status
        const updatedCriteria = {
            ...existingCriteria,          // Spread the existing criteria
            isActive: newStatus          // Update only the isActive field
        }

        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                onboardingCriteria: updatedCriteria, // Send the merged object

            });
            console.log('Success:', response);
            if (response.status === 200) {
                await dispatch(setCompanyInfo(response?.data?.data))

            }

        } catch (error) {
            console.error("Error updating registration message status:", error);
        }

    }

    const [closeSbmissionSuccess, setcloseSbmissionSuccess] = useState(false);
    const [closeSbmissionLoading, setcloseSbmissionLoading] = useState(false);
    const handleConfirmCloseSubmission = async () => {
        const dateToStore = DateValue.toDate();
        setcloseSbmissionLoading(true);
        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                closeSubmission: {
                    value: dateToStore,
                    isActive: closeSubmission,
                },
            });

            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))
            }
            setcloseSbmissionLoading(false);
            setcloseSbmissionSuccess(true);
            setTimeout(() => {
                setcloseSbmissionSuccess(false);
            }, 5000);
            // alert('Close Submission updated successfully!');
        } catch (error) {
            console.error("Error updating close submission:", error);
        }
    };

    const [openMsgEditor, setopenMsgEditor] = useState(false);
    const handleCloseMsgEditor = () => {
        setopenMsgEditor(false);
    }

    const handleToggleRegistration = async () => {
        const newStatus = !enableRegistration;  // Toggle the status
        setEnableRegistration(newStatus);
        if (newStatus) {
            setopenMsgEditor(true);
        }

        // Make the API request to update isActive status
        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                regMsg: {
                    isActive: newStatus,
                },
            });
            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))

            }

        } catch (error) {
            console.error("Error updating registration message status:", error);
        }
    };

    const createInvite = async (id) => {
        const body = {
            senderId: userID,
            inviteeEmail: email,
        };

        try {
            // Include method to send the body with the request
            const response = await backend.post(`/api/invite`, body); // Pass body as the second argument to the PUT request
            if (response) {
                console.log(response)
                setCreatedInviteId(response?.data?._id)
                sendEmail(response?.data?._id)
                getAllInvites();
                // window.location.reload();
            }
            // You might want to do something with the response here, e.g., update UI or state

        } catch (error) {
            console.error(error);
            // Uncomment or modify the toast error message as needed
            // toast.error("Error saving story");
        }
    };

    const getAllInvites = async () => {

        try {
            const response = await backend.get(`/api/invite`);
            if (response && Array.isArray(response.data)) {
                console.log(response.data); // Verifying the response data structure
                // Normalize the ID format before comparison, assuming userID is a string
                const userInvites = response.data.filter(invite => invite.senderId.toString() === userID);
                console.log(userInvites); // Check filtered results
                setAllIvites(userInvites); // Make sure this is the correct function name
            }
        } catch (error) {
            console.error(error);
            // toast.error("Error retrieving invites"); // Display error notification if you have a toast system set up
        }
    };

    // console.log(allIvites)
    // console.log(user)

    const sendEmail = async (id) => {

        const link = `https://${companyInfo.companyName}.evala.ai/${userId}/${id}/${companyId}`;
        // const emailBody = `${companyInfo.companyName} invited you to be an admin. Visit this link to join:${messageLink} `;

        const companyName = companyInfo?.companyName?.toUpperCase();
        const body = {
            email: email, // Make sure this 'email' variable is defined somewhere in your scope
            companyName: companyName,
            link: link
        };

        try {
            // Include method to send the body with the request
            await backend.post(`/email/adminmail`, body); // Pass body as the second argument to the PUT request
            // You might want to do something with the response here, e.g., update UI or state

            setLoading(false);
        } catch (error) {
            console.error(error);
            // Uncomment or modify the toast error message as needed
            // toast.error("Error saving story");
        }
    };

    const DeleteInvite = async (id) => {

        try {
            // Include method to send the body with the request
            const response = await backend.delete(`/api/invite/${id}`); // Pass body as the second argument to the PUT request
            if (response) {
                window.location.reload();
            }
            // You might want to do something with the response here, e.g., update UI or state

            setLoading(false);
        } catch (error) {
            console.error(error);
            // Uncomment or modify the toast error message as needed
            // toast.error("Error saving story");
        }
    };

    const handleImage = (e) => {
        const file = e.target.files[0];
        setImage(file);
        setImageURL(URL.createObjectURL(file));
    };

    const imageInputRef = useRef(null);

    const handleClick = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };

    const handleCheckboxChange = (index) => {
        setscoringCriteria(prevState => {
            const newState = [...prevState];
            newState[index].status = !newState[index].status;
            debouncedUpdateCompany()
            return newState;
        });
    };

    const [openUpdateCompanySuccess, setopenUpdateCompanySuccess] = useState(false);
    const UpdateCompany = async () => {
        // const formdata = new FormData();
        // formdata.append('companyName', companyName);
        // formdata.append('companyWebsite', companyWebsite);
        // formdata.append('scoringCriteria', scoringCriteria);
        // formdata.append('colorScheme', colorScheme);
        // formdata.append('image', image);
        const body = {
            userId: userId,
            companyName: companyName,
            companyWebsite: companyWebsite,
            scoringCriteria: scoringCriteria,  // Ensure this is an array of objects
            color: colorScheme,
            announcement: announcement,
            logo: imageURL,
            videoSubmission: videoSubmission
        }
        try {
            const response = await backend.put(`/onboarding/${companyId}`, body);

            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))
            }
            // setopenUpdateCompanySuccess(true);
            // setTimeout(() => {
            //     setopenUpdateCompanySuccess(false);
            // }, 5000);
            // window.location.reload();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const debouncedUpdateCompany = useCallback(debounce(UpdateCompany, 500), [companyName, companyWebsite, scoringCriteria, colorScheme, announcement, imageURL, videoSubmission]);

    const uploadMedia = async (files, name) => {

        setBrochureLoading(true);
        try {
            const formData = new FormData();
            Array.from(files).forEach((file) => formData.append("files", file));
            console.log(formData)
            const response = await axios.post("https://evala-aws-server-f7f37b27f032.herokuapp.com/api/deck/upload", formData)
            console.log(response)
            if (response?.data?.data[0]?.imgurl) {
                setImageURL(response.data.data[0].imgurl);
            }


        } catch (error) {
            alert(error.response?.data?.message || error.message || "An error occurred");
        } finally {
            setBrochureLoading(false);

        }
    };

    const handleInputChange = (e) => {
        const files = e.target.files;
        const name = e.target.name;
        handleFileChange(files, name);
    };

    const handleFileChange = (files, name) => {

        uploadMedia(files, name);

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        UpdateCompany()

    };

    useEffect(() => {

        debouncedUpdateCompany(); // Call debouncedUpdateCompany after setting the state


    }, [imageURL])

    useEffect(() => {
        UpdateCompany();

    }, [videoSubmission])

    console.log(videoSubmission)

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Check if companyName exists in database
                const response = await backend.get(`/onboarding/?userID=${userId}`);
                // console.log(response.data[0])
                // If response.data is not empty, companyName exists in database
                if (response.data.length > 0) {
                    dispatch(setCompanyInfo(response.data[0]))
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        getAllInvites();

    }, []); // Empty dependency array ensures useEffect runs once on component mount


    console.log(allIvites?.length === companyInfo?.allowed_admins);

    return (
        <>
            <Snackbar open={openUpdateCompanySuccess} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert severity="success" sx={{ backgroundColor: companyInfo?.color ? companyInfo?.color : '#2e7d32' }} >
                    Company Info updated successfully!
                </Alert>
            </Snackbar>
            <MobileVersionPopup />
            <AnimatePresence>
                {openMsgEditor &&
                    <MsgEditorPopup enableRegistration={enableRegistration}
                        closePopup={handleCloseMsgEditor}
                    />
                }
            </AnimatePresence>
            <AnimatePresence>
                {openCompanyCriteria &&
                    <CompanyCriteriaPopup closePopup={handleCloseCompanyCriteriaPopup} />
                }
            </AnimatePresence>
            <div className={Classes.banner} >
                <h5 className={Classes.fw600} >Settings</h5>
            </div>
            <div style={{ background: 'white' }} >
                <div className={Classes.hero} >
                    <h5 className={Classes.title} >Branding</h5>
                    <div className={Classes.accordionList} >
                        <Accordion className={Classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panela-content`}
                                id={`panela-header`}
                                className={Classes.accordionHeader}
                            >
                                <h5>Change color scheme</h5>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ textAlign: "left" }}
                                className={Classes.accordionBody}
                            >
                                <div className={Classes.textAlign} >
                                    <input
                                        type='color'
                                        value={colorScheme}
                                        onChange={(e) => setcolorScheme(e.target.value)}
                                    />
                                    <input
                                        type='text'
                                        value={colorScheme}
                                        onChange={(e) => { setcolorScheme(e.target.value); }}
                                        className={Classes.colorSchemeTextInput}
                                    />
                                    <button className={Classes.confirmBtn} style={{ background: companyInfo?.color }} onClick={() => debouncedUpdateCompany()}>
                                        <TickSvg height="14px" width="14px" />
                                        <h5>Confirm</h5>
                                    </button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion className={Classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panela-content`}
                                id={`panela-header`}
                                className={Classes.accordionHeader}
                            >
                                <h5>Change logo</h5>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ textAlign: "left" }}
                                className={Classes.accordionBody}
                            >
                                <div className={Classes.textAlign} >
                                    <div
                                        className={Classes.uploadCon}
                                        onClick={handleClick}
                                        style={{ border: `1px solid ${companyInfo?.color}` }}
                                    >
                                        <input
                                            ref={imageInputRef}
                                            hidden
                                            accept="image/*"
                                            type="file"
                                            onChange={handleInputChange}
                                            name="uploadBrochure"
                                            id="formFile" />
                                        <svg height="20px" fill={companyInfo?.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                            <path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z" />
                                        </svg>
                                    </div>

                                    {
                                        brochureLoading ? <CircularProgress /> : null
                                    }

                                    {imageURL && (
                                        <img
                                            style={{ height: "3rem", width: 'max-content', margin: "3px", borderRadius: "6px" }}
                                            src={imageURL}
                                            alt="Uploaded Image"
                                        />
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <>
                            {/* <Accordion className={Classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panela-content`}
                                id={`panela-header`}
                                className={Classes.accordionHeader}
                            >
                                <h5>Change announcement</h5>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ textAlign: "left" }}
                                className={Classes.accordionBody}
                            >
                                <div className={Classes.textAlign} >
                                    <div className={Classes.passwordCon} >
                                        <input
                                            onChange={(e) => { setannouncement(e.target.value) }}
                                            type="text"
                                            placeholder="Enter announcement"
                                            value={announcement}
                                            required
                                        />
                                    </div>
                                    <button className={Classes.confirmBtn} style={{ background: companyInfo?.color }} onClick={() => debouncedUpdateCompany()}>
                                        <TickSvg height="14px" width="14px" />
                                        <h5>Confirm</h5>
                                    </button>
                                </div>
                            </AccordionDetails>
                        </Accordion> */}
                        </>
                        <Accordion className={Classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panela-content`}
                                id={`panela-header`}
                                className={Classes.accordionHeader}
                            >
                                <h5>Change landing page link</h5>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ textAlign: "left" }}
                                className={Classes.accordionBody}
                            >
                                <div className={Classes.textAlign} >
                                    <div className={`${Classes.passwordCon}`}  >
                                        <input
                                            onChange={(e) => { setcompanyName(e.target.value) }}
                                            type="text"
                                            placeholder="Account Name"
                                            value={companyName}
                                            required
                                        />
                                        <div className={Classes.preDomain} style={{ background: convertToRgba(companyInfo?.color, 0.2) }} > <h5>.evala.ai</h5> </div>
                                    </div>
                                    <button className={Classes.confirmBtn} style={{ background: companyInfo?.color }} onClick={() => debouncedUpdateCompany()}>
                                        <TickSvg height="14px" width="14px" />
                                        <h5>Confirm</h5>
                                    </button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <h5 className={Classes.title} >Scoring Criteria</h5>
                    <div className={Classes.accordionList} >
                        <Accordion className={Classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panela-content`}
                                id={`panela-header`}
                                className={Classes.accordionHeader}
                            >
                                <h5>Change scoring criteria</h5>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ textAlign: "left" }}
                                className={Classes.accordionBody}
                            >
                                <div className={Classes.list} >
                                    {scoringCriteria?.map((criteria, index) => (
                                        <div className={Classes.listItem} key={index}>
                                            <label htmlFor={index}>
                                                <h5 className={Classes.accrdionText}>{criteria.name.split(/(?=[A-Z])/).join(' ')}</h5>
                                            </label>
                                            {/* <input
                                                type='checkbox'
                                                id={index}
                                                checked={criteria.status}
                                                onChange={() => handleCheckboxChange(index)}
                                            /> */}
                                            <Checkbox
                                                {...label}
                                                checked={criteria.status}
                                                id={index}
                                                onChange={() => handleCheckboxChange(index)}
                                                sx={{
                                                    color: companyInfo.color,
                                                    '&.Mui-checked': {
                                                        color: companyInfo.color,
                                                    },
                                                    '& .MuiSvgIcon-root': { fontSize: 17 }
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <h5 className={`${Classes.title} ${Classes.mb10}`} >Submission Requirements</h5>
                    {/* Company Criteria */}
                    <div className={`${Classes.flexBetween}`}>
                        <div className={Classes.flexAlign} >
                            <div className={Classes.accordionHeader} >
                                <h5>Set company criteria</h5>
                            </div>
                            <Tooltip
                                title="Only allow companies that fit your criteria to register and submit their pitch decks."
                                sx={{ textAlign: 'center' }} placement="top"
                            >
                                <span className='flex' >
                                    <InfoSvg />
                                </span>
                            </Tooltip>
                        </div>
                        <div className={Classes.flexAlign} >
                            {companyCriteria &&
                                <button onClick={() => { setopenCompanyCriteria(true) }} className={Classes.confirmBtn} style={{ height: '30px', background: companyInfo.color ? companyInfo.color : "black" }} >
                                    <h5>Edit</h5>
                                </button>
                            }
                            <FormGroup>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }}
                                        checked={companyCriteria}
                                        onChange={handleToggleCompanyCriteria}
                                    />}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <hr className={Classes.line} />
                    {/* Close submissions */}
                    <div>
                        <div className={Classes.flexBetween} >
                            <div className={Classes.flexAlign} >
                                <div className={Classes.accordionHeader} >
                                    <h5>Close submissions</h5>
                                </div>
                            </div>
                            <div>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }}
                                            checked={closeSubmission}
                                            onChange={handleToggleCloseSubmission}
                                        />}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <AnimatePresence>
                            {closeSubmission &&
                                <motion.div
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'fit-content', overflow: 'hidden' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{
                                        duration: 0.15,
                                    }}
                                    className={Classes.dropDownBody}
                                >
                                    <div className={Classes.flexAlign} >
                                        <DateTimePickup DateValue={DateValue} setDateValue={setDateValue} />
                                        <button className={Classes.confirmBtn} style={{ background: companyInfo?.color }}
                                            onClick={handleConfirmCloseSubmission}
                                        >
                                            <TickSvg height="14px" width="14px" />
                                            <h5> {closeSbmissionLoading ? 'Confirming...' : 'Confirm'} </h5>
                                        </button>
                                        {closeSbmissionSuccess &&
                                            <h5 style={{ color: 'green' }} >Successful</h5>
                                        }
                                    </div>
                                </motion.div>
                            }
                        </AnimatePresence>
                    </div>
                    <hr className={Classes.line} />
                    {/* Video link submission */}
                    <div className={`${Classes.flexBetween}`}>
                        <div className={Classes.flexAlign} >
                            <div className={Classes.accordionHeader} >
                                <h5>Enable pitch deck video</h5>
                            </div>
                            <Tooltip
                                title="Require applicants to link their pitch deck video when submitting their final deck. 
                                    You can view this video on your screening dashboard."
                                sx={{ textAlign: 'center' }} placement="top"
                            >
                                <span className='flex' >
                                    <InfoSvg />
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <FormGroup>
                                <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }}
                                        checked={videoSubmission}
                                        onChange={() => setVideoSubmission(!videoSubmission)}
                                    />}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <hr className={Classes.line} />
                    {/* Enable registration message */}
                    <div className={`${Classes.mb25r}`}  >
                        <div className={`${Classes.flexBetween} ${Classes.mb20}`} >
                            <div className={Classes.flexAlign} >
                                <div className={Classes.accordionHeader} >
                                    <h5>Enable registration message</h5>
                                </div>
                                <Tooltip
                                    title="Require applicants to review more information about your program, competition, or investment firm prior to signing up."
                                    sx={{ textAlign: 'center' }} placement="top"
                                >
                                    <span className='flex' >
                                        <InfoSvg />
                                    </span>
                                </Tooltip>
                            </div>
                            <div className={Classes.flexAlign} >
                                {enableRegistration &&
                                    <button onClick={() => { setopenMsgEditor(true) }} className={Classes.confirmBtn} style={{ height: '30px', background: companyInfo.color ? companyInfo.color : "black" }} >
                                        <h5>Edit</h5>
                                    </button>
                                }
                                <FormGroup>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }}
                                            checked={enableRegistration}
                                            onChange={handleToggleRegistration}
                                        />}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <h5 className={`${Classes.title} ${Classes.mb10}`} id='connectEmail' >Connect email</h5>
                    <div className={Classes.accordionList} >
                        <Accordion className={Classes.accordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panela-content`}
                                id={`panela-header`}
                                className={Classes.accordionHeader}
                            >
                                <h5>Connect your email</h5>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ textAlign: "left" }}
                                className={Classes.accordionBody}
                            >
                                <h5 className={`${Classes.accrdionText} ${Classes.mb10}`} >Connect email address</h5>
                                <h5 className={`${Classes.accrdionText} ${Classes.mb10}`} >
                                    To notify companies of your decision on their pitch decks automatically, please connect your email account. <br />
                                    Follow the instructions below to generate an app password from your email provider and enter it here.
                                </h5>
                                <h5 className={`${Classes.accrdionMiniTitle} ${Classes.mb10}`} >Select Your Email Provider</h5>
                                <h5 className={`${Classes.accrdionText} ${Classes.mb10}`} >
                                    Choose your email provider from the list below to see detailed instructions for generating an app password:
                                </h5>
                                <div className={`${Classes.ConnectList} ${Classes.mb20}`} >
                                    <ul>
                                        <li>
                                            <h5 style={{ color: companyInfo.color }} onClick={() => { triggerInstr('gmail') }} > Gmail </h5>
                                        </li>
                                        <li> <h5 style={{ color: companyInfo.color }} > Outlook </h5> <span >*COMING SOON*</span> </li>
                                        <li> <h5 style={{ color: companyInfo.color }} > Yahoo </h5> <span>*COMING SOON*</span> </li>
                                        <li> <h5 style={{ color: companyInfo.color }} > Other (Contact Support for assistance) </h5> <span>*COMING SOON*</span> </li>
                                    </ul>
                                </div>
                                <hr className={Classes.line} />
                                {activeInstr === 'gmail' && <GmailInstructions />}
                                {activeInstr === 'hotmail' && <OutlookInstructions />}
                                {activeInstr === 'yahoo' && <YahooInstructions />}
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    {user?.companySubAdmin === false ?
                        <div style={{ display: 'none' }} >
                            <h5 className={Classes.title} style={{ marginBottom: '10px' }} >Invite Admins</h5>
                            <div className={Classes.accordionList} >

                                {
                                    allIvites?.length !== companyInfo?.allowed_admins ?
                                        <div className={Classes.textAlign} >
                                            <div className={`${Classes.passwordCon}`}  >
                                                <input
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type="text"
                                                    placeholder="Enter email address"
                                                    required
                                                />
                                            </div>
                                            <button className={Classes.confirmBtn} style={{ background: companyInfo?.color }} onClick={() => createInvite()} >
                                                <TickSvg height="14px" width="14px" />
                                                <h5>Confirm</h5>
                                            </button>
                                        </div> : <p style={{ color: "red" }}>Only 5 Invites are allowed</p>}

                                {/* {
                                    allIvites?.length > 0 ? (
                                        allIvites.map((invite, index) => (
                                            <Grid item xs={12} sm={12} md={12} style={{ height: "70px", borderRadius: "10px", paddingLeft: "1rem", maxWidth: "400px" }} key={index}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    cursor: 'pointer',
                                                }}>
                                                    <a href="#" target="_blank" rel="noopener noreferrer" style={{ flexGrow: 1, textDecoration: "none", color: "navy" }}>
                                                        <p style={{ fontWeight: "600" }}>
                                                            {invite?.inviteeEmail ? (
                                                                <>
                                                                    <span>{invite.inviteeEmail}</span>
                                                                    <span>  </span>
                                                                    <span style={{ color: invite.isAccepted ? "green" : "red" }}>
                                                                        {invite.isAccepted ? "Accepted" : "Not-accepted"}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <h5 style={{ margin: '10px 0 0 7px', fontSize: '13px' }} >Invitee email not available</h5>
                                                            )}
                                                        </p>

                                                    </a>
                                                    <DeleteIcon className="delete-icon" onClick={() => DeleteInvite(invite._id, invite)} />
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <h5 style={{ margin: '10px 0 0 7px', fontSize: '13px' }} >No invites found</h5>
                                    )
                                } */}
                                <InviteTable allIvites={allIvites} />
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </>
    )
}
