import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Classes from './Pipeline.module.css'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
// import './styles.css';
import { FreeMode, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import SeeMorePopup from '../../Global-Components/SeeMorePopup/SeeMorePopup';
import PipelineCard from './Components/PipelineCard/PipelineCard';
import { CircularProgress } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';

const SmallRowSkeleton = (props) => (
    <div style={{ width: props.width, display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="15" ry="15" width="100%" {...props} />
        </ContentLoader>
    </div>
);

export default function Slider({ approvedDecks, rejectedDecks, reviewDecks, requestInfoDecks, deferredDecks, loading, setLoading }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const [decisionLoading, setdecisionLoading] = useState(false);
    const [openSeeMorePopup, setopenSeeMorePopup] = useState(false);


    const handleCloseSeeMore = () => {
        setopenSeeMorePopup(false);
    }


    const [popupData, setPopupData] = useState({});



    return (
        <>
            <AnimatePresence>
                {openSeeMorePopup &&
                    <SeeMorePopup closePopup={handleCloseSeeMore} deck={popupData}
                        setdecisionLoading={setdecisionLoading}
                    />
                }
            </AnimatePresence>

            {loading ?
                <Swiper
                    slidesPerView={3}
                    spaceBetween={15}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide className={Classes.swiperElem}>
                        <SmallRowSkeleton height={'89vh'} width={'22rem'} />
                    </SwiperSlide>
                    <SwiperSlide className={Classes.swiperElem}>
                        <SmallRowSkeleton height={'89vh'} width={'22rem'} />
                    </SwiperSlide>
                    <SwiperSlide className={Classes.swiperElem}>
                        <SmallRowSkeleton height={'89vh'} width={'22rem'} />
                    </SwiperSlide>
                    <SwiperSlide className={Classes.swiperElem}>
                        <SmallRowSkeleton height={'89vh'} width={'22rem'} />
                    </SwiperSlide>
                    <SwiperSlide className={Classes.swiperElem}>
                        <SmallRowSkeleton height={'89vh'} width={'22rem'} />
                    </SwiperSlide>
                    <SwiperSlide className={Classes.swiperElem}>
                        <SmallRowSkeleton height={'89vh'} width={'22rem'} />
                    </SwiperSlide>
                </Swiper>
                :
                <Swiper
                    slidesPerView={3}
                    spaceBetween={15}
                    freeMode={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide className={Classes.swiperElem} >
                        <div className={Classes.card} >
                            <h5>Reviewing Material</h5>
                            <div className={Classes.cardInner} >

                                {reviewDecks && reviewDecks?.decks?.length > 0 ? (
                                    reviewDecks.decks.map((deckRef) => (
                                        <PipelineCard setLoading={setLoading} key={deckRef._id} data={deckRef} setdecisionLoading={setdecisionLoading}
                                            setPopupData={setPopupData}
                                            setopenSeeMorePopup={setopenSeeMorePopup}
                                            pipelineName="review"
                                        />
                                    ))

                                ) : (
                                    <h5>No companies in this stage.</h5>
                                )}

                                {decisionLoading &&
                                    <div className={Classes.loadingPopup} > <h5>Loading...</h5> </div>
                                }
                            </div>
                        </div>
                    </SwiperSlide>


                    <SwiperSlide className={Classes.swiperElem} >
                        <div className={Classes.card} >
                            <h5>Requested Information</h5>
                            <div className={Classes.cardInner} >

                                {requestInfoDecks && requestInfoDecks?.decks?.length > 0 ? (
                                    requestInfoDecks.decks.map((deckRef) => (
                                        <PipelineCard setLoading={setLoading} key={deckRef._id} data={deckRef} setdecisionLoading={setdecisionLoading}
                                            setPopupData={setPopupData}
                                            setopenSeeMorePopup={setopenSeeMorePopup}
                                            pipelineName="requestInfo"
                                        />
                                    ))

                                ) : (
                                    <h5>No companies in this stage.</h5>
                                )}

                                {decisionLoading &&
                                    <div className={Classes.loadingPopup} > <h5>Loading...</h5> </div>
                                }
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className={Classes.swiperElem}>
                        <div className={Classes.card}>
                            <h5>Approved</h5>
                            <div className={Classes.cardInner}>
                                {approvedDecks && approvedDecks?.decks?.length > 0 ? (
                                    approvedDecks.decks.map((deckRef) => (
                                        <PipelineCard setLoading={setLoading} key={deckRef._id} data={deckRef}
                                            setPopupData={setPopupData}
                                            setopenSeeMorePopup={setopenSeeMorePopup}
                                            pipelineName="approved"
                                        />
                                    ))
                                ) : (
                                    <h5>No companies in this stage.</h5>
                                )}
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className={Classes.swiperElem} >
                        <div className={Classes.card} >
                            <h5>Rejected</h5>
                            <div className={Classes.cardInner} >
                                {rejectedDecks && rejectedDecks?.decks?.length > 0 ? (
                                    rejectedDecks.decks.map((deckRef) => (
                                        <PipelineCard setLoading={setLoading} key={deckRef._id} data={deckRef}
                                            setPopupData={setPopupData}
                                            setopenSeeMorePopup={setopenSeeMorePopup}
                                            pipelineName="rejected"
                                        />
                                    ))
                                ) : (
                                    <h5>No companies in this stage.</h5>
                                )}
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className={Classes.swiperElem} >
                        <div className={Classes.card} >
                            <h5>Deferred</h5>
                            <div className={Classes.cardInner} >
                                {deferredDecks && deferredDecks?.decks?.length > 0 ? (
                                    deferredDecks.decks.map((deckRef) => (
                                        <PipelineCard setLoading={setLoading} key={deckRef._id} data={deckRef}
                                            setPopupData={setPopupData}
                                            setopenSeeMorePopup={setopenSeeMorePopup}
                                            pipelineName="deferred"
                                        />
                                    ))
                                ) : (
                                    <h5>No companies in this stage.</h5>
                                )}
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper >
            }
        </>
    );
}
