// FilterDropDown.js
import React, { useState, useRef, useEffect } from 'react';
import Classes from '../ScreeningDashboard.module.css';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import DropDownElem from './DropDowns/DropDownElem';

export default function FilterDropDown({ onFilterChange }) {
  const [isfilterDropDown, setisfilterDropDown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setisfilterDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [filters, setFilter] = useState([
    {
      title: 'Decision',
      options: ['Approved', 'Rejected', 'Undecided'],
    },
    {
      title: 'Industry',
      options: [
        'SaaS',
        'MedTech',
        'EdTech',
        'FinTech',
        'FoodTech',
        'E-Commerce',
        'HealthTech',
        'AgreTech',
        'CleanTech',
        'InsurTech',
        'TravelTech',
        'Gaming',
        'Entertainment',
        'AI/ML',
        'Cybersecurity',
        'Blockchain',
        'IoT',
        'RetailTech',
        'SocialTech',
        'LegalTech',
        'AR',
        'Wearable Technology',
      ],
    },
  ]);

  const [selectedFilters, setSelectedFilters] = useState({});
  const [filterSearchTerm, setFilterSearchTerm] = useState('');

  const handleOptionChange = (filterTitle, optionValue, isSelected) => {
    setSelectedFilters((prev) => {
      const currentSelections = prev[filterTitle] || [];
      let newSelections;
      if (isSelected) {
        newSelections = [...currentSelections, optionValue];
      } else {
        newSelections = currentSelections.filter((val) => val !== optionValue);
      }
      return {
        ...prev,
        [filterTitle]: newSelections,
      };
    });
  };

  const handleSearchChange = (e) => {
    setFilterSearchTerm(e.target.value);
  };
  
  useEffect(() => {
    onFilterChange && onFilterChange(selectedFilters);
  }, [selectedFilters]);

  return (
    <div className={Classes.dropDown} ref={dropdownRef}>
      <div
        className={Classes.filterCon}
        onClick={() => {
          setisfilterDropDown(!isfilterDropDown);
        }}
      >
        <div className={Classes.flexAlign}>
          <img src='/filter.png' alt='filter' className={Classes.filterIcon} />
          <h5>Filter</h5>
        </div>
        <img
          src='/onBoarding/arrow-down.svg'
          alt='toggle'
          style={{
            height: '12px',
            transform: isfilterDropDown ? 'rotate(180deg)' : '',
          }}
        />
      </div>
      <AnimatePresence>
        {isfilterDropDown && (
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -5 }}
            transition={{
              duration: 0.15,
            }}
            className={Classes.filterdropDownBody}
          >
            <div className={Classes.ddPointerCon}>
              <div className={Classes.dropDownPointer}></div>
            </div>
            <div className={Classes.filterdropDownBodyInner}>
              <h5 className={Classes.filterTitle}>Filters</h5>
              <input
                type='text'
                placeholder='Search filters'
                className={Classes.searchCon}
                value={filterSearchTerm}
                onChange={handleSearchChange}
              />
              {filters?.map((filter, index) => {
                return (
                  <DropDownElem
                    key={index}
                    filter={filter}
                    index={index}
                    selectedOptions={selectedFilters[filter.title] || []}
                    onOptionChange={handleOptionChange}
                    searchTerm={filterSearchTerm}
                  />
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
