import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import MsgEditorPopup from '../../MsgEditorPopup/MsgEditorPopup';
import Classes from '../styles/style.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import backend from '../../../api';
import { setCompanyInfo } from '../../../redux-store/auth/actions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export default function RegMsg({ handleNext, handleBack, activeMsg, direction }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [enableRegistration, setEnableRegistration] = useState(onBoarding?.regMsg?.isActive ?? false);
    const [openMsgEditor, setopenMsgEditor] = useState(false);

    const dispatch = useDispatch();
    const onboardingId = onBoarding?._id;
    const companyId = onBoarding?._id;

    const handleCloseMsgEditor = () => {
        setopenMsgEditor(false);
    }

    const handleToggleRegistration = async () => {
        const newStatus = !enableRegistration;  // Toggle the status
        setEnableRegistration(newStatus);
        if (newStatus) {
            setopenMsgEditor(true);
        }

        // Make the API request to update isActive status
        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                regMsg: {
                    isActive: newStatus,
                },
            });
            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))

            }

        } catch (error) {
            console.error("Error updating registration message status:", error);
        }
    };

    return (
        <>
            <AnimatePresence>
                {openMsgEditor &&
                    <MsgEditorPopup enableRegistration={enableRegistration}
                        closePopup={handleCloseMsgEditor}
                    />
                }
            </AnimatePresence>

            <motion.div
                initial={{ x: direction === "forward" ? activeMsg === 1 ? 0 : 20 : -20, opacity: 0 }} // Forward moves left-to-right, backward moves right-to-left
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: direction === "forward" ? -20 : 20, opacity: 0 }}
                transition={{ duration: 0.25 }}
                className={Classes.cardCon}
            >
                <h5 className={`${Classes.text2} ${Classes.m1r}`}>
                    Require applicants to review more information <br /> about your program prior to signing up.
                </h5>
                <div className={Classes.miniCon}>
                    <h5 className={Classes.lightText}>Enable registration message</h5>
                    <FormGroup>
                        <FormControlLabel
                            control={<IOSSwitch
                                checked={enableRegistration}
                                onChange={handleToggleRegistration}
                            />}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.flexAlign}>
                    <button type="button" onClick={handleBack} className={Classes.btn1} style={{ border: `1px solid ${onBoarding.color}` }}>
                        <h5 style={{ color: onBoarding.color || "red" }}> Back </h5>
                    </button>
                    <button type="button" onClick={handleNext} className={Classes.btn2} style={{ background: onBoarding.color || "black", border: `1px solid ${onBoarding.color}` }}>
                        <h5> Next </h5>
                    </button>
                </div>
            </motion.div>
        </>
    )
}
