import React, { useEffect, useState } from 'react';
import Classes from './PitchDashboard.module.css';
import { pdfjs, Document } from 'react-pdf';
import { useSelector } from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ConfirmPopup from '../../Global-Components/ConfirmPopup/ConfirmPopup';
import { AnimatePresence } from 'framer-motion';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CardItem({ pdfUrl, id, index, DeleteFile, moveToAnalyzer, submittedStatus, analyzingstatus }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [numPages, setNumPages] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        renderFirstPageImage();
    }, []);

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const openDeleteConfirmPopup = () => {
        setShowConfirmPopup(true);
    };

    const closeDeleteConfirmPopup = () => {
        setShowConfirmPopup(false);
    };

    const handleDelete = () => {
        DeleteFile(id);
        closeDeleteConfirmPopup();
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const renderFirstPageImage = async () => {
        const page = await getPageAsImage(pdfUrl, 1); // Fetch the first page as an image
        setImageUrl(URL.createObjectURL(page));
    };

    const getPageAsImage = async (url, pageNumber) => {
        const pdf = await pdfjs.getDocument(url).promise;
        const page = await pdf.getPage(pageNumber);

        const viewport = page.getViewport({ scale: 1.5 }); // Adjust scale as needed
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        await page.render(renderContext).promise;
        const imageData = canvas.toDataURL('image/png');
        return fetch(imageData).then(res => res.blob());
    };

    return (
        <>
            <AnimatePresence>
                {showConfirmPopup &&
                    <ConfirmPopup
                        title="Delete Deck?"
                        description="Are you sure you want to delete this pitch deck? This action can’t be undone."
                        onConfirm={handleDelete}
                        onCancel={closeDeleteConfirmPopup}
                        id={id}
                    />
                }
            </AnimatePresence>
            <li className={Classes.cards_item}>
                <div className={Classes.card}>
                    <div className={Classes.card_image}>
                        {numPages && imageUrl ?
                            <img 
  src={imageUrl} 
  alt="First page of PDF" 
  onClick={() => {
    if (analyzingstatus === "completed") {
      moveToAnalyzer(pdfUrl, id);
    } else {
        alert("Deck is ",analyzingstatus)
    }
  }} 
/>
                            :
                            <img src="/sample-image.png" alt="PDF Loading" />
                        }
                        {numPages && !imageUrl && <div className={Classes.loadingCon}><h5>Loading...</h5></div>}
                        <Document
                            className={Classes.reactPdf}
                            file={pdfUrl}
                            onLoadSuccess={onDocumentLoadSuccess}
                            options={{
                                cMapUrl: 'cmaps/',
                                cMapPacked: true,
                            }}
                        ></Document>
                    </div>
                    <div className={Classes.card_content}>
                        <span>Deck {index + 1}</span>
                        <span>{analyzingstatus}</span>
                        {
                            submittedStatus === "submitted" ?
                                <h5 style={{ color: onBoarding.color ? onBoarding.color : "red", fontSize: '12px', fontWeight: '700' }} > submitted </h5> :
                                <a onClick={() => { setShowConfirmPopup(true) }}>
                                    <img src="/icons/delete.png" alt="DeleteIcon" />
                                </a>
                        }
                    </div>
                </div>
            </li>
        </>
    )
}

export default CardItem;
