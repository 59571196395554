import React from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import sessionstorage from "sessionstorage";
import { useNavigate } from "react-router-dom";
// import './Auth.css';
import { useLocation } from 'react-router-dom';
import { motion, easeOut } from 'framer-motion';
import Classes from './SubmittedDeck.module.css';
import { authActions } from "../../../../redux-store";

import PitchDeckSuccessPopup from "../../../../Global-Components/PitchDeckSuccessPopup/PitchDeckSuccessPopup";

function SubmittedDeck() {
  const onBoarding = useSelector((state) => state.companyInfo.info);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  
  const moveToMain = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    // alert("Signed out successfully")
  };


  const SvgComponent = (props) => (
    <svg
      height="40"
      width="40"
      {...props}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_1828640"
      style={{ marginBottom: '0.5rem' }}
    >
      <path
        d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
      />
      <path
        d="m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0"
        fill={onBoarding?.color}
      />
    </svg>
  )

  return (
    <>
      <PitchDeckSuccessPopup
        title="WOOHOO!"
        description="Your application has been submitted succesfully!"
        buttonText="Return"
        closePopup={moveToMain}
      />
      <div className={Classes.backgroundImage}>
        <motion.div
          initial={{ opacity: 0, y: -5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: easeOut,
          }}
        >
          <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
            <div>
              <a className={Classes.logoLink} href="/">
                <img className={Classes.logoImage} src={onBoarding.logo} alt="Logo" />
              </a>
            </div>
            <nav className={Classes.navMenu}>

            </nav>
            <div>
              <a href={onBoarding.companyWebsite}>
                <h5 className={Classes.goBackTxt} style={{ color: `${onBoarding.color}` }} >Go back to {onBoarding.companyName} </h5>
              </a>
            </div>
          </div>

        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.5,
            ease: easeOut,
            delay: 1,
          }}
        >
          <form className={`${Classes.section3}`}>
            <div className={Classes.s4InnerMain} >
              <h5 className={Classes.title} > Pitch Deck </h5>
              <div
                className={Classes.uploadMain}
              >
                <div
                >
                  <div className={Classes.cardCon}
                    style={{ border: '2px dashed green', borderColor: onBoarding.color ? onBoarding.color : "red" }}
                  >
                    <svg className={Classes.imgCloud} style={{ fill: onBoarding.color }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" /></svg>
                    <h5 className={Classes.title} >Drag and drop PDF pitch deck file here</h5>
                    <h5 className={Classes.text1} >or</h5>
                    <button
                      type='button'
                      style={{ background: onBoarding.color ? onBoarding.color : "black" }}
                    >
                      <img className={Classes.chooseFile} src='/icons/chooseFile.png' alt="Choose File" />
                      <h5>Choose file</h5>
                    </button>
                    <input
                      accept="application/pdf"
                      name="uploadBrochure"
                      className="form-control"
                      type="file"
                      id="formFile"
                      style={{ display: 'none' }}
                    />
                    <h5 className={Classes.text2} >Accepts PDF files up to 10MB</h5>
                    <a href='https://www.ilovepdf.com/powerpoint_to_pdf' target='_blank' rel="noopener noreferrer">
                      <h5 className={Classes.text3}
                        style={{ color: onBoarding.color ? onBoarding.color : "red" }}
                      >Convert PPT to PDF here</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <h5 className={Classes.text5} >
                  Download our ultimate pitch deck template with <br />
                  over 125 premium designed slides.
                </h5>
                <div className='flex' >
                  <h5 className={Classes.text4} style={{ color: onBoarding?.color }} >Download here</h5>
                </div>
              </div>
              {onBoarding?.videoSubmission &&
                <div style={{ width: '26rem' }} >
                  <input
                    type="text"
                    placeholder="Pitch deck video link"
                    required
                    className={`${Classes.inputField} ${Classes.mt15}`}
                    style={{ width: '100%' }}
                  />
                </div>
              }
            </div>
            <footer style={{ background: onBoarding?.color ? onBoarding?.color : 'white' }} >
              <button type='button' >
                <h5 style={{ color: onBoarding?.color ? onBoarding?.color : 'black' }} >Submit {'>'}</h5>
              </button>
            </footer>
          </form>
        </motion.div>
      </div>
    </>
  )
}

export default SubmittedDeck;