import React, { useEffect, useState, useRef } from 'react'
import Classes from './Scoring.module.css'
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

export default function NoDataRow({ value, setdecisionLoading }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [dropDown, setdropDown] = useState(false);
    const handleClosedropdown = () => {
        setdropDown(false);
    }

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [decision, setdecision] = useState('approve');
    const handleDecisionChange = async (value, id) => {
        setdecisionLoading(true);
        try {
            console.log(value);

        } catch (error) {
            setdecisionLoading(false);
            console.error("An error occurred while updating the decision:", error);
            // Handle the error appropriately, e.g., show an error message to the user
        } finally {
            setdecisionLoading(false);
        }
    };
    return (
        <>
            <div className={Classes.row} >
                <div className={Classes.rowHeader} >
                    <h5>1</h5>
                    <h5>Stripe</h5>
                    <h5>John Smith</h5>
                    <h5>1</h5>
                    <h5 className={Classes.score}
                    >
                        100 {'>'}
                    </h5>
                    <div className={Classes.dropDown} >
                        {/* Dropdown Head */}
                        <div
                            className={Classes.dropDownHead}
                            onClick={() => setdropDown(!dropDown)}
                            ref={dropdownRef}
                        >
                            <p style={{ textTransform: 'capitalize' }} >{decision === 'approve' ? 'Approve' : decision === 'reject' ? 'Reject' : 'Select'}</p>
                            <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
                        </div>

                        {/* Dropdown Body */}
                        <AnimatePresence>
                            {dropDown && (
                                <motion.div
                                    initial={{ opacity: 0, y: -5 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -5 }}
                                    transition={{
                                        duration: 0.15,
                                    }}
                                    className={Classes.dropDownBody}
                                >
                                    <div className={Classes.ddPointerCon}>
                                        <div className={Classes.dropDownPointer}></div>
                                    </div>
                                    <div className={Classes.dropDownBodyInner}>
                                        {/* Dropdown Options */}
                                        <div
                                            className={`${Classes.dropDownElem} ${decision == 'approve' ? Classes.activeDropElem : ''}`}
                                            onClick={() => handleDecisionChange('approve', value?._id)}
                                            style={{ padding: '5px 10px' }} // Mimic MUI padding style
                                        >
                                            <h5>Approve</h5>
                                        </div>
                                        <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                                        <div
                                            className={`${Classes.dropDownElem} ${decision == 'reject' ? Classes.activeDropElem : ''}`}
                                            onClick={() => handleDecisionChange('reject', value?._id)}
                                            style={{ padding: '5px 10px' }}
                                        >
                                            <h5>Reject</h5>
                                        </div>
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        {/* Hidden input for accessibility */}
                        <input
                            type="hidden"
                            value={decision}
                            aria-label="Without label"
                            readOnly
                        />
                    </div>
                    <div className='flex' >
                        <h5 className={Classes.seemoreTxt} style={{ color: onBoarding?.color }} >See more</h5>
                    </div>
                </div>
            </div >
        </>
    )
}
