import React, { useState, useRef, useEffect } from 'react'
import Classes from '../ScreeningDashboard.module.css'
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import DropDownElem from './DropDowns/DropDownElem';

export default function ToolsDropDown({ setopenEmailEditorPopup, setopenAddCompanyPopup }) {
    const [dropDown, setdropDown] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [filters, setFilter] = useState([
        {
            title: 'Decision',
            options: ["Approved", "Rejected"]
        },
        {
            title: 'Rank',
            options: ["1-10", "11-25", "26-50", "51-100", "100+"]
        },
        {
            title: 'Score',
            options: ["0-50", "51-60", "61-70", "71-80", "81-90", "91-100"]
        },
        {
            title: 'Industry',
            options: [
                "SaaS", "MedTech", "EdTech", "FinTech", "FoodTech",
                "E-Commerce", "HealthTech", "AgreTech", "CleanTech",
                "InsurTech", "TravelTech", "Gaming", "Entertainment",
                "AI/ML", "Cybersecurity", "Blockchain", "IoT",
                "RetailTech", "SocialTech", "LegalTech", "AR",
                "Wearable Technology"
            ]
        }
    ]);

    const handleOpenEmailEditor = () =>{
        setopenEmailEditorPopup(true);
        setdropDown(false);
    }

    const handleOpenAddCompany = () =>{
        setopenAddCompanyPopup(true);
        setdropDown(false);
    }


    return (
        <div
            className={Classes.dropDown}
            ref={dropdownRef}
        >
            {/* Dropdown Head */}
            <div className={`${Classes.flexAlign10} pointer ${Classes.mr10}`}
                onClick={() => setdropDown(!dropDown)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height='15px' viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" /></svg>
                <h5 className='noSelect' >Tools</h5>
            </div>

            {/* Dropdown Body */}
            <AnimatePresence>
                {dropDown && (
                    <motion.div
                        initial={{ opacity: 0, y: -5 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -5 }}
                        transition={{
                            duration: 0.15,
                        }}
                        className={Classes.dropDownBody}
                        style={{ top: '30px', right: '0px' }}
                    >
                        <div className={Classes.ddPointerCon}>
                            <div className={Classes.dropDownPointer}></div>
                        </div>
                        <div className={Classes.dropDownBodyInner}>
                            <div
                                className={`${Classes.dropDownElem}`}
                                style={{ padding: '5px 10px' }}
                                onClick={handleOpenEmailEditor}
                            >
                                <h5>Mass email</h5>
                            </div>
                            <div className={Classes.dropDownLine}></div> {/* Optional line separator */}
                            <div
                                className={`${Classes.dropDownElem}`}
                                style={{ padding: '5px 10px' }}
                            >
                                <h5>Clear dashboard</h5>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            {/* Hidden input for accessibility */}
            <input
                type="hidden"
                aria-label="Without label"
                readOnly
            />
        </div>
    )
}
