import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { motion } from 'framer-motion';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SvgComponent = (props) => (
    <svg
        id="fi_5089885"
        height="55"
        viewBox="0 0 512 512"
        width="55"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g>
            <path d="M482 207.792c0-14.1-7.835-26.775-20.449-33.081l-13.817-6.909v79.989l13.819-6.912c12.612-6.304 20.447-18.979 20.447-33.087z" />
            <path d="M50.449 240.881l13.818 6.911v-79.989l-13.82 6.909c-27.081 13.089-27.083 53.08.002 66.169z" />
            <path d="M511.999 207.646c0-.012-.002-.024-.002-.036 0 .061.003.121.003.182 0-.048 0-.097-.001-.146z" />
            <path d="M474.967 267.714l-212.259 106.136c-2.111 1.056-4.409 1.583-6.708 1.583s-4.597-.528-6.708-1.583L37.028 267.714c-22.838-11.416-37.028-34.373-37.028-59.911V432.737c0 43.707 35.559 79.266 79.267 79.266h353.467c43.707 0 79.266-35.559 79.266-79.267V207.803c0 25.538-14.19 48.495-37.033 59.914z" />
            <path d="M242.675 182.23c-5.857 5.858-15.355 5.858-21.213 0l-32.485-32.485-24.229 24.225 67.321 67.321 115.521-115.52-24.229-24.225z" />
            <path d="M417.733 262.796V63.2c0-34.966-28.297-63.2-63.2-63.2H157.467c-34.962 0-63.2 28.29-63.2 63.2v199.596l161.733 80.867zm-196.271 10.316l-88.536-88.536c-5.853-5.852-5.859-15.355.001-21.214l45.445-45.437c5.858-5.856 15.355-5.857 21.212.001l32.484 32.484 80.684-80.684c5.857-5.857 15.354-5.858 21.212-.001l45.444 45.437c5.853 5.852 5.86 15.356.001 21.214L242.675 293.112c-5.852 5.852-15.354 5.859-21.213 0z" />
        </g>
    </svg>
)

function EmailSuccessPopup({ closePopup, title, description, buttonText }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const handleBgClick = () => {
        closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        closePopup();
    }

    usePreventScrolling();

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.close} onClick={closePopup} >
                        <svg height='20px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <SvgComponent fill={'white'} />
                        <div className={Classes.curveCon} >

                        </div>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className={Classes.cardCon} >
                            <h5 className={Classes.title} >{title}</h5>
                            <h5 className={`${Classes.text2} ${Classes.m1r}`} >
                                {description}
                            </h5>
                            <button type='submit' className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} > <h5>{buttonText}</h5> </button>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
        </>
    )
}

export default EmailSuccessPopup
