import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';
import { useNavigate } from 'react-router-dom';

const CrownSvg = (props) => (
    <svg {...props} id="fi_2476413" enable-background="new 0 0 32 32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m27.488 23-.513 2.225c-.105.454-.509.775-.975.775h-20c-.466 0-.87-.321-.975-.775l-.513-2.225z"></path><path d="m29.975 12.225-2.025 8.775h-23.9l-2.025-8.775c-.089-.387.059-.791.378-1.028.32-.237.749-.262 1.093-.065l6.189 3.537 5.482-8.223c.179-.268.475-.434.796-.446.324-.014.629.132.826.386l6.429 8.266 5.227-3.484c.341-.226.786-.224 1.123.009.338.233.498.649.407 1.048z"></path></svg>
)

function UpgradeFreePopup({ closePopup, title, description, buttonText, icon }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const navigateTo = useNavigate();
    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        navigateTo('/pricing');
    }

    usePreventScrolling();

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    {/* <div className={Classes.close} onClick={closePopup} >
                        <svg height='20px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div> */}
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <CrownSvg fill={'white'} height="50px" />
                        <div className={Classes.curveCon} >

                        </div>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className={Classes.cardCon} >
                            <h5 className={Classes.title} > Upgrade to unlock this feature! </h5>
                            <h5 className={`${Classes.text2} ${Classes.m1r}`} >
                                Invite admins or scorers to evaluate companies, <br /> allowing them to provide feedback and ratings
                            </h5>
                            <button type='submit' className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} > <h5>Upgrade</h5> </button>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
        </>
    )
}

export default UpgradeFreePopup
