import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { BorderColor } from '@mui/icons-material';
import Classes from './SectionC.module.css';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';

const SectionC = () => {

  const [width, setWidth] = useState(window.innerWidth);
  const sectionRef = useRef(null); // Reference to the section

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id='how-it-works' className={Classes.howItWorks} ref={sectionRef} >
      <div>
        <AnimateTopToBottom delay={0.3}>
          <Button variant="contained" className="howItWorksBtn"> HOW IT WORKS </Button>
        </AnimateTopToBottom>
        <AnimateTopToBottom delay={0.4}>
          <h1>Evaluate pitch decks based on your criteria</h1>
        </AnimateTopToBottom>
      </div>
      <AnimateTopToBottom delay={0.5} >
        <div className={Classes.cardsContainer}>
          <div className={Classes.lineContainer}>
            <img src="/line.png" alt="line" className={Classes.lineImage} />
          </div>
          {['01', '02', '03'].map((iconNumber, index) => (
            <div className={Classes.featureCard} key={index} style={{ animationDelay: `${index * 0.2}s` }}>
              <div className={Classes.cardBg} ></div>
              <img src={`/${iconNumber}.png`} alt='icon' className={Classes.iconImage} />
              <h2 className={Classes.cardTitle}>
                {iconNumber === '01' && 'Create your screening tool'}
                {iconNumber === '02' && 'Send out landing page link'}
                {iconNumber === '03' && 'Review key pitch deck metrics'}
              </h2>
              <p className={Classes.cardText}>
                {iconNumber === '01' && 'Customize your pitch deck screening tool in minutes without any onboarding calls, and select the AI model that best fits your needs.'}
                {iconNumber === '02' && 'Provide applicants with your unique landing page link where they can sign up and submit their pitch decks to the AI pitch deck analyzer.'}
                {iconNumber === '03' && 'Review key metrics of submitted pitch decks, including company description, comprehensive summary, and overall score.'}
              </p>
            </div>
          ))}
        </div>
      </AnimateTopToBottom>
    </section >
  );
};

export default SectionC;
