import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import Classes from './Auth.module.css';
import backend from "../../../api";
import { useNavigate } from "react-router-dom";
import { setUserData } from "../../../redux-store/auth/actions";
import IndustryDropDown from "./DropDowns/IndustryDropDown";
import CompanyStageDropDown from "./DropDowns/CompanyStageDropDown";
import CurrentRevenueDropDown from "./DropDowns/CurrentRevenueDropDown";
import InvestAmountDropDown from "./DropDowns/InvestAmountDropDown";
import PrevInvestDropDown from "./DropDowns/PrevInvestDropDown";
import CountryDropDown from "./DropDowns/CountryDropDown";
import { authActions } from "../../../redux-store";
import sessionstorage from "sessionstorage";
import CriteriaInvalidPopup from "../../Global-Components/CriteriaInvalidPopup/CriteriaInvalidPopup";

function OnboardingAuth() {
  const [companyName, setcompanyName] = useState('');
  const [founders, setfounders] = useState('');
  const [industry, setindustry] = useState('');
  const [companyDesc, setcompanyDesc] = useState('');
  const [website, setwebsite] = useState('');
  const [loading, setLoading] = useState(false);
  const [companyInfoPopup, setCompanyInfoPopup] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const [emptyFieldError, setemptyFieldError] = useState(false);
  const [decks, setDecks] = useState([]);

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const onBoarding = useSelector((state) => state.companyInfo.info);

  const [userCriteriaSelections, setUserCriteriaSelections] = useState({
    industry: [],           // Flatten and set to array or empty array
    companyStage: [],   // Flatten and set to array or empty array
    currentRevenue: [],   // Same for other fields
    investmentAmount: [],
    previousInvestment: [],
    country: []
  });

  const userId = user._id;


  useEffect(() => {
    const fetchDecks = async () => {
      try {
        // Make an API request to get decks by userId and status 'submitted'
        const response = await backend.get(`/deck/`, {
          params: {
            userID: userId, // Passing userId as a query parameter
          },
        });
        // Set the fetched decks into state
        setDecks(response.data);

        console.log(response.data)

        if(response?.data?.length>0) {
          navigateTo('/submitted')
        }
      } catch (err) {
        // Handle any error that occurs during the API call
        console.log("Error fetching decks: " + err.message);
      } finally {
        // Stop loading once the request is finished
        setLoading(false);
      }
    };

    if (userId) {
      fetchDecks();
    }
  }, [userId]);


  async function updateUser(e) {
    e.preventDefault();
    if (companyName === "" || founders === "" || companyDesc === "" || website === "") {
      setemptyFieldError(true);
      setTimeout(() => {
        setemptyFieldError(false);
      }, 30000);
      return
    }

    // Validate user criteria against admin criteria
    if (AdminCriteria?.isActive && !validateCriteria()) {
      setopenCriteriaInvalidPopup(true); // Show the popup if criteria do not match
      return;
    }


    const body = {
      companyName,
      founders,
      companyDescription: companyDesc,
      industry,
      website,
      customerOnboarded: true,
      CustomerData: userCriteriaSelections
    }

    try {
      setLoading(true);
      const resp = await backend.put(`/api/user/${userId}`, body);

      console.log(resp)
      if (resp?.status === 200 && resp?.data) {
        // Immediately re-fetch the updated user data from the backend

        // Update the Redux store with the latest user data
        await dispatch(setUserData(resp?.data?.data));

        // Navigate once the user data reflects the correct `customerOnboarded` status
        if (resp?.data?.data?.customerOnboarded) {
          localStorage.setItem('isFirstInteraction', 'true');
          navigateTo('/application');
        }

      }
      // Handle success if needed
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle error (e.g., show toast)

    } finally {
      setLoading(false);
    }
  }


  // useEffect(() => {
  //   if (user?.customerOnboarded) {
  //     navigateTo('/dashboard');
  //   }
  // }, [user?.customerOnboarded]);

  
  const [openCriteriaInvalidPopup, setopenCriteriaInvalidPopup] = useState(false);
  const handleClosePopup = async() => {
    setopenCriteriaInvalidPopup(false);

    await backend.delete(`/api/user/${userId}`)

    handleLogout()
    
  }


  
  const handleLogout = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
  };


  const AdminCriteria = onBoarding?.onboardingCriteria;





  const handleSelectionChange = (type, selections) => {
    setUserCriteriaSelections(prevState => ({
      ...prevState,
      [type]: selections
    }));
  };

  console.log(userCriteriaSelections)
  // console.log(AdminCriteria)

  const validateCriteria = () => {
    // Define the criteria fields we want to validate
    const criteriaFields = [
      'industry',
      'companyStage',
      'currentRevenue',
      'investmentAmount',
      'previousInvestment',
      'country'
    ];

    for (const criteria of criteriaFields) {
      const adminValues = AdminCriteria[criteria]; // Get admin values for the current criterion
      const userValue = userCriteriaSelections[criteria]; // Get user value for the current criterion

      // Skip empty admin criteria
      if (!adminValues || adminValues.length === 0) {
        continue; // Ignore this criterion if admin criteria are empty
      }

      // If user has no selected value, validation fails
      if (!userValue || userValue.length === 0) {
        console.log(`Validation failed: User does not provide a value for ${criteria}`);
        return false; // User does not meet the criteria
      }

      // Check if the user value matches any of the admin values
      const matches = adminValues.includes(userValue[0]); // Compare user value with admin values

      if (!matches) {
        console.log(`Validation failed for ${criteria}: User value '${userValue[0]}' does not match any admin values ${adminValues}`);
        return false; // User does not match any of the non-empty admin criteria
      }
    }

    console.log("Validation successful: User meets all non-empty admin criteria.");
    return true; // User meets all the non-empty admin criteria
  };

  return (
    <>
      <AnimatePresence>
        {openCriteriaInvalidPopup && (
          <CriteriaInvalidPopup
            closePopup={handleClosePopup}
            title="Sorry you don’t meet the criteria"
            description={`Thank you for your interest! Unfortunately, you don't meet the criteria required by ${onBoarding?.companyName} at this time.`}
          />
        )}
      </AnimatePresence>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <div id="loading-bar-spinner" className="spinner">
            <div
              className="spinner-icon"
              style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}
            ></div>
          </div>
        </div>
      ) : (
        <div className={Classes.backgroundImage}>
          <motion.div
            initial={{ opacity: 0, y: -5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              ease: easeOut,
            }}
          >
            <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
              <div>
                <a className={Classes.logoLink} href="/">
                  <img className={Classes.logoImage} src={onBoarding.logo} alt="Logo" />
                </a>
              </div>
              <nav className={Classes.navMenu}>

              </nav>
              <div>
                <a href={onBoarding.companyWebsite}>
                  <h5 className={Classes.goBackTxt} style={{ color: `${onBoarding.color}` }} >Go back to {onBoarding.companyName} </h5>
                </a>
              </div>
            </div>

          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.5,
              ease: easeOut,
              delay: 1,
            }}
          >
            <form onSubmit={updateUser} className={`${Classes.section3}`}>
              {companyInfoPopup && (
                <div className={Classes.section3Inner} >
                  <div className={Classes.boxStyles2}>
                    <h5 className={Classes.miniTitle} >Company Information</h5>
                    {/* <p className={Classes.subtitle}
                      style={{ fontWeight: '600', color: '#6E6E6E' }} >
                      You can update or change any of this information anytime in your user settings.
                    </p> */}
                    <br />
                    <input
                      onChange={(e) => setcompanyName(e.target.value)}
                      type="text"
                      placeholder="Company name"
                      required
                      className={`${`${Classes.inputField} ${Classes.mb15}`}`}
                      value={companyName}
                    />
                    <input
                      onChange={(e) => setfounders(e.target.value)}
                      type="text"
                      placeholder="Founders"
                      required
                      className={`${`${Classes.inputField} ${Classes.mb15}`}`}
                      value={founders}
                    />
                    {
                      !AdminCriteria?.isActive?
                       <IndustryDropDown
                       selected={userCriteriaSelections.industry}
                       onChange={(selections) => handleSelectionChange('industry', selections)}
                     />:null
                    }
                    {
                      AdminCriteria?.isActive ?
                        <div>
                          <IndustryDropDown
                            selected={userCriteriaSelections.industry}
                            onChange={(selections) => handleSelectionChange('industry', selections)}
                          />
                          {AdminCriteria?.companyStage.length>0?
                          <CompanyStageDropDown
                            selected={userCriteriaSelections.companyStage}
                            onChange={(selections) => handleSelectionChange('companyStage', selections)}
                          />:null}

                          {AdminCriteria?.currentRevenue.length?
                          <CurrentRevenueDropDown
                            selected={userCriteriaSelections.currentRevenue}
                            onChange={(selections) => handleSelectionChange('currentRevenue', selections)}
                          />:null}

                          {AdminCriteria?.investmentAmount.length > 0?
                          <InvestAmountDropDown
                            selected={userCriteriaSelections.investmentAmount}
                            onChange={(selections) => handleSelectionChange('investmentAmount', selections)}
                          />:null}

                          {AdminCriteria?.previousInvestment.length > 0?
                          <PrevInvestDropDown
                            selected={userCriteriaSelections.previousInvestment}
                            onChange={(selections) => handleSelectionChange('previousInvestment', selections)}
                          />:null}

                          {AdminCriteria?.country.length > 0?
                          <CountryDropDown
                            selected={userCriteriaSelections.country}
                            onChange={(selections) => handleSelectionChange('country', selections)}
                          />:null}
                          </div> : null 
                    }

                    <textarea
                      onChange={(e) => setcompanyDesc(e.target.value)}
                      placeholder="Company description (100 words or less)"
                      required
                      className={`${`${Classes.inputField} ${Classes.mb15}`}`}
                      value={companyDesc}
                      rows={5}
                      style={{
                        height: 'auto',
                        paddingTop: '10px'
                      }}
                    />
                    <input
                      onChange={(e) => setwebsite(e.target.value)}
                      type="text"
                      placeholder="Website"
                      required
                      className={`${`${Classes.inputField} ${Classes.mb15}`}`}
                      value={website}
                    />
                    {emptyFieldError && <h5 className={Classes.error} >*All fields are required</h5>}

                    {/* <button type="submit" style={{ background: onBoarding.color ? onBoarding.color : "black" }} className={`${Classes.button} ${Classes.signInButton}`}>
                      Continue
                    </button> */}
                  </div>
                </div>
              )}
              <footer style={{ background: onBoarding?.color ? onBoarding?.color : 'white' }} >
                <button type="submit" >
                  <h5 style={{ color: onBoarding?.color ? onBoarding?.color : 'black' }} >Continue {'>'}</h5>
                </button>
              </footer>
            </form>
          </motion.div>
        </div>
      )}
    </>
  );
}

export default OnboardingAuth;
