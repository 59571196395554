import React, { useState, useEffect, useRef } from 'react'
import Classes from './Dashboard.module.css'
import Color from 'color';
import RowComp from './RowComp';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate, useNavigate } from 'react-router-dom';
import backend from '../../../api';
import { useSelector } from 'react-redux';
import { setCompanyInfo } from '../../../redux-store/auth/actions';
import { useDispatch } from 'react-redux';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import sessionstorage from "sessionstorage";
import { CircularProgress } from '@mui/material';
import EmailSuccessPopup from '../../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import EmailEditorPopup from '../../../Global-Components/EmailEditorPopup/EmailEditorPopup';
import MobileVersionPopup from '../../../Global-Components/MobileVersionPopup/MobileVersionPopup';
import OnBoardingSuccessPopup from '../../../Global-Components/OnBoardingSuccessPopup/OnBoardingSuccessPopup';
import OnBoardingUserSuccessPopup from '../../Global-Components/OnBoardingSuccessPopup/OnBoardingUserSuccessPopup';
import { setUserData } from '../../../redux-store/auth/actions';

export default function Dashboard() {
    const navigateTo = useNavigate();
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);
    const [deckData, setDeckData] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [last30DaysDeck, setLast30DaysDeck] = useState(0);
    const [avgDeckScore, setAverageDeckScore] = useState(0);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState('All');
    const [selectedButton, setSelectedButton] = useState('');
    const [Industry, setIndustry] = useState('SaaS');

    const deckUpdate = useSelector((state) => state.deck.data);
    const [searchTerm, setSearchTerm] = useState("");

    const [filteredDecks, setFilteredDecks] = useState([]); // Data to display



    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
      
        const filtered = deckData?.filter((deck) => {
          // Safely check if companyName exists and convert it to lowercase
          const companyName = deck?.userId?.companyName?.toLowerCase() || "";
      
          // Filter based on companyName that starts with the search term
          return companyName.startsWith(value);
        });
      
        console.log(filtered);
      
        setFilteredDecks(filtered);
      };


    const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);

    const userID = user?._id
    const dispatch = useDispatch();

    const [openEmailConnectedPopup, setopenEmailConnectedPopup] = useState(false);

    useEffect(() => {
        if (selectedButton === 'Setting') {
            sessionstorage.setItem("navBtn", "Setting")
            navigateTo('/setting');
        }
    }, [selectedButton])
    const [decisionLoading, setdecisionLoading] = useState(false);

    const handleCloseEmailConnectedPopup = () => {
        setopenEmailConnectedPopup(false);
    }

    const [openNotifications, setopenNotifications] = useState(false);
    const NotificationsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setopenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleNotifications = (event) => {
        event.stopPropagation(); // Prevents event propagation to document
        setopenNotifications(prevState => !prevState);
    };
    const handleSettingClick = () => {
        setSelectedButton('Setting');
    }

    const convertToRgba = (colorInput, opacity) => {
        try {
            const color = Color(colorInput);
            return color.alpha(opacity).rgb().string();
        } catch (error) {
            console.error("Invalid color format:", colorInput);
            return `rgba(0, 0, 0, ${opacity})`;
        }
    };

    const [copied, setCopied] = useState(false);
    const handleCopyText = () => {
        const textToCopy = `${onBoarding.companyName}.evala.ai`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
        });
    };

  

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calculate total pages
    const totalPages = Math.ceil(filteredDecks?.length / itemsPerPage);

    // Get current items
    const currentItems = filteredDecks?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // Calculate the range of items currently being shown
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, filteredDecks?.length);
    const totalItems = filteredDecks?.length;

    const companyId = onBoarding?._id;

    const handleFilterChange = (newFilter) => {
        // If the new filter is the same as the current filter, set it to "All"
        if (filter === newFilter) {
            setFilter('All');
        } else {
            // Otherwise, update to the new filter
            setFilter(newFilter);
        }
    };

    const [onBoardingSuccessPopup, setonBoardingSuccessPopup] = useState(false);
    const handleCloseOnboardingSuccessPopup = () => {
        setonBoardingSuccessPopup(false);
        
        const handleFalseInteract = async () => {
            const resp = await backend.put(`/api/user/${userID}`, {
                isFirstInteraction: false
            });
            if(resp?.status == 200 && resp?.data) {
               await dispatch(setUserData(resp?.data?.data))
            }
        }
        handleFalseInteract();
    }

    useEffect(() => {
        const handleOnboarding = async () => {
          if (user?.isFirstInteraction) {
            setTimeout(() => {
              setonBoardingSuccessPopup(true);
            }, 1000);
          } else {
            setonBoardingSuccessPopup(false);
          }
        };
    
        handleOnboarding();
      }, [user, user?.isFirstInteraction]);
      
    const [isfilterDropDown, setisfilterDropDown] = useState(false);
    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setisfilterDropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true)
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

                // Filter the response data where adminDelete is false
                const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");
                const industries = filteredData?.map(item => item?.userId?.industry) || ['SaaS'];
                console.log(industries)

                const chosenName = getMostFrequentOrRandomCompanyName(industries);
                setIndustry(chosenName)



                // Calculate the date 30 days ago
                const thirtyDaysAgo = new Date();
                thirtyDaysAgo.setDate(thirtyDaysAgo?.getDate() - 30);

                // Filter the data for the last 30 days
                const last30DaysData = filteredData?.filter(item => new Date(item?.createdAt) >= thirtyDaysAgo);

                // Log the filtered data
                console.log(filteredData);
                setDeckData(filteredData);
                setFilteredDecks(filteredData)

                // Log or set the length of the last 30 days data
                console.log(last30DaysData?.length);
                setLast30DaysDeck(last30DaysData?.length);

                // Calculate total score for each deck
                const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
                const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

                const calculateTotalScore = (deck) => {
                    const scores = {
                        deckStructure: parseFloat(deck?.deckStructureScoreMatch) || 0,
                        marketOpportunity: parseFloat(deck?.marketPotentialScoreMatch) || 0,
                        marketingSales: parseFloat(deck?.marketingSalesScoreMatch) || 0,
                        businessModel: parseFloat(deck?.businessModelScoreMatch) || 0,
                        competitiveLandscape: parseFloat(deck?.competitiveLandscapeScoreMatch) || 0,
                        team: parseFloat(deck?.teamScoreMatch) || 0,
                        traction: parseFloat(deck?.tractionScoreMatch) || 0
                    };

                    let totalScore = activeScoringCriteria.reduce((acc, criteria) => {
                        return acc + (scores[criteria.name] * weight);
                    }, 0);

                    return +(totalScore?.toFixed(2)); // Round to two decimal places
                };

                // Add totalScore to each deck and sort by totalScore in descending order
                const decksWithScores = filteredData?.map(deck => ({
                    ...deck,
                    totalScore: calculateTotalScore(deck)
                })).sort((a, b) => b.totalScore - a.totalScore);



                const getFilteredDecks = () => {
                    const topCounts = {
                        'Top 10': 10,
                        'Top 25': 25,
                        'Top 100': 100
                    };

                    if (filter === 'All') {
                        return decksWithScores;
                    }

                    const count = topCounts[filter]; // Default to Top 10 if no filter is selected


                    return decksWithScores.slice(0, count);
                };

                const filteredDecks = getFilteredDecks();

                // Update the state with the sorted array
                setDeckData(filteredDecks);
                setFilteredDecks(filteredData)


                // Calculate the average score of all decks
                const totalScoresSum = decksWithScores.reduce((acc, deck) => acc + deck.totalScore, 0);
                const averageScore = totalScoresSum / decksWithScores.length;

                // Log or use the average score
                console.log('Average Score:', averageScore);
                setAverageDeckScore(averageScore);

                // Use filtered data as needed
                // ...
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        const fetchUsers = async () => {
            try {
                // Check if companyName exists in database
                const response = await backend.get(`/api/user/bycompany?companyId=${companyId}`);
                console.log(response.data)
                if (response?.data) {
                    setTotalUser(response?.data?.length)
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        fetchUsers();

    }, [companyId, onBoarding, filter, deckUpdate]); // Dependency on companyId ensures useEffect runs when companyId changes


    const getMostFrequentOrRandomCompanyName = (industries) => {


        if (industries.length === 1) {
            return industries[0]; // Only one item, return it
        }

        // Count occurrences of each company name
        const nameCounts = industries.reduce((acc, name) => {
            acc[name] = (acc[name] || 0) + 1;
            return acc;
        }, {});

        // Find the maximum occurrence count
        const maxCount = Math.max(...Object.values(nameCounts));

        // Get all names with the maximum count
        const mostFrequentNames = Object.keys(nameCounts).filter(name => nameCounts[name] === maxCount);

        if (mostFrequentNames.length === 1) {
            return mostFrequentNames[0]; // Return the most frequent name
        } else {
            // If there's a tie, pick one randomly
            const randomIndex = Math.floor(Math.random() * mostFrequentNames.length);
            return mostFrequentNames[randomIndex];
        }
    };

    // Example usage
    // const companyNames = ["CompanyA", "CompanyB", "CompanyA", "CompanyC", "CompanyB", "CompanyA"];
    // console.log(chosenName);

    useEffect(() => {
        const fetchData = async () => {
            try {

                // Check if companyName exists in database
                const response = await backend.get(`/onboarding/?userID=${userID}`);
                // console.log(response.data[0])
                // If response.data is not empty, companyName exists in database
                if (response.data.length > 0) {
                    dispatch(setCompanyInfo(response.data[0]))
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // Empty dependency array ensures useEffect runs once on component mount

    // Function to handle update and filter data
    const handleDelete = async (deckId) => {
        try {
            setLoading(true)
            // Update backend to mark adminDelete as true for the deckId
            await backend.put(`/deck/${deckId}`, { adminDelete: true });

            // Refetch and filter data
            RefetchData();
            setLoading(false)


        } catch (error) {
            console.error('Error updating or fetching data:', error);
        }
    };

    const RefetchData = async () => {

        try {
            // Check if companyName exists in database
            const response = await backend.get(`/deck/bycompany?companyId=${companyId}`);

            // Filter the response data where adminDelete is false
            const filteredData = response?.data?.filter(item => item?.adminDelete === false && item?.adminDeck === false && item?.status === "submitted");
            const industries = filteredData?.map(item => item?.userId?.industry) || ['SaaS'];

            console.log(industries)
            const chosenName = getMostFrequentOrRandomCompanyName(industries);
            setIndustry(chosenName)

            // Calculate the date 30 days ago
            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo?.getDate() - 30);

            // Filter the data for the last 30 days
            const last30DaysData = filteredData?.filter(item => new Date(item?.createdAt) >= thirtyDaysAgo);

            // Log the filtered data
            console.log(filteredData);
            setDeckData(filteredData);
            setFilteredDecks(filteredData)


            // Log or set the length of the last 30 days data
            console.log(last30DaysData?.length);
            setLast30DaysDeck(last30DaysData?.length);

            // Calculate total score for each deck
            const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
            const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

            const calculateTotalScore = (deck) => {
                const scores = {
                    deckStructure: parseFloat(deck?.deckStructureScoreMatch) || 0,
                    marketOpportunity: parseFloat(deck?.marketPotentialScoreMatch) || 0,
                    marketingSales: parseFloat(deck?.marketingSalesScoreMatch) || 0,
                    businessModel: parseFloat(deck?.businessModelScoreMatch) || 0,
                    competitiveLandscape: parseFloat(deck?.competitiveLandscapeScoreMatch) || 0,
                    team: parseFloat(deck?.teamScoreMatch) || 0,
                    traction: parseFloat(deck?.tractionScoreMatch) || 0
                };

                let totalScore = activeScoringCriteria.reduce((acc, criteria) => {
                    return acc + (scores[criteria.name] * weight);
                }, 0);

                return +(totalScore?.toFixed(2)); // Round to two decimal places
            };

            // Add totalScore to each deck and sort by totalScore in descending order
            const decksWithScores = filteredData.map(deck => ({
                ...deck,
                totalScore: calculateTotalScore(deck)
            })).sort((a, b) => b.totalScore - a.totalScore);

            // Update the state with the sorted array
            setDeckData(decksWithScores);
            setFilteredDecks(filteredData)


            // Calculate the average score of all decks
            const totalScoresSum = decksWithScores.reduce((acc, deck) => acc + deck.totalScore, 0);
            const averageScore = totalScoresSum / decksWithScores.length;

            // Log or use the average score
            console.log('Average Score:', averageScore);
            setAverageDeckScore(averageScore);

            // Use filtered data as needed
            // ...

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const [demoDecks, setdemoDecks] = useState([
        {
            score: 80,
            companyName: 'Stripe',
            industry: 'FinTech',
            description: `Stripe is a global technology company that builds economic infrastructure for the internet. Its core service enables businesses of all sizes- from startups to large enterprises-to accept online payments securely and efficiently. Stripe provides a suite of APIs that allow developers to integrate payment processing into websites and mobile apps easily. In addition to payments, Stripe offers solutions for managing subscriptions, fraud prevention, financial reporting, and more.`,
            founders: 'Patrick Collison and John Collision',
            website: 'www.stripe.com',
            email: 'contact@stripe.com',
            link: '/demo',
            businessModelScoreMatch: '85',
            businessModelParagraphMatch: `Stripe's business model is robust, focusing on continuous innovation and developer-first tools, which are critical in the rapidly evolving tech landscape. The deck successfully communicates how Stripe's comprehensive toolkit and partnerships facilitate business operations globally, fostering a strong value proposition.`,
            deckStructureScoreMatch: '80',
            deckStructureParagraphMatch: `The overall structure of the pitch deck is well-organized, flowing logically from introducing Stripe's capabilities to detailing its global impact and customer base, which helps in maintaining audience engagement throughout the presentation.`,
            marketPotentialScoreMatch: '75',
            marketPotentialParagraphMatch: `Stripe operates in a high-growth market with increasing demands for online payment solutions. The presentation effectively highlights the vast market potential and Stripe's ability to capitalize on this through its global reach and scalable platform.`,
            marketingSalesScoreMatch: '78',
            marketingSalesParagraphMatch: `The pitch effectively outlines Stripe's marketing and sales strategies, showcasing their direct alignment with business growth and customer acquisition. Demonstrations of strategic partnerships and client testimonials could further enhance this section.`,
            competitiveLandscapeScoreMatch: '74',
            competitiveLandscapeParagraphMatch: `The competitive analysis outlines key differentiators that set Stripe apart in the market. However, more detailed comparisons with direct competitors could provide a clearer picture of Stripe's market standing.`,
            teamScoreMatch: '82',
            teamParagraphMatch: `The leadership team is presented as experienced and well-equipped to drive the company's vision. Including more specific achievements of team members could further strengthen this section.`,
            tractionScoreMatch: '85',
            tractionParagraphMatch: `The deck presents impressive traction, with a broad user base and significant transaction volumes. More detailed statistics on user growth rates and market penetration would provide deeper insights into Stripe's success.`,
        },
    ])

    console.log(activeScoringCriteria)
    // Function to filter and flatten objects based on active scoring criteria
    const filterAndFlattenObject = (obj) => {
        const activeCriteriaNames = activeScoringCriteria.map(criteria => criteria.name);
        const weight = activeScoringCriteria.length ? 1 / activeScoringCriteria.length : 0;



        // Parse scores and calculate the total score dynamically
        const scores = {
            deckStructure: parseFloat(obj?.deckStructureScoreMatch) || 0,
            marketOpportunity: parseFloat(obj?.marketPotentialScoreMatch) || 0,
            marketingSales: parseFloat(obj?.marketingSalesScoreMatch) || 0,
            businessModel: parseFloat(obj?.businessModelScoreMatch) || 0,
            competitiveLandscape: parseFloat(obj?.competitiveLandscapeScoreMatch) || 0,
            team: parseFloat(obj?.teamScoreMatch) || 0,
            traction: parseFloat(obj?.tractionScoreMatch) || 0
        };

        // Calculate totalScore
        let totalScore = activeScoringCriteria.reduce((acc, criteria) => {
            return acc + (scores[criteria.name] * weight);
        }, 0);
        totalScore = +(totalScore?.toFixed(2)); // Round to two decimal places

        const filteredObject = {
            user_id: obj.userId?._id,
            user_email: obj.userId?.email,
            user_founders: obj.userId?.founders,
            user_website: obj.userId?.website,
            user_companyName: obj.userId?.companyName,
            user_industry: obj.userId?.industry,
            user_companyDescription: obj.userId?.companyDescription,
            total_score: totalScore,

        };

        // Map the criteria to corresponding scores and paragraphs
        if (activeCriteriaNames.includes('businessModel')) {
            filteredObject.businessModelScoreMatch = obj.businessModelScoreMatch;
            filteredObject.businessModelParagraphMatch = obj.businessModelParagraphMatch;
        }
        if (activeCriteriaNames.includes('deckStructure')) {
            filteredObject.deckStructureScoreMatch = obj.deckStructureScoreMatch;
            filteredObject.deckStructureParagraphMatch = obj.deckStructureParagraphMatch;
        }
        if (activeCriteriaNames.includes('marketOpportunity')) {
            filteredObject.marketPotentialScoreMatch = obj.marketPotentialScoreMatch;
            filteredObject.marketPotentialParagraphMatch = obj.marketPotentialParagraphMatch;
        }
        if (activeCriteriaNames.includes('marketingSales')) {
            filteredObject.marketingSalesScoreMatch = obj.marketingSalesScoreMatch;
            filteredObject.marketingSalesParagraphMatch = obj.marketingSalesParagraphMatch;
        }
        if (activeCriteriaNames.includes('competitiveLandscape')) {
            filteredObject.competitiveLandscapeScoreMatch = obj.competitiveLandscapeScoreMatch;
            filteredObject.competitiveLandscapeParagraphMatch = obj.competitiveLandscapeParagraphMatch;
        }
        if (activeCriteriaNames.includes('team')) {
            filteredObject.teamScoreMatch = obj.teamScoreMatch;
            filteredObject.teamParagraphMatch = obj.teamParagraphMatch;
        }
        if (activeCriteriaNames.includes('traction')) {
            filteredObject.tractionScoreMatch = obj.tractionScoreMatch;
            filteredObject.tractionParagraphMatch = obj.tractionParagraphMatch;
        }

        return filteredObject;
    };

    // Function to convert data to CSV format
    const convertToCSV = (data) => {
        const filteredData = data.map(item => filterAndFlattenObject(item));
        const headers = Object.keys(filteredData[0]).join(','); // Extract headers
        const rows = filteredData.map(obj =>
            Object.values(obj).map(val => `"${val || ''}"`).join(',')
        );
        return [headers, ...rows].join('\n');
    };

    // Function to download the CSV file
    const downloadCSV = (data, filename = `${onBoarding?.companyName}-decks.csv`) => {
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        console.log(openNotifications)
    }, [openNotifications])

    return (loading && !onBoarding?.isFirstInteraction) ?
        <div style={{ background: 'white' }} >
            <div className={Classes.banner} >

            </div>
            <div className={Classes.mainRightBg} >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100vh",
                        alignItems: "center",
                    }}
                >
                    <div id="loading-bar-spinner" class="spinner">
                        <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
                    </div>
                </div>
            </div>
        </div>
        : (
            <>
                {openEmailConnectedPopup && <EmailSuccessPopup closePopup={handleCloseEmailConnectedPopup}
                    title="WOOHOO!"
                    description="Your email has been connected successfully."
                    buttonText="Return to dashboard"
                />}

                {onBoardingSuccessPopup && <OnBoardingUserSuccessPopup closePopup={handleCloseOnboardingSuccessPopup} />}
                <MobileVersionPopup />
                <div style={{ background: 'white' }} >
                    <div className={Classes.banner} >
                        <div className={Classes.flexCenter} >
                        </div>
                        <div className={Classes.flexAlign} >

                        </div>
                        <div className={Classes.bannerRight} >
                            {/* <div className={Classes.mainNotifications}
                                ref={NotificationsRef} >
                                <div className={Classes.bellCon} onClick={toggleNotifications}>
                                    <img src='/bell.png' alt='bell' draggable={false} />
                                    <span> <h5>0</h5> </span>
                                </div>
                                <AnimatePresence>
                                    {openNotifications && (
                                        <motion.div
                                            initial={{ opacity: 0, y: -5 }} // Animation when component enters
                                            animate={{ opacity: 1, y: 0 }}   // Animation when component is open
                                            exit={{ opacity: 0, y: -5 }}    // Animation when component exits
                                            transition={{ duration: 0.2 }}
                                            className={Classes.notifications}
                                        >
                                            <div className={Classes.notifyBanner}><h5>Notifications</h5></div>
                                            <div className={Classes.notifyBody}>
                                                <div className={Classes.emptyBox}>
                                                    <img src='/empty-box.png' alt='box' />
                                                    <h5>Coming soon</h5>
                                                </div>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div> */}

                        </div>
                    </div>
                    <div className={Classes.mainRightBg} >
                        <div className={Classes.hero2} >
                            <div className={Classes.searcCon} >
                                <img src="/icons/search.png" alt="icon" />
                                <input type="text" placeholder="Search" 
                                value={searchTerm}
                                onChange={handleSearch}
                                />
                            </div>
                            {/* <div className={Classes.flexAlign10} >
                                <div className={Classes.dropDown} ref={dropdownRef} >
                                    <div className={Classes.filterCon} onClick={() => { setisfilterDropDown(!isfilterDropDown) }} >
                                        <div className={Classes.flexAlign} >
                                            <img src='/filter.png' alt='filter' className={Classes.filterIcon} />
                                            <h5>Filter</h5>
                                        </div>
                                        <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '12px', transform: isfilterDropDown ? 'rotate(180deg)' : '' }} />
                                    </div>
                                    {isfilterDropDown &&
                                        <div className={Classes.filterdropDownBody} >
                                            <div className={Classes.ddPointerCon} >
                                                <div className={Classes.dropDownPointer} ></div>
                                            </div>
                                            <div className={Classes.filterdropDownBodyInner} >
                                                <h5 className={Classes.filterTitle} >Filters</h5>

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div> */}
                        </div>
                        <div className={Classes.hero3} >
                            <div className={Classes.tableCon} >
                                <div className={Classes.tableHeader} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                    <div> <h5>Rank</h5> </div>
                                    <div> <h5>Score</h5> </div>
                                    <div> <h5>Company name</h5> </div>
                                    <div> <h5>Industry</h5> </div>
                                    <div> <h5>Pitch deck</h5> </div>
                                    <div> <h5>My Score</h5> </div>
                                </div>
                                <div className={Classes.tableBody} >
                                    {currentItems && currentItems.length > 0 ? currentItems?.map((item, index) => (
                                        <RowComp key={item._id} value={item} index={index}
                                            handleDelete={() => handleDelete(item._id)}
                                            deckId={item._id}
                                            setdecisionLoading={setdecisionLoading}
                                        />
                                    )) :
                                        <div className='flex' >
                                            <h5>No data available.</h5>
                                        </div>
                                    }
                                    {decisionLoading &&
                                        <div className={Classes.loadingPopup} > <h5>Loading...</h5> </div>
                                    }
                                </div>
                                <div className={Classes.pagFooter} >
                                    <h5> Showing {startItem} to {endItem} of {totalItems} results </h5>
                                    <Stack spacing={2} className={Classes.pagination}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            shape="rounded"
                                        />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
}
