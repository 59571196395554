// DropDownElem.js
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Classes from '../styles/style.module.css';
import Checkbox from '@mui/material/Checkbox';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export default function DropDownElem({ filter, index, selectedOptions, onOptionChange, searchTerm }) {
  const onBoarding = useSelector((state) => state.companyInfo.info);

  useEffect(() => {
    if (onBoarding) {
      document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
    }
  }, [onBoarding]);

  // Filter options based on search term
  const filteredOptions = filter.options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={Classes.filterElem}>
      <Accordion
        sx={{
          boxShadow: 'none !important',
          border: 'none !important',
          borderRadius: '10px !important',
          marginBottom: '10px !important',
          '&:before': {
            display: 'none',
          },
        }}
        key={index}
      >
        <AccordionSummary
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          sx={{
            minHeight: '25px !important',
            margin: '0 !important',
            padding: '0px !important',
            '&.Mui-expanded': {
              margin: '0 !important',
              minHeight: '25px !important',
              padding: '0px !important',
            },
            '.MuiAccordionSummary-content': {
              margin: '0 !important',
            },
          }}
        >
          <div className={Classes.selectionCon}>
            <img src='/onBoarding/arrow-down.svg' alt='toggle' className={Classes.iconCon} />
            <h5 className={Classes.SelectionTitle}>{filter?.title}</h5>
          </div>
        </AccordionSummary>
        <AccordionDetails
          className={Classes.accordionBody}
          sx={{
            padding: '0px !important',
          }}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, optionIndex) => {
              const isChecked = selectedOptions.includes(option);
              const checkboxId = `checkbox-${index}-${optionIndex}`;
              return (
                <div className={Classes.dropDownElem} key={optionIndex}>
                  <Checkbox
                    id={checkboxId}
                    checked={isChecked}
                    onChange={(e) => {
                      onOptionChange(filter.title, option, e.target.checked);
                    }}
                    sx={{
                      color: onBoarding?.color,
                      padding: '5px',
                      '&.Mui-checked': {
                        color: onBoarding?.color,
                      },
                      '& .MuiSvgIcon-root': { fontSize: 17 },
                    }}
                  />
                  <label htmlFor={checkboxId}>
                    <h5>{option}</h5>
                  </label>
                </div>
              );
            })
          ) : (
            <div className={Classes.noOptions}>
              <h5>No options found</h5>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
