import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sessionstorage from "sessionstorage";
import { CircularProgress } from "@mui/material";

function RedirectAnalyzer() {
  const navigateTo = useNavigate();

  useEffect(() => {
    // Set the sessionStorage item
    sessionstorage.setItem("navBtn", "");
    
    // Navigate to the home page
    navigateTo('/');
  }, [navigateTo]); // Added navigateTo as a dependency

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      {/* MUI Circular Progress in the center */}
      <CircularProgress />
    </div>
  );
}

export default RedirectAnalyzer;
