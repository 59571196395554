import React, { useRef, useState, useEffect } from 'react';
import backend from '../../../../api';

import { Swiper, SwiperSlide } from 'swiper/react';
import Classes from '../../Pipeline.module.css'
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import './styles.css';
import { FreeMode, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import PipelineMenu from './PipelineMenu';
import { CircularProgress } from '@mui/material';


export default function PipelineCard({setLoading, key, data, setdecisionLoading, setPopupData, setopenSeeMorePopup, pipelineName }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const companyId = onBoarding?._id;
    const activeScoringCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);
    const { user } = useSelector((state) => state.auth);
    const [companyUsers, setCompanyUsers] = useState();

    // Function to get removed users by deckId from localStorage
    const getRemovedUsersByDeck = (deckId) => {
        const storedData = JSON.parse(localStorage.getItem('removedUsers')) || {};
        return storedData[deckId] || [];
    };

    const [removedUsers, setRemovedUsers] = useState(getRemovedUsersByDeck(data?.deck?._id));



    const userId = user._id


    function handlePopupData(data) {
        console.log("popupdata", data)
        setPopupData(data);
        setopenSeeMorePopup(true)
    }
    const formatDate = (createdAt) => {
        const date = new Date(createdAt);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return date.toLocaleDateString('en-US', options);
    };

    const weight = activeScoringCriteria?.length ? 1 / activeScoringCriteria?.length : 0;

    const scores = {
        deckStructure: parseFloat(data?.deck?.deckStructureScoreMatch) || 0,
        marketOpportunity: parseFloat(data?.deck?.marketPotentialScoreMatch) || 0,
        marketingSales: parseFloat(data?.deck?.marketingSalesScoreMatch) || 0,
        businessModel: parseFloat(data?.deck?.businessModelScoreMatch) || 0,
        competitiveLandscape: parseFloat(data?.deck?.competitiveLandscapeScoreMatch) || 0,
        team: parseFloat(data?.deck?.teamScoreMatch) || 0,
        traction: parseFloat(data?.deck?.tractionScoreMatch) || 0
    };

    // Calculate totalScore
    let totalScore = activeScoringCriteria?.reduce((acc, criteria) => {
        return acc + (scores[criteria.name] * weight);
    }, 0);

    totalScore = +(totalScore?.toFixed(2)); // Round to two decimal places

    const TotalReviewsDone = data?.deck.feedback.filter(feedbackItem => feedbackItem.score && feedbackItem?.score !== '').length;

    const IsAdminRatingDone = data?.deck.feedback.filter(feedbackItem => feedbackItem.userId && feedbackItem?.userId?._id === userId);
    // console.log("Is Admin rating done", IsAdminRatingDone)

    const totalComments = (data?.deck?.AdminComments ? data?.deck?.AdminComments.length : 0) +
        (data?.deck?.feedback
            ? data?.deck.feedback.filter(feedbackItem => feedbackItem.comment && feedbackItem.comment.trim() !== '').length
            : 0
        );

    const calculateAverageScore = (feedbackArray) => {
        // Filter out feedbacks that have a valid numeric score
        const validFeedbacks = feedbackArray.filter(feedback => typeof feedback.score === 'number');

        // If there are no valid feedbacks, return 0 to avoid division by zero
        if (validFeedbacks.length === 0) return 0;

        // Calculate the sum of all the scores
        const totalScore = validFeedbacks.reduce((acc, feedback) => acc + feedback.score, 0);

        // Calculate the average score
        const averageScore = totalScore / validFeedbacks.length;

        return averageScore;
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await backend.get(`api/user/all`);
                if (response?.data) {
                    const filteredUsers = response?.data?.data?.filter(user =>
                        user?.companyId?.toString() === companyId?.toString() &&
                        user?.companySubAdmin === true
                    );
                    const visibleUsers = filteredUsers.filter(user => !removedUsers.includes(user._id)); // Exclude removed users for the specific deck
                    setCompanyUsers(visibleUsers);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchUsers();
    }, [companyId, removedUsers, data?.deck?._id]); // Re-fetch when removed users or deck changes



    // Assuming companyUsers is an array of user objects and deck is an object with a feedback array

    // Calculate the number of users without feedback
    const countUsersWithoutFeedback = companyUsers?.filter(user =>
        // Check if the current user does NOT have any feedback in the deck
        !data?.deck?.feedback?.some(feedback =>
            // Compare user IDs (ensure both are strings for accurate comparison)
            String(feedback?.userId?._id) === String(user._id)
        )
    ).length ?? 0;

    // Example usage:
    console.log(`Number of users without feedback: ${countUsersWithoutFeedback}`);


    function getElapsedTime(pastDate) {
        // Convert the input to a Date object if it's a string
        const past = new Date(pastDate);
        const now = new Date();
        
        // Calculate the difference in milliseconds
        const diffMs = now - past;
        
        // Handle future dates
        if (diffMs < 0) {
            return "In the future";
        }
        
        // Define time constants
        const msInMinute = 1000 * 60;
        const msInHour = msInMinute * 60;
        const msInDay = msInHour * 24;
        
        // Calculate elapsed days, hours, and minutes
        const elapsedDays = Math.floor(diffMs / msInDay);
        const elapsedHours = Math.floor((diffMs % msInDay) / msInHour);
        const elapsedMinutes = Math.floor((diffMs % msInHour) / msInMinute);
        
        // Determine which unit to use
        if (elapsedDays >= 1) {
            return `${elapsedDays} day${elapsedDays === 1 ? "" : "s"}`;
        } else if (elapsedHours >= 1) {
            return `${elapsedHours} hour${elapsedHours === 1 ? "" : "s"}`;
        } else {
            return `${elapsedMinutes} minute${elapsedMinutes === 1 ? "" : "s"}`;
        }
    }
    return (    
    <>

        <div className={Classes.cardBox} >
            <div className={Classes.flexBetween} >
                <div className={Classes.flexAlign7} >
                    {/* <img className={Classes.icon} src='/icons/airbnbIcon.png' /> */}
                    <h5 className={Classes.titleMini2} style={{color:onBoarding?.color}} >{data?.deck?.userId?.companyName}</h5>
                </div>
                <h5 className={Classes.textMiniLight} > {getElapsedTime(data?.updatedAt)} in this stage </h5>
                <PipelineMenu data={data} setdecisionLoading={setdecisionLoading}
                pipelineName={pipelineName}
                setLoading={setLoading}
                />
            </div>
            <hr className={Classes.line} />
            <div className={`${Classes.flexBetween} ${Classes.mb10}`} >
                <h5 className={Classes.titleMini} >Entered pipeline</h5>
                <h5 className={Classes.textLight} >{formatDate(data?.createdAt)}</h5>
            </div>
            <div className={`${Classes.flexBetween}`} >
                <h5 className={Classes.titleMini} >Entered current stage</h5>
                <h5 className={Classes.textLight} >{formatDate(data?.updatedAt)}</h5>
            </div>
            <hr className={Classes.line} />
            <h5 className={Classes.titleMini} >Review</h5>
            <div className={`${Classes.flexBetween} ${Classes.mb10} mt10`} >
                <div>
                    <h5 className={Classes.textMiniLight} >{countUsersWithoutFeedback} review pending</h5>
                    <h5 className={Classes.textMiniLight} >{TotalReviewsDone} reviews done</h5>
                </div>
                {
                    IsAdminRatingDone?
                        <div>
                            <h5 className={Classes.scoreText}
                                onClick={() => handlePopupData(data?.deck)}
                            >{calculateAverageScore(data?.deck?.feedback)} {'>'}</h5>
                        </div>
                        :
                        <div>
                            <button className={Classes.rateBtn} style={{ background: onBoarding?.color ? onBoarding?.color : 'black' }} >
                                <h5
                                    onClick={() => handlePopupData(data?.deck)}
                                >Rate this startup</h5> </button>
                        </div>
                }
            </div>
            <hr className={Classes.line} />
            <h5 className={Classes.titleMini} >Comments</h5>

            {
                totalComments > 0 ?
                    <div className={`${Classes.flexAlign7} mt10`} >                                        <img src='/icons/commentIcon.png' alt='comment' style={{ height: '18px' }} />
                        <h5 className={Classes.textLight} >{totalComments}</h5></div> : <h5 className={Classes.textMiniLight} >No comments yet</h5>
            }
        </div>
    </>

    );
}
