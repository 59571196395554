import React, { useState, useEffect, useRef } from 'react'
import Classes from './Team.module.css'
import Color from 'color';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import sessionstorage from "sessionstorage";
import DemoRowComp from './DemoRowComp';
import InviteByEmailComp from './InviteByEmailComp';
import backend from '../../api';
import EmailSuccessPopup from '../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import BasicNotifyPopup from '../../Global-Components/BasicNotifyPopup/BasicNotifyPopup';
import NoDataRow from './NoDataRow';
import Notifications from '../../On-boarding/Global-Components/Notification/Notifcation';
import ContentLoader from 'react-content-loader';

const RowSkeleton = () => (
    <div style={{ height: '3.1rem', padding: '9px 20px', display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            height="32"
        >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="32" />
        </ContentLoader>
    </div>
);
const SmallRowSkeleton = (props) => (
    <div style={{ height: '1.7em', width: props.width, display: 'flex', alignItems: 'center' }} >
        <ContentLoader
            speed={1.2}
            width="100%"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="0" y="0" rx="4" ry="4" width="97.5%" {...props} />
        </ContentLoader>
    </div>
);

export default function Team() {
    const navigateTo = useNavigate();
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [decision, setdecision] = useState('Admin');

    const [openEmailSentPopup, setopenEmailSentPopup] = useState(false);
    const handleCloseEmailSentPopup = () => setopenEmailSentPopup(false);

    const [openAlreadyExistPopup, setopenAlreadyExistPopup] = useState(false);
    const [pending, setIsPending] = useState(false);

    const handleCloseAlreadyExistPopup = () => setopenAlreadyExistPopup(false);

    const companyId = onBoarding?._id;

    const [decisionLoading, setdecisionLoading] = useState(false);

    const [openNotifications, setopenNotifications] = useState(false);
    const NotificationsRef = useRef(null);
    const userId = user?._id;

    const [inviteAdmin, setinviteAdmin] = useState('');

    const [inviteeEmail, setInviteeEmail] = useState('');
    const [inviteeName, setInviteeName] = useState('User');
    const [createdInviteId, setCreatedInviteId] = useState('');
    const [allInvites, setAllInvites] = useState([]);
    const [activeInvites, setActiveInvites] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const deckUpdate = useSelector((state) => state.deck.data);

    const [filteredInvites, setFilteredInvites] = useState([]); // Data to display


    console.log(allInvites)


    // Function to handle the search input
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = allInvites.filter((invite) => {
            // Safely check if userId and fullname exist
            const name = invite.isAccepted && invite.userId?.fullname ? invite.userId.fullname.toLowerCase() : "pending";
            const email = invite.inviteeEmail ? invite.inviteeEmail.toLowerCase() : "";

            // Filter based on name or email
            return name.startsWith(value) || email.startsWith(value);
        });

        console.log(filtered)

        setFilteredInvites(filtered);
    };

    const onboardingId = onBoarding?._id;

    // const handleInviteInParent = () => {
    //     // Custom logic you want to perform when invite is triggered
    //     console.log('Invite triggered with decision:', decision);
    // };


    const userID = user?._id
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;



    // Calculate total pages
    const totalPages = Math.ceil(filteredInvites?.length / itemsPerPage);

    // Get current items
    const currentItems = filteredInvites?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // Calculate the range of items currently being shown
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, filteredInvites?.length);
    const totalItems = activeInvites?.length;



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (NotificationsRef.current && !NotificationsRef.current.contains(event.target)) {
                setopenNotifications(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleNotifications = (event) => {
        event.stopPropagation(); // Prevents event propagation to document
        setopenNotifications(prevState => !prevState);
    };


    const createInvite = async (id) => {

        const matchingInvite = allInvites?.find(invite => invite?.inviteeEmail === inviteeEmail);
        console.log(matchingInvite)
        let role;
        let companySubAdmin;
        let userExists = false;

        if (decision === "Admin") {
            role = "company"
            companySubAdmin = true
        } else {
            role = "judge"
            companySubAdmin = false
        }

        let existingUserId;
        let existingInviteId;

        if (matchingInvite) {
            if (matchingInvite?.status === true) {
                // alert("User already invited");
                if (matchingInvite?.isAccepted === true) {
                    setIsPending(false)
                } else {
                    setIsPending(true)
                }
                setopenAlreadyExistPopup(true);

                return;
            } else {
                const body = {
                    status: true,
                    role: role
                }
                userExists = true;
                existingInviteId = matchingInvite?._id;
                existingUserId = matchingInvite?.userId?._id;
                console.log("Invite Id: " + existingInviteId);
                console.log("User Id: " + existingUserId);
                const [inviteResponse, userResponse] = await Promise.all([
                    backend.put(`/api/invite/${existingInviteId}`, body),  // First API call
                    backend.put(`/api/user/${existingUserId}`, { role: role, companySubAdmin: companySubAdmin })  // Second API call
                ]);

                // Handle the responses
                console.log("Invite response:", inviteResponse.data);
                console.log("User response:", userResponse.data);
                sendEmail(existingInviteId, existingUserId, userExists)

                return;
            }
        }



        const body = {
            senderId: userID,
            inviteeEmail: inviteeEmail,
            companyId: companyId,
            role: role
        };

        try {
            // Include method to send the body with the request
            const response = await backend.post(`/api/invite`, body); // Pass body as the second argument to the PUT request
            if (response) {
                console.log(response)
                setCreatedInviteId(response?.data?._id)
                sendEmail(response?.data?._id)
                getAllInvites();
                // window.location.reload();
            }
            // You might want to do something with the response here, e.g., update UI or state

        } catch (error) {
            console.error(error);
            // Uncomment or modify the toast error message as needed
            // toast.error("Error saving story");
        }
    };

    const sendEmail = async (inviteId, existingUserId, userExists) => {



        const AdminHashCode = "x4a679ffdfdfqAEF5";
        const JudgeHashCode = "asasadffdfdfqE31xB";
        const existingUserHashCode = "asasaderuxrtdfqE31xB";


        let link;
        if (decision === 'Admin' && !userExists) {
            link = `https://${onBoarding.companyName}.evala.ai/${AdminHashCode}/${userId}/${inviteId}/${companyId}`;
        }

        else if (decision === 'Scorer' && !userExists) {
            link = `https://${onBoarding.companyName}.evala.ai/${JudgeHashCode}/${userId}/${inviteId}/${companyId}`;
        }
        else if (userExists) {
            link = `https://${onBoarding.companyName}.evala.ai/${existingUserHashCode}/${existingUserId}/${inviteId}/${companyId}`;
        }


        let role;
        if (decision === "Admin") {
            role = "Admin"
        } else {
            role = "Judge"
        }

        console.log(role)

        const companyName = onBoarding?.companyName?.toUpperCase();
        const body = {
            email: inviteeEmail,
            companyName: companyName,
            link: link,
            role: role,
            userExists: userExists
        };

        try {
            // Include method to send the body with the request
            await backend.post(`/email/adminmail`, body); // Pass body as the second argument to the PUT request

            // You might want to do something with the response here, e.g., update UI or state

            setLoading(false);
            setopenEmailSentPopup(true)
            getAllInvites()
        } catch (error) {
            console.error(error);
            // Uncomment or modify the toast error message as needed
            // toast.error("Error saving story");
        }
    };

    const getAllInvites = async () => {

        try {
            const response = await backend.get(`/api/invite/`);
            // console.log(response)
            if (response && Array.isArray(response.data)) {
                console.log(response.data); // Verifying the response data structure
                // Normalize the ID format before comparison, assuming userID is a string
                const ActiveInvites = response.data.filter(invite => invite?.companyId === companyId && invite?.status === true);
                const AllInvites = response.data.filter(invite => invite?.companyId === companyId);

                setActiveInvites(ActiveInvites)
                setAllInvites(AllInvites); // Make sure this is the correct function name
                setFilteredInvites(ActiveInvites);
            }
        } catch (error) {
            console.error(error);
            // toast.error("Error retrieving invites"); // Display error notification if you have a toast system set up
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (companyId) { // Ensure companyId is available
            getAllInvites();
        } else {
            console.log("companyId is not available yet.");
        }
    }, [deckUpdate, companyId]); // Depend on deckUpdate and companyId


    const handleInviteeEmailChange = (event) => {
        setInviteeEmail(event.target.value);
        // You can call another function here to trigger something based on email input change
    };

    // loading ?

    //     <div style={{ background: 'white' }} >
    //         <div className={Classes.banner} >

    //         </div>
    //         <div className={Classes.mainRightBg} >
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     justifyContent: "center",
    //                     height: "100vh",
    //                     alignItems: "center",
    //                 }}
    //             >
    //                 <div id="loading-bar-spinner" class="spinner">
    //                     <div class="spinner-icon" style={{ borderTopColor: onBoarding.color ? onBoarding.color : "red", borderLeftColor: onBoarding.color ? onBoarding.color : "red" }}></div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    //     : 

    return (
        <>
            <AnimatePresence exit="wait">
                {openEmailSentPopup && (
                    <EmailSuccessPopup
                        closePopup={handleCloseEmailSentPopup}
                        title="WOOHOO!"
                        description="Your invite was sent successfully."
                        buttonText="Continue"
                    />
                )}
            </AnimatePresence>
            <AnimatePresence exit="wait">
                {openAlreadyExistPopup && (
                    <BasicNotifyPopup
                        closePopup={handleCloseAlreadyExistPopup}
                        title="Invitation Already Exists"
                        description={pending ? "There is already a pending invitation for this user." : "There is already an active user on this email."}
                        buttonText="Return"
                        icon="exclamation"
                    />

                )}
            </AnimatePresence>
            <div style={{ background: 'white' }} >
                <div className={Classes.banner} >
                    <div className={Classes.flexCenter} >
                        {/* <h5 className={Classes.fw600} >Team!</h5> */}
                    </div>
                    <div className={Classes.flexAlign} >

                    </div>
                    <div className={Classes.bannerRight} >
                        <Notifications />

                    </div>
                </div>
                <div className={Classes.mainRightBg} >
                    <div className={Classes.hero2} >
                        <div className={Classes.searcCon} >
                            <img src="/icons/search.png" alt="icon" />
                            <input type="text" placeholder="Search by name or email"
                                value={searchTerm}
                                onChange={handleSearch}

                            />
                        </div>
                        <div className={Classes.flexAlign10} >
                            <InviteByEmailComp
                                onInvite={createInvite}
                                decision={decision}
                                setdecision={setdecision}
                                inviteeEmail={inviteeEmail}
                                setInviteeEmail={handleInviteeEmailChange}
                            />
                        </div>
                    </div>
                    <div className={Classes.hero3} >
                        <div className={Classes.tableCon} >
                            <div className={Classes.tableHeader} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <div> <h5>Name</h5> </div>
                                <div> <h5>Email</h5> </div>
                                <div> <h5>Role</h5> </div>
                                <div> <h5>Status</h5> </div>
                                <div> <h5></h5> </div>
                            </div>
                            <div className={Classes.tableBody} >
                                {filteredInvites && filteredInvites.length > 0 ? currentItems?.map((item, index) => (
                                    <DemoRowComp key={index + 1} value={item} index={index} setdecisionLoading={setdecisionLoading} />
                                )) :
                                    loading ? Array.from({ length: 5 }).map((_, index) => (
                                        <RowSkeleton key={index} />
                                    )) :
                                        <>
                                            <h5 className={Classes.sampleText} >Invite admins or scorers above</h5>
                                            <hr className={Classes.line} />
                                            {/* <NoDataRow setdecisionLoading={setdecisionLoading} value={'Admin'} /> */}
                                        </>
                                }
                                {decisionLoading &&
                                    <div className={Classes.loadingPopup} > <h5>Loading...</h5> </div>
                                }
                            </div>
                            <div className={Classes.pagFooter} >
                                <h5 style={{ height: '1.2rem' }} > {loading ? <SmallRowSkeleton height={'1.2rem'} width={'10rem'} /> : `Showing ${startItem} to ${endItem} of ${totalItems} results`} </h5>
                                <Stack spacing={2} className={Classes.pagination}>
                                    {loading ? <SmallRowSkeleton height={'1.2rem'} width={'5rem'} /> :
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            shape="rounded"
                                        />
                                    }
                                </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}