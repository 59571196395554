import React, { useEffect, useState } from 'react';
import Classes from './PitchDashboard.module.css';
import { pdfjs, Document } from 'react-pdf';
import { useSelector } from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ConfirmPopup from '../../Global-Components/ConfirmPopup/ConfirmPopup';
import { AnimatePresence } from 'framer-motion';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CardItem({ pdfUrl, id, index, DeleteFile, onFinalSelection, isFinal, setopenUploadVideoPopup, submittedStatus }) {
    const [numPages, setNumPages] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const closeStatus = onBoarding?.closeSubmission?.isActive;
    const CloseDate = new Date(onBoarding?.closeSubmission?.value);
    const todayDate = new Date();
    // Combine conditions into a single boolean
    const hasPassedDeadlineAndIsActive = CloseDate ? todayDate > CloseDate && closeStatus : false;


    useEffect(() => {
        const fetchFirstPage = async () => {
            try {
                const pdf = await pdfjs.getDocument(pdfUrl).promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({ canvasContext: context, viewport }).promise;
                setImageUrl(canvas.toDataURL());
            } catch (error) {
                console.error("Error fetching PDF first page:", error);
            }
        };
        fetchFirstPage();
    }, [pdfUrl]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const openDeleteConfirmPopup = () => {
        setShowConfirmPopup(true);
    };

    const closeDeleteConfirmPopup = () => {
        setShowConfirmPopup(false);
    };

    const handleDelete = () => {
        DeleteFile(id);
        closeDeleteConfirmPopup();
    };

    const handleFinalClick = () => {

        if (hasPassedDeadlineAndIsActive) {
            alert("Deadline Passed")
            return;
        }
        onFinalSelection(id);

        setopenUploadVideoPopup(true);

    };

    return (
        <li className={`${Classes.cards_item}`}>
            <div className={Classes.card} style={{ border: isFinal ? `2px solid ${onBoarding.color}` : '2px solid transparent' }}  >
                <div className={Classes.card_image}>
                    {imageUrl ? (
                        <img src={imageUrl} alt={`PDF Page 1`} onClick={handleFinalClick} />
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
                <div className={Classes.card_content}>
                    {
                        submittedStatus === "submitted" ?
                            <h5 style={{ color: onBoarding.color ? onBoarding.color : "red", fontSize: '12px', fontWeight: '700' }}  >Submitted</h5> :
                            <button className={Classes.deleteBtn} onClick={openDeleteConfirmPopup}> <h5>Delete</h5> </button>
                    }
                    <AnimatePresence>
                        {showConfirmPopup && (
                            <ConfirmPopup
                                title="Delete Deck?"
                                description="Are you sure you want to delete this pitch deck? This action can’t be undone."
                                onConfirm={handleDelete}
                                onCancel={closeDeleteConfirmPopup}
                                id={id}
                            />
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </li>
    );
}

export default CardItem;
