import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({ onBoarding, deckData }) => {
  const [chartState, setChartState] = useState({
    series: [
      {
        name: 'Score',
        data: [],
      },
    ],
    options: {
      chart: {
        height: 300,
        type: 'radar',
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        radar: {
          size: 140,
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff'],
            },
          },
        },
      },
      colors: [onBoarding.color],
      markers: {
        size: 4,
        colors: ['#fff'],
        strokeColor: onBoarding.color ? onBoarding.color : 'red',
        strokeWidth: 2,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: [], // Initially empty, will be set dynamically
      },
      yaxis: {
        labels: {
          formatter: function (val, i) {
            if (i % 2 === 0) {
              return val;
            } else {
              return '';
            }
          },
        },
      },
    },
  });

  useEffect(() => {
    // Filter active criteria names and scores
    const activeCriteria = onBoarding?.scoringCriteria?.filter(criteria => criteria.status);

    const activeCategories = [];
    const activeScores = [];

    activeCriteria.forEach(criteria => {
      switch (criteria.name) {
        case 'deckStructure':
          activeCategories.push('Deck Structure');
          activeScores.push(deckData.deckStructureScoreMatch);
          break;
        case 'marketOpportunity':
          activeCategories.push('Market Opportunity');
          activeScores.push(deckData.marketPotentialScoreMatch);
          break;
        case 'marketingSales':
          activeCategories.push('Marketing Sales');
          activeScores.push(deckData.marketingSalesScoreMatch);
          break;
        case 'businessModel':
          activeCategories.push('Business Model');
          activeScores.push(deckData.businessModelScoreMatch);
          break;
        case 'competitiveLandscape':
          activeCategories.push('Competitive Landscape');
          activeScores.push(deckData.competitiveLandscapeScoreMatch);
          break;
        case 'team':
          activeCategories.push('Team');
          activeScores.push(deckData.teamScoreMatch);
          break;
        case 'traction':
          activeCategories.push('Traction');
          activeScores.push(deckData.tractionScoreMatch);
          break;
        default:
          break;
      }
    });

    // Update the chart state with the filtered categories and scores
    setChartState(prevState => ({
      ...prevState,
      series: [
        {
          ...prevState.series[0],
          data: activeScores,
        },
      ],
      options: {
        ...prevState.options,
        xaxis: {
          ...prevState.options.xaxis,
          categories: activeCategories,
        },
      },
    }));
  }, [onBoarding, deckData]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartState.options}
          series={chartState.series}
          type="radar"
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
