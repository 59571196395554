import React, { useState } from 'react';
import Classes from '../styles/style.module.css';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

export default function CommentElem({ deck }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [revealedComments, setRevealedComments] = useState({}); // To track revealed state for each comment
    const charLimit = 70; // Set your desired character limit

    const handleToggle = (index) => {
        setRevealedComments((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const formatDate = (createdAt) => {
        const date = new Date(createdAt);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return date.toLocaleDateString('en-US', options);
    };

    // Combine feedback and admin comments
    const combinedComments = [
        ...deck?.feedback.map(feedback => ({
            userId: feedback.userId,
            comment: feedback.comment,
            createdAt: feedback.createdAt,
            isAdmin: false // Indicate it's a user comment
        })),
        ...deck?.AdminComments.map(adminComment => ({
            userId: adminComment.userId,
            comment: adminComment.comment,
            createdAt: adminComment.createdAt,
            isAdmin: true // Indicate it's an admin comment
        }))
    ];

    return (
<div>
  {combinedComments?.map((comment, index) => (
    <div key={index} className={Classes.cardScrollElem2}>
      {comment?.comment?.length && (
        <div>
          <div className={`${Classes.flexAlign} ${Classes.mb10}`}>
            <h5 className={Classes.bigTitle}>{comment?.userId?.role==="company"?"Admin":comment?.userId?.fullname}</h5>
            <span className={Classes.statusText}>{formatDate(comment?.createdAt)}</span>
          </div>
          <motion.div
            initial={{ height: 'auto' }}
            animate={{ height: revealedComments[index] ? 'auto' : '2.4rem' }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={Classes.commentText}
            style={{ overflow: 'hidden', maxHeight: revealedComments[index] ? 'none' : '2.4rem' }}
          >
            <h5 className={Classes.text4}>
              {revealedComments[index] || comment?.comment?.length <= charLimit
                ? comment?.comment
                : `${comment?.comment?.substring(0, charLimit)}...`}
            </h5>
          </motion.div>

          {comment?.comment?.length > charLimit && (
            <div className={`${Classes.flexEnd} ${Classes.mt10}`}>
              <h5
                className={Classes.seeMoreText}
                style={{ color: onBoarding?.color }}
                onClick={() => handleToggle(index)}
              >
                {revealedComments[index] ? 'See less' : 'See more'}
              </h5>
            </div>
          )}
        </div>
      )}
    </div>
  ))}
</div>

    );
}
