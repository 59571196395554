import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Classes from './SectionD.module.css';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';

const SectionD = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const sectionRef = useRef(null); // Reference to the section

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section id="features" ref={sectionRef} className={Classes.section}>
      <AnimateTopToBottom delay={0.3}>
        <Button variant="contained" className="howItWorksBtn" > Features </Button>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.4}>
        <h1 className={Classes.heading} > Leverage AI to save you time and money </h1>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.5}>
        <div className={Classes.grid}>
          <div className={`${Classes.item} ${Classes.textCon}`}>
            <h1 className={Classes.subheading}> Custom screening tool in minutes </h1>
            <p className={Classes.text}>
              Create your own screening tool in minutes with your own branding, pitch deck scoring criteria, and a custom landing page link for your applicants.
            </p>
            <h5 className={Classes.colored}> {`Create your custom tool >`} </h5>
          </div>
          <div className={`${Classes.item} ${Classes.ImgCon}`}>
            <img src='/1.png' alt='image' className={Classes.image} />
          </div>
        </div>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.3}>
        <div className={`${Classes.grid} ${Classes.gridReverse}`}>
          <div className={`${Classes.item} ${Classes.ImgCon}`}>
            <img src='/2.png' alt='image' className={Classes.image} />
          </div>
          <div className={`${Classes.item} ${Classes.textCon}`}>
            <h1 className={Classes.subheading}> Full analysis of pitch decks </h1>
            <p className={Classes.text}>
              Upload a pitch deck and our AI will analyze it, providing actionable feedback, suggestions, and scoring based on specific criteria to help applicants refine their decks.
            </p>
            <h5 className={Classes.colored}> {`Start analyzing decks >`} </h5>
          </div>
        </div>
      </AnimateTopToBottom>
      <AnimateTopToBottom delay={0.3}>
        <div className={Classes.grid}>
          <div className={`${Classes.item} ${Classes.textCon}`}>
            <h1 className={Classes.subheading}> Screening dashboard for key metrics </h1>
            <p className={Classes.text}>
            Track submitted pitch decks on a dashboard and review key metrics such as company description, comprehensive pitch deck summary, and overall deck score.
            </p>
            <h5 className={Classes.colored}> {`Start screening decks >`} </h5>
          </div>
          <div className={`${Classes.item} ${Classes.ImgCon}`}>
            <img src='/3.png' alt='image' className={Classes.image} />
          </div>
        </div>
      </AnimateTopToBottom>
      {/* Repeat similar div components for other sections */}
    </section>
  );
};

export default SectionD;
