import React, { useState, useEffect } from 'react';
import { LinearProgress, Box, Typography, styled, Grid } from '@mui/material';

// Custom style for the LinearProgress component
// Custom style for the LinearProgress component using the styled utility
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 25,

  width: "100%",
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#0262DF',
    borderRadius: 25
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },

}));

const LoadingLineWithPercentage = () => {
  // State to hold the current progress value
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{
      width: '80%', display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginTop: "9rem"
    }}>
      <Typography variant="body1" gutterBottom style={{ fontSize: "20px", textAlign: "center" }}>
        Your pitch deck is being generated by our AI
      </Typography>
      <p style={{ fontSize: "16px", color: "#424242", textAlign: "center" }}>
        This may take a few minutes. The counter might get stuck on 100%,<br />
        so please don't navigate away from this page.
      </p>
      {/* ... other text components */}
      <Box position="relative" display="inline-flex" width="100%" alignItems="center" style={{
        width: "50%", marginTop: "4.5rem"
      }}>
        <BorderLinearProgress variant="determinate" value={progress} />
        <Box
          sx={{
            position: 'absolute',
            left: `${progress}%`,
            transform: 'translate(-50%, -50%)',
            ml: '30px',
            mt: '25px'
          }}
        >
          <Typography variant="body3" component="div" color="textPrimary" sx={{ fontWeight: 'bold', fontWeight: "500", fontSize: "20px" }}>
            {`${Math.round(progress)}%`}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} style={{ marginTop: "8rem" }}>
        <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <img src='/icons/bulb.png' alt='bulb' style={{ height: "41px", width: "29px", marginBottom:'1rem' }} />
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Tell a compelling story
          </h1>

          <p style={{ fontSize: "17px", textAlign: "center", marginTop: "-0.5rem", width: "330px" }}>
            Craft a compelling narrative that highlights
            the problem, solution, and market oppurtunity
          </p>
        </Grid>

        <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

          <img src='/icons/brush.png' alt='bulb' style={{ height: "41px", width: "41px", marginBottom:'1rem' }} />
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Keep it simple and appealing          </h1>

          <p style={{ fontSize: "17px", textAlign: "center", marginTop: "-0.5rem", width: "320px" }}>
            Use a clean, visually appealing design with
            minimal text to make it engaging
          </p>

        </Grid>

        <Grid item xs={12} sm={12} md={4} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

          <img src='/icons/metric.png' alt='bulb' style={{ height: "41px", width: "42px", marginBottom:'1rem' }} />
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>
            Focus on key metrics
          </h1>

          <p style={{ fontSize: "17px", textAlign: "center", marginTop: "-0.5rem", width: "320px" }}>
            Showcase key metrics and traction to
            demonstrate business model effectiveness
          </p>

        </Grid>

      </Grid>

    </Box>
  );
};

export default LoadingLineWithPercentage;
