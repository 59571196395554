import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import Classes from '../styles/style.module.css';
import IndustryDropDown from './DropDowns/IndustryDropDown';
import CompanyStageDropDown from './DropDowns/CompanyStageDropDown';
import CurrentRevenueDropDown from './DropDowns/CurrentRevenueDropDown';
import InvestAmountDropDown from './DropDowns/InvestAmountDropDown';
import CountryDropDown from './DropDowns/CountryDropDown';
import PrevInvestDropDown from './DropDowns/PrevInvestDropDown';
import { setCompanyInfo } from '../../../redux-store/auth/actions';
import backend from '../../../api';

export default function CriteriaSelection({ callingFrom, handleNext, handleBack, activeMsg, direction }) {
    const dispatch = useDispatch();


    const onBoarding = useSelector((state) => state.companyInfo.info);
    const companyId = onBoarding?._id;
    const savedCriteria = onBoarding?.onboardingCriteria;

    console.log('Is Active is ' + savedCriteria?.isActive)

    console.log(savedCriteria)

    const [criteriaSelections, setCriteriaSelections] = useState({
        industry: savedCriteria?.industry || [],           // Flatten and set to array or empty array
        companyStage: savedCriteria?.companyStage || [],   // Flatten and set to array or empty array
        currentRevenue: savedCriteria?.currentRevenue || [],   // Same for other fields
        investmentAmount: savedCriteria?.investmentAmount || [],
        previousInvestment: savedCriteria?.previousInvestment || [],
        country: savedCriteria?.country || [],
        isActive: savedCriteria?.isActive
    });

    // If you need to update the state after fetching data (if the state is not immediately set due to async nature)
    useEffect(() => {
        if (savedCriteria) {
            setCriteriaSelections({
                industry: savedCriteria?.industry || [],
                companyStage: savedCriteria?.companyStage || [],
                currentRevenue: savedCriteria?.currentRevenue || [],
                investmentAmount: savedCriteria?.investmentAmount || [],
                previousInvestment: savedCriteria?.previousInvestment || [],
                country: savedCriteria?.country || [],
                isActive: true
            });
        }
    }, [savedCriteria]); // Re-run the effect when `savedCriteria` changes

    async function UpdateDB() {
        const body = {
            onboardingCriteria: criteriaSelections
        }
        try {
            // Send PUT request to update company criteria selections
            const response = await backend.put(`/onboarding/${companyId}`, body);

            if (response.status === 200) {
                console.log('Criteria updated successfully', response.data);
                dispatch(setCompanyInfo(response?.data?.data))
            } else {
                console.error('Error updating criteria');
            }
        } catch (error) {
            console.error('Error updating criteria:', error);
        }
    }

    const handleSelectionChange = (type, selections) => {
        setCriteriaSelections(prevState => ({
            ...prevState,
            [type]: selections
        }));
    };

    console.log(criteriaSelections)

    return (
        <>
            <motion.div
                initial={{ x: direction === "forward" ? activeMsg === 1 ? 0 : 20 : -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: direction === "forward" ? -20 : 20, opacity: 0 }}
                transition={{ duration: 0.25 }}
                className={Classes.cardCon}
            >
                <h5 className={`${Classes.text2} ${Classes.m1r}`}>
                    Only allow companies that fit your criteria <br />
                    to register and submit their pitch decks.
                </h5>
                <div className={Classes.miniCon}>
                    <div className={Classes.selectionMain} >
                        <div className={Classes.selectionCon}>
                            <h5 className={Classes.SelectionTitle}>Industry</h5>
                            <IndustryDropDown
                                selected={criteriaSelections.industry}
                                onChange={(selections) => handleSelectionChange('industry', selections)}
                            />
                        </div>
                        <div className={Classes.selectionCon}>
                            <h5 className={Classes.SelectionTitle}>Company Stage</h5>
                            <CompanyStageDropDown
                                selected={criteriaSelections.companyStage}
                                onChange={(selections) => handleSelectionChange('companyStage', selections)}
                            />
                        </div>
                        <div className={Classes.selectionCon}>
                            <h5 className={Classes.SelectionTitle}>Current Revenue</h5>
                            <CurrentRevenueDropDown
                                selected={criteriaSelections.currentRevenue}
                                onChange={(selections) => handleSelectionChange('currentRevenue', selections)}
                            />
                        </div>
                        <div className={Classes.selectionCon}>
                            <h5 className={Classes.SelectionTitle}>Investment Amount</h5>
                            <InvestAmountDropDown
                                selected={criteriaSelections.investmentAmount}
                                onChange={(selections) => handleSelectionChange('investmentAmount', selections)}
                            />
                        </div>
                        <div className={Classes.selectionCon}>
                            <h5 className={Classes.SelectionTitle}>Previous Investment</h5>
                            <PrevInvestDropDown
                                selected={criteriaSelections.previousInvestment}
                                onChange={(selections) => handleSelectionChange('previousInvestment', selections)}
                            />
                        </div>
                        <div className={Classes.selectionCon}>
                            <h5 className={Classes.SelectionTitle}>Country</h5>
                            <CountryDropDown
                                selected={criteriaSelections.country}
                                onChange={(selections) => handleSelectionChange('country', selections)}
                            />
                        </div>
                    </div>
                </div>
                {callingFrom === "onBoarding" &&
                    <div className={Classes.flexAlign}>
                        <button type="button" onClick={handleNext} className={Classes.btn1} style={{ border: `1px solid ${onBoarding.color}` }}>
                            <h5 style={{ color: onBoarding.color || "red" }}> Skip </h5>
                        </button>
                        <button type="button" onClick={(e) => { handleNext(e); UpdateDB(e) }} className={Classes.btn2} style={{ background: onBoarding.color || "black", border: `1px solid ${onBoarding.color}` }}>
                            <h5> Next </h5>
                        </button>
                    </div>
                }
                {callingFrom === "setting" &&
                    <button type="button" onClick={(e) => { handleNext(e); UpdateDB(e) }} className={Classes.btn2} style={{ background: onBoarding.color || "black", border: `1px solid ${onBoarding.color}` }}>
                        <h5> Confirm </h5>
                    </button>
                }
            </motion.div>
        </>
    );
}
