import React, { useState } from 'react';
import Classes from './SummaryPopup.module.css';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

function SummaryPopup({ openSummary, handleCloseSummary }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const handleBgClick = () => {
        handleCloseSummary();
    };

    const handleCardClick = (event) => {
        event.stopPropagation();
    };

    const [deckData, setDeckData] = useState({
        score: 80,
        companyName: 'Stripe',
        industry: 'FinTech',
        description: `Stripe is a global technology company that builds economic infrastructure for the internet. Its core service enables businesses of all sizes- from startups to large enterprises-to accept online payments securely and efficiently. Stripe provides a suite of APIs that allow developers to integrate payment processing into websites and mobile apps easily. In addition to payments, Stripe offers solutions for managing subscriptions, fraud prevention, financial reporting, and more.`,
        founders: 'Patrick Collison and John Collision',
        website: 'www.stripe.cm',
        email: 'contact@stripe.com',
        link: '/stripe',
        businessModelScoreMatch: '85',
        businessModelParagraphMatch: `Stripe's business model is robust, focusing on continuous innovation and developer-first tools, which are critical in the rapidly evolving tech landscape. The deck successfully communicates how Stripe's comprehensive toolkit and partnerships facilitate business operations globally, fostering a strong value proposition.`,
        deckStructureScoreMatch: '80',
        deckStructureParagraphMatch: `The overall structure of the pitch deck is well-organized, flowing logically from introducing Stripe's capabilities to detailing its global impact and customer base, which helps in maintaining audience engagement throughout the presentation.`,
        marketPotentialScoreMatch: '75',
        marketPotentialParagraphMatch: `Stripe operates in a high-growth market with increasing demands for online payment solutions. The presentation effectively highlights the vast market potential and Stripe's ability to capitalize on this through its global reach and scalable platform.`,
        marketingSalesScoreMatch: '78',
        marketingSalesParagraphMatch: `The pitch effectively outlines Stripe's marketing and sales strategies, showcasing their direct alignment with business growth and customer acquisition. Demonstrations of strategic partnerships and client testimonials could further enhance this section.`,
        competitiveLandscapeScoreMatch: '74',
        competitiveLandscapeParagraphMatch: `The competitive analysis outlines key differentiators that set Stripe apart in the market. However, more detailed comparisons with direct competitors could provide a clearer picture of Stripe's market standing.`,
        teamScoreMatch: '82',
        teamParagraphMatch: `The leadership team is presented as experienced and well-equipped to drive the company's vision. Including more specific achievements of team members could further strengthen this section.`,
        tractionScoreMatch: '85',
        tractionParagraphMatch: `The deck presents impressive traction, with a broad user base and significant transaction volumes. More detailed statistics on user growth rates and market penetration would provide deeper insights into Stripe's success.`,

    })

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.summaryBg}>
                        <div className={Classes.close} onClick={handleCloseSummary}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </div>

                        <div className={Classes.flexBetween}>
                            <h2 className='mb20'>Summary</h2>
                        </div>
                        <div className={Classes.scroll} >
                            <div className={Classes.flexAlign}>
                                <img src='/deck.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Deck Structure</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.deckStructureParagraphMatch}
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/market.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Market Opportunity</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.marketPotentialParagraphMatch}
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/marketing.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Marketing & Sales</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.marketingSalesParagraphMatch}
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/business.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Business Model</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.businessModelParagraphMatch}
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/competitive.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Competitive Landscape</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.competitiveLandscapeParagraphMatch}
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/team.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Team</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.teamParagraphMatch}
                            </p>
                            <div className={Classes.flexAlign}>
                                <img src='/onBoarding/traction.png' alt={'deck'} className={Classes.icon} />
                                <h3 style={{ margin: 0 }}>Traction</h3>
                            </div>
                            <p className={Classes.para}>
                                {deckData?.tractionParagraphMatch}
                            </p>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </>
    );
}

export default SummaryPopup;
