import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import {
    Button,
    ListItem,
    ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from '../../Pages/Home-New/Components/Animations/AnimateTopToBottom';
import StripeCheckout from 'react-stripe-checkout';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import backend from '../../api';
import { setUserData } from "../../redux-store/auth/actions";
import axios from 'axios';
import Color from 'color';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';

const convertToRgba = (colorInput, opacity) => {
    try {
        const color = Color(colorInput);
        return color.alpha(opacity).rgb().string();
    } catch (error) {
        console.error("Invalid color format:", colorInput);
        return `rgba(0, 0, 0, ${opacity})`;
    }
};

function LearnMorePopup({ closeUpgradePopup, closeable }) {
    const [isYearly, setIsYearly] = useState(false);

       const onBoarding = useSelector((state) => state.companyInfo.info);

    const handleBgClick = () => {
        if (closeable) {
            closeUpgradePopup();
        }
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    usePreventScrolling();

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card} onClick={(event) => { handleCardClick(event) }} >
                {closeable &&
                    <div className={Classes.close} onClick={closeUpgradePopup} >
                        <img src='/icons/close.png' alt='close icon' />
                    </div>
                }
                <div className={Classes.header} >
                    {/* <div className={Classes.crownImg} style={{background: `${onBoarding.color+20}` }} > */}
                    <div className={Classes.crownImg} style={{ background: convertToRgba(onBoarding.color, 0.2) }} >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill={onBoarding.color} ><path d="M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0L86.4 427.4c5.5 30.4 32 52.6 63 52.6H426.6c30.9 0 57.4-22.1 63-52.6L535.3 176c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-89.1 71.3c-15.9 12.7-39.5 7.5-48.6-10.7L309 106z" /></svg>
                    </div>
                    {/* <img src='/icons/upgrade-round.png' alt='upgrade-icon' /> */}
                    <h2 className={Classes.planName}>Free year of premium</h2>
                    <h5>
                        Score 90 or above on your pitch deck and unlock a full year of premium features!
                        <br />
                        <br />
                        Simply tag our LinkedIn account with a picture of your impressive score to claim your reward!
                    </h5>
                </div>
                <a href='https://www.linkedin.com/company/pitchperfecter-ai/' target='_blank' >
                    <button className={Classes.premiumBtn} style={{ background: onBoarding.color?onBoarding.color:"black" }} >LinkedIn</button>
                </a>
            </div>
        </div>
    )
}

export default LearnMorePopup
