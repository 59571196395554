import React, { useState } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion'
import CriteriaSelection from '../../../Global-Components/OnBoardingSuccessPopup/Comp/CriteriaSelection';
import {usePreventScrolling} from '../../../Hooks/usePreventScrolling';

function CompanyCriteriaPopup({ closePopup }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const companyId = onBoarding?._id;

    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        closePopup();
    }

    const [activeMsg, setActiveMsg] = useState(1);
    const [direction, setDirection] = useState("forward"); // Track direction

    const handleBack = () => {
        closePopup();
    };

    usePreventScrolling();

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: -7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    {activeMsg > 4 &&
                        <div className={Classes.close} onClick={handleSubmit} >
                            <img src='/icons/close.png' alt='close icon' />
                        </div>
                    }
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <h5 className={Classes.title} > Company criteria </h5>
                        <div className={Classes.curveCon} ></div>
                    </div>
                    <div>
                        <CriteriaSelection callingFrom="setting" handleNext={handleSubmit} handleBack={handleBack} activeMsg={activeMsg} direction={direction} />
                    </div>
                </motion.div>
            </motion.div>
        </>
    )
}

export default CompanyCriteriaPopup
