import React, { useState } from 'react';
import Classes from '../Setting.module.css';
import { useSelector, useDispatch } from 'react-redux';
import backend from '../../../api';
import EmailSuccessPopup from '../../../Global-Components/EmailSuccessPopup/EmailSuccessPopup';
import { CircularProgress } from '@mui/material';
import { setCompanyInfo } from '../../../redux-store/auth/actions';

export default function OutlookInstructions() {
    const companyInfo = useSelector((state) => state.companyInfo?.info);
    const [email, setEmail] = useState(companyInfo?.email || '');
    const [password, setPassword] = useState(companyInfo?.app_password || '');
    const [loading, setLoading] = useState(false);
    const [openEmailConnectedPopup, setOpenEmailConnectedPopup] = useState(false);

    const dispatch = useDispatch();
    const companyId = companyInfo?._id;
    const companyName = companyInfo?.companyName;
    const companyWebsite = companyInfo?.companyWebsite;
    const logo = companyInfo?.logo;

    const handleCloseEmailConnectedPopup = () => {
        setOpenEmailConnectedPopup(false);
    };

    const UpdateCompany = async (e) => {
        e.preventDefault();
        setLoading(true);

        const body = {
            mail_user: email,
            mail_password: password,
            message: "Your email is connected now",
            subject: "connected",
            email: email,
            companyName: companyName,
            companyWebsite: companyWebsite,
            logo: logo,
        };

        try {
            // Send email
            const emailResponse = await backend.post("/email/connected", body);
            console.log("Email Response Status:", emailResponse.status);

            if (emailResponse.status === 200) {
                try {
                    // Update company info
                    const updateResponse = await backend.put(`/onboarding/${companyId}`, {
                        email: email,
                        app_password: password,
                    });

                    console.log("Update Response:", updateResponse);

                    if (updateResponse?.data) {
                        // Dispatch action and show popup
                        dispatch(setCompanyInfo(updateResponse.data.data));
                        setOpenEmailConnectedPopup(true);
                    }
                } catch (updateError) {
                    console.error("Error updating company info:", updateError.message);
                }
            } else {
                console.error("Failed to send email. Status code:", emailResponse.status);
            }
        } catch (emailError) {
            console.error("Error sending email:", emailError.message);
        } finally {
            setLoading(false); // Ensure loading state is reset in all cases
        }
    };

    return loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
    ) : (
        <>
            {openEmailConnectedPopup && (
                <EmailSuccessPopup
                    closePopup={handleCloseEmailConnectedPopup}
                    title="WOOHOO!"
                    description="Your email has been connected successfully."
                    buttonText="Return to dashboard"
                />
            )}
            <div>
                <h5 className={`${Classes.accrdionMiniTitle} ${Classes.mb10}`}>Outlook Instructions</h5>
                <ol className={`${Classes.instructionsList} ${Classes.mb20}`}>
                    <li>
                        <h5>Enable Two-Step Verification (2FA):</h5>
                        <ul>
                            <li><h5 className={Classes.flexAlign}>Go to <a href='https://account.microsoft.com/security' target='_blank' rel="noopener noreferrer">account.microsoft.com/security</a>.</h5></li>
                            <li><h5>Under "Security basics," select "Advanced security options.</h5></li>
                            <li><h5>Under "Two-step verification," click "Set up" and follow the on-screen instructions to complete the setup.</h5></li>
                        </ul>
                    </li>
                    <li>
                        <h5>Generate an App Password:</h5>
                        <ul>
                            <li><h5>In the "Advanced security options" page, find "App passwords" and select "Create a new app password."</h5></li>
                            <li><h5>An app password will be generated and displayed on the screen.</h5></li>
                        </ul>
                    </li>
                    <li>
                        <h5>Copy the App Password:</h5>
                        <ul>
                            <li><h5>Copy the 16-character app password that appears.</h5></li>
                        </ul>
                    </li>
                    <li>
                        <h5>Enter the App Password Below:</h5>
                        <ul>
                            <li><h5>Paste the app password in the field below and click "Connect."</h5></li>
                        </ul>
                    </li>
                </ol>
                <form onSubmit={UpdateCompany} className={Classes.mailInputsCon}>
                    <div className={`${Classes.passwordCon} ${Classes.mb10}`}>
                        <input
                            type="email"
                            placeholder="Enter email address"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>
                    <div className={`${Classes.passwordCon} ${Classes.mb10}`}>
                        <input
                            type="text"
                            placeholder="App generated password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                    </div>
                    <button type='submit' className={`${Classes.confirmBtn} ${Classes.mb10}`} style={{ background: companyInfo?.color, width: '100%' }}>
                        <h5>Connect</h5>
                    </button>
                </form>
            </div>
        </>
    );
}
