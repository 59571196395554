import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import Classes from '../../styles/style.module.css'
import { AnimatePresence, motion } from 'framer-motion'
import Checkbox from '@mui/material/Checkbox';

export default function CountryDropDown({ selected, onChange }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const countryOptions = [
        { value: 'USA', label: 'United States' },
        { value: 'CAN', label: 'Canada' },
        { value: 'UK', label: 'United Kingdom' },
        { value: 'GER', label: 'Germany' },
        { value: 'IND', label: 'India' },
        { value: 'SGP', label: 'Singapore' },
        { value: 'AFG', label: 'Afghanistan' },
        { value: 'ALB', label: 'Albania' },
        { value: 'DZA', label: 'Algeria' },
        { value: 'AND', label: 'Andorra' },
        { value: 'AGO', label: 'Angola' },
        { value: 'ARG', label: 'Argentina' },
        { value: 'ARM', label: 'Armenia' },
        { value: 'AUS', label: 'Australia' },
        { value: 'AUT', label: 'Austria' },
        { value: 'AZE', label: 'Azerbaijan' },
        { value: 'BHR', label: 'Bahrain' },
        { value: 'BGD', label: 'Bangladesh' },
        { value: 'BLR', label: 'Belarus' },
        { value: 'BEL', label: 'Belgium' },
        { value: 'BLZ', label: 'Belize' },
        { value: 'BEN', label: 'Benin' },
        { value: 'BTN', label: 'Bhutan' },
        { value: 'BOL', label: 'Bolivia' },
        { value: 'BIH', label: 'Bosnia and Herzegovina' },
        { value: 'BWA', label: 'Botswana' },
        { value: 'BRA', label: 'Brazil' },
        { value: 'BRN', label: 'Brunei' },
        { value: 'BGR', label: 'Bulgaria' },
        { value: 'BFA', label: 'Burkina Faso' },
        { value: 'BDI', label: 'Burundi' },
        { value: 'KHM', label: 'Cambodia' },
        { value: 'CMR', label: 'Cameroon' },
        { value: 'CHL', label: 'Chile' },
        { value: 'CHN', label: 'China' },
        { value: 'COL', label: 'Colombia' },
        { value: 'COD', label: 'Congo (Democratic Republic)' },
        { value: 'CRI', label: 'Costa Rica' },
        { value: 'CIV', label: 'Côte d\'Ivoire' },
        { value: 'HRV', label: 'Croatia' },
        { value: 'CUB', label: 'Cuba' },
        { value: 'CYP', label: 'Cyprus' },
        { value: 'CZE', label: 'Czech Republic' },
        { value: 'DNK', label: 'Denmark' },
        { value: 'DJI', label: 'Djibouti' },
        { value: 'DOM', label: 'Dominican Republic' },
        { value: 'ECU', label: 'Ecuador' },
        { value: 'EGY', label: 'Egypt' },
        { value: 'SLV', label: 'El Salvador' },
        { value: 'EST', label: 'Estonia' },
        { value: 'ETH', label: 'Ethiopia' },
        { value: 'FIN', label: 'Finland' },
        { value: 'FRA', label: 'France' },
        { value: 'GAB', label: 'Gabon' },
        { value: 'GMB', label: 'Gambia' },
        { value: 'GEO', label: 'Georgia' },
        { value: 'GHA', label: 'Ghana' },
        { value: 'GRC', label: 'Greece' },
        { value: 'GTM', label: 'Guatemala' },
        { value: 'GIN', label: 'Guinea' },
        { value: 'GUY', label: 'Guyana' },
        { value: 'HTI', label: 'Haiti' },
        { value: 'HND', label: 'Honduras' },
        { value: 'HKG', label: 'Hong Kong' },
        { value: 'HUN', label: 'Hungary' },
        { value: 'ISL', label: 'Iceland' },
        { value: 'IDN', label: 'Indonesia' },
        { value: 'IRN', label: 'Iran' },
        { value: 'IRQ', label: 'Iraq' },
        { value: 'IRL', label: 'Ireland' },
        { value: 'ISR', label: 'Israel' },
        { value: 'ITA', label: 'Italy' },
        { value: 'JAM', label: 'Jamaica' },
        { value: 'JPN', label: 'Japan' },
        { value: 'JOR', label: 'Jordan' },
        { value: 'KAZ', label: 'Kazakhstan' },
        { value: 'KEN', label: 'Kenya' },
        { value: 'KOR', label: 'Korea (South)' },
        { value: 'KWT', label: 'Kuwait' },
        { value: 'KGZ', label: 'Kyrgyzstan' },
        { value: 'LAO', label: 'Laos' },
        { value: 'LVA', label: 'Latvia' },
        { value: 'LBN', label: 'Lebanon' },
        { value: 'LSO', label: 'Lesotho' },
        { value: 'LBR', label: 'Liberia' },
        { value: 'LIE', label: 'Liechtenstein' },
        { value: 'LTU', label: 'Lithuania' },
        { value: 'LUX', label: 'Luxembourg' },
        { value: 'MAC', label: 'Macao' },
        { value: 'MDG', label: 'Madagascar' },
        { value: 'MWI', label: 'Malawi' },
        { value: 'MYS', label: 'Malaysia' },
        { value: 'MDV', label: 'Maldives' },
        { value: 'MLI', label: 'Mali' },
        { value: 'MLT', label: 'Malta' },
        { value: 'MEX', label: 'Mexico' },
        { value: 'MDA', label: 'Moldova' },
        { value: 'MCO', label: 'Monaco' },
        { value: 'MNG', label: 'Mongolia' },
        { value: 'MNE', label: 'Montenegro' },
        { value: 'MAR', label: 'Morocco' },
        { value: 'MOZ', label: 'Mozambique' },
        { value: 'MMR', label: 'Myanmar' },
        { value: 'NAM', label: 'Namibia' },
        { value: 'NPL', label: 'Nepal' },
        { value: 'NLD', label: 'Netherlands' },
        { value: 'NZL', label: 'New Zealand' },
        { value: 'NIC', label: 'Nicaragua' },
        { value: 'NER', label: 'Niger' },
        { value: 'NGA', label: 'Nigeria' },
        { value: 'NOR', label: 'Norway' },
        { value: 'OMN', label: 'Oman' },
        { value: 'PAK', label: 'Pakistan' },
        { value: 'PAN', label: 'Panama' },
        { value: 'PNG', label: 'Papua New Guinea' },
        { value: 'PRY', label: 'Paraguay' },
        { value: 'PER', label: 'Peru' },
        { value: 'PHL', label: 'Philippines' },
        { value: 'POL', label: 'Poland' },
        { value: 'PRT', label: 'Portugal' },
        { value: 'QAT', label: 'Qatar' },
        { value: 'ROU', label: 'Romania' },
        { value: 'RUS', label: 'Russia' },
        { value: 'RWA', label: 'Rwanda' },
        { value: 'SAU', label: 'Saudi Arabia' },
        { value: 'SEN', label: 'Senegal' },
        { value: 'SRB', label: 'Serbia' },
        { value: 'SGP', label: 'Singapore' },
        { value: 'SVK', label: 'Slovakia' },
        { value: 'SVN', label: 'Slovenia' },
        { value: 'ZAF', label: 'South Africa' },
        { value: 'ESP', label: 'Spain' },
        { value: 'LKA', label: 'Sri Lanka' },
        { value: 'SWE', label: 'Sweden' },
        { value: 'CHE', label: 'Switzerland' },
        { value: 'SYR', label: 'Syria' },
        { value: 'TWN', label: 'Taiwan' },
        { value: 'TJK', label: 'Tajikistan' },
        { value: 'TZA', label: 'Tanzania' },
        { value: 'THA', label: 'Thailand' },
        { value: 'TUN', label: 'Tunisia' },
        { value: 'TUR', label: 'Turkey' },
        { value: 'UGA', label: 'Uganda' },
        { value: 'UKR', label: 'Ukraine' },
        { value: 'ARE', label: 'United Arab Emirates' },
        { value: 'GBR', label: 'United Kingdom' },
        { value: 'USA', label: 'United States' },
        { value: 'URY', label: 'Uruguay' },
        { value: 'UZB', label: 'Uzbekistan' },
        { value: 'VEN', label: 'Venezuela' },
        { value: 'VNM', label: 'Vietnam' },
        { value: 'YEM', label: 'Yemen' },
        { value: 'ZMB', label: 'Zambia' },
        { value: 'ZWE', label: 'Zimbabwe' }
    ];

    const [dropDown, setdropDown] = useState(false);
    const handleClosedropdown = () => {
        setdropDown(false);
    }

    const [selectedOption, setSelectedOption] = useState(null);
    const handleSelect = (option) => {
        let newSelection;
        if (selected.includes(option.value)) {
            newSelection = selected.filter(item => item !== option.value);
        } else {
            newSelection = [...selected, option.value];
        }
        onChange(newSelection);
    };

    useEffect(() => {
        if (onBoarding) {
            document.documentElement.style.setProperty('--dropdown-border', onBoarding.color || 'transparent');
        }
    }, [onBoarding]);

    const dropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setdropDown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className={Classes.dropDown} ref={dropdownRef}>
                {/* Dropdown Head */}
                <div
                    className={Classes.dropDownHead}
                    onClick={() => setdropDown(!dropDown)}
                >
                    <p style={{ textTransform: 'capitalize' }} > Select </p>
                    <img src='/onBoarding/arrow-down.svg' alt='delete' style={{ height: '10px', transform: dropDown ? 'rotate(180deg)' : '' }} />
                </div>

                {/* Dropdown Body */}
                <AnimatePresence>
                    {dropDown && (
                        <motion.div
                            initial={{ opacity: 0, y: -5 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -5 }}
                            transition={{
                                duration: 0.15,
                            }}
                            className={Classes.dropDownBody}
                        >
                            <div className={Classes.ddPointerCon}>
                                <div className={Classes.dropDownPointer}></div>
                            </div>
                            <div className={Classes.dropDownBodyInner}>
                                <div>
                                    {countryOptions.map((option, index) => (
                                        <div
                                            key={index}
                                            className={`${Classes.dropDownElem} ${selected.includes(option.value) ? Classes.activeDropElem : ''}`}
                                        >
                                            <label htmlFor={index} >
                                                <h5>{option.label}</h5>
                                            </label>
                                            <Checkbox
                                             checked={selected.includes(option.value)}
                                             onChange={() => handleSelect(option)}
                                            
                                                id={index}
                                                sx={{
                                                    color: onBoarding.color,
                                                    padding: '5px',
                                                    '&.Mui-checked': {
                                                        color: onBoarding.color,
                                                    },
                                                    '& .MuiSvgIcon-root': { fontSize: 17 }
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
                <input
                    type="hidden"
                    aria-label="Without label"
                    readOnly
                />
            </div>
        </>
    )
}
