import LogoutIcon from "@mui/icons-material/Logout";
import React, { useEffect, useState } from "react";
import { LinearProgress, Paper, Button, Modal } from '@mui/material';
import StructureIcon from '@mui/icons-material/AccountBalance';
import MarketIcon from '@mui/icons-material/ShowChart';
import BusinessIcon from '@mui/icons-material/MonetizationOn';
import CloseIcon from '@mui/icons-material/Close';

import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { authActions } from "../../redux-store";
import Typography from "@mui/material/Typography";
import TelegramIcon from "@mui/icons-material/Telegram";
import NotesIcon from "@mui/icons-material/Notes";
import FolderIcon from "@mui/icons-material/Folder";
import backend from "../../api";
import HistoryIcon from "@mui/icons-material/History";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import sessionstorage from "sessionstorage";
import { setChatHistoryId } from "../../redux-store/auth/actions";
import { setSidebar } from "../../redux-store/auth/actions";
import DashboardIcon from '@mui/icons-material/Dashboard';
import './Sidebar.css'
import Classes from './Sidebar.module.css'
import UpgradePopup from "../../On-boarding/Global-Components/UpgradePopup/UpgradePopup";
import PitchDashboard from "../../Pages/Pitch-Dashboard/Pitch-Dashboard";
import { setCompanyInfo } from "../../redux-store/auth/actions";

const drawerWidth = 265;
const ScoringIcon = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_4791884"
      {...props}
    >
      <g id="review">
        <path d="M19 2H5C3.3457031 2 2 3.3457031 2 5v11c0 1.6542969 1.3457031 3 3 3h4.4648438l1.703125 2.5546875c.1860351.2783203.4980468.4453125.8320312.4453125h.0297852c.3452148-.0107422.6601563-.1982422.8339844-.4960938l1.4604492-2.5039062h4.6757812c1.6542969 0 3-1.3457031 3-3V5c0-1.6542969-1.3457031-3-3-3zM16.8320313 9.4550781l-1.3544922 2.03125.6923828 2.7714844c.0947266.3789063-.0390625.7783203-.34375 1.0224609-.3066406.2451172-.7255859.2871094-1.0732422.1142578l-2.7529297-1.3759765-2.7529297 1.3759766c-.1416015.0703125-.2949219.1054687-.4472656.1054687-.2231445 0-.4448242-.0742188-.6259766-.2197266-.3041992-.2451172-.4389648-.6435547-.3442383-1.0224609l.6928711-2.7714844-1.3544922-2.03125c-.2045898-.3066406-.2236328-.7011718-.0498046-1.0263672.1743164-.3251953.5131836-.5283203.8818359-.5283203h1.8647461l1.3032227-1.9550781c.1855468-.2783203.4975585-.4453125.8320312-.4453125s.6464844.1669922.8320313.4453125l1.3027344 1.9550781h1.8652343c.3691406 0 .7080078.203125.8818359.5283203s.1542969.7197266-.0498046 1.0263672z"></path>
      </g>
    </svg>
  )
}
// The rest of your components remain unchanged
function ScorerDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navBtn = sessionstorage.getItem("navBtn")
  const [selectedButton, setSelectedButton] = React.useState(navBtn ? navBtn : "Pitch Deck Dashboard");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [selectFinal, setSelectFinal] = useState(false);

  const userID = user?._id;

  console.log(user);
  const [loading, setLoading] = React.useState(false);
  const BotID = localStorage.getItem('projectID');
  const ProjectName = localStorage.getItem('projectName');
  const ProjectID = localStorage.getItem("projectID")

  const [openUploadPopup, setopenUploadPopup] = useState(false);
  const closeUploadPopup = () => {
    setopenUploadPopup(false);
  }

  const [pitchDeckScore, setPitchDeckScore] = useState('');
  const [deckStructureScore, setDeckStructureScore] = useState('');
  const [marketPotentialScore, setMarketPotentialScore] = useState('');
  const [businessModelScore, setBusinessModelScore] = useState('');

  const [deckStructureParagraph, setDeckStructureParagraph] = useState('');
  const [marketPotentialParagraph, setMarketPotentialParagraph] = useState('');
  const [businessModelParagraph, setBusinessModelParagraph] = useState('');
  const sideBarValue = useSelector((state) => state.sidebar.sidebarValue);
  const deckId = useSelector((state) => state.deckId.deckId);
  const onBoarding = useSelector((state) => state.companyInfo.info);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpgradePopup, setopenUpgradePopup] = useState(false);
  const closeUpgradePopup = () => {
    setopenUpgradePopup(false);
  }

  const navigateTo = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const settingClicked = async () => {
    sessionstorage.setItem("navBtn", 'Setting')
    navigateTo("/setting");
    // alert("Signed out successfully")
  };

  const companyId = user?.companyId;
  useEffect(() => {
    const fetchData = async () => {
      try {

        // Check if companyName exists in database
        const response = await backend.get(`/onboarding/bycompany?companyId=${companyId}`);
        // console.log(response.data[0])
        console.log(response)
        // If response.data is not empty, companyName exists in database
        if (response.data.length > 0) {
          await dispatch(setCompanyInfo(response.data[0]))
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [companyId]); // Empty dependency array ensures useEffect runs once on component mount



  const NavigateToBots = async () => {
    sessionstorage.setItem("navBtn", "")
    const id = localStorage.getItem('projectID');
    await navigateTo(`/chatbots/${id}`)
  };


  console.log(sideBarValue)
  const saveChat = async () => {
    const userId = user._id

    try {
      const body = {
        UserId: userId,
        projectId: ProjectID
      };

      const response = await backend.post("/chats", body);
      // setLoading(false);
      console.log(response)
      const RouteID = response?.data?.data?._id
      // localStorage.setItem("chatId", RouteID)
      dispatch(setChatHistoryId(RouteID))
      await UpdateProjectById(RouteID)
      // navigateTo(`/chat/${ProjectID}/${RouteID}`)

    } catch (error) {
      console.error(error);
      // toast.error("Error saving story");
    }

  };

  const UpdateProjectById = async (newChatData) => {

    console.log(newChatData)

    const id = await user?._id;
    try {
      // Fetch the existing project data
      setLoading(true);
      const response = await backend.get(`/mainfolder/${ProjectID}`);
      console.log(response?.data)
      const existingProjectData = response?.data;

      // Modify the chats array by merging new data with the existing data
      const updatedChats = [...existingProjectData.chats, newChatData];

      // Prepare the updated project body
      const body = {
        ...existingProjectData,
        chats: updatedChats,
      };
      // console.log(typeof(ProjectID))
      // Make the PUT request to update the project
      const resp = await backend.put(`/mainfolder/${ProjectID}`, body);

      console.log(resp)
      setLoading(false);
      // Optionally, you can return the updated project data
      navigateTo(`/chat/${ProjectID}/${newChatData}`)
    } catch (error) {
      // Handle error
      console.error("Error updating project:", error.message);
      throw error;
    }
  };

  const s3FileUrl = localStorage.getItem('pitch');


  //   useEffect(() => {
  //     if (selectedButton === 'Business Chatbot') {
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       navigateTo(`/chatbot`)
  //     }
  //     if (selectedButton === 'Pitch Deck Analyzer') {
  //       if (s3FileUrl) {
  //         sessionstorage.setItem("navBtn", selectedButton)

  //         dispatch(setChatHistoryId(''))
  //         // navigateTo(`/analyzer`)
  //         navigateTo(`/analyzer/${deckId}`);
  //         console.log('ANALYZINGGGG');
  //       } else {
  //         sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")
  //         setSelectedButton("Pitch Deck Dashboard")

  //         dispatch(setChatHistoryId(''))
  //         navigateTo(`/dashboard`)
  //       }

  //     }
  //     if (selectedButton === 'Chat') {
  //       // navigateTo('/chat')
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       saveChat();
  //     }

  //     if (selectedButton === 'Pitch Deck Editor') {
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       dispatch(setChatHistoryId(''))
  //       navigateTo(`/pitcheditor/`)
  //     }
  //     if (selectedButton === 'Pitch Deck Dashboard') {
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       navigateTo(`/dashboard`)
  //     }
  //     if (selectedButton === 'Pitch Deck Templates') {
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       navigateTo(`/templates/`)
  //     }
  //     if (selectedButton === 'Testing') {
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       navigateTo(`/testing/`)
  //     }
  //     if (selectedButton === 'Testing-1') {
  //       sessionstorage.setItem("navBtn", selectedButton)
  //       navigateTo(`/testing1/`)
  //     }


  //   }, [selectedButton])

  const handleLogout = async () => {
    localStorage.clear();
    sessionstorage.clear();
    navigateTo("/");
    await dispatch(authActions.logout());
    // window.location.reload();
    // alert("Signed out successfully")
  };


  const handleHomeClick = async () => {
    sessionstorage.setItem("navBtn", 'Pitch Deck Dashboard')
    sessionstorage.setItem("refresh", 'yes')
    navigateTo("/");
    // alert("Signed out successfully")
  };

  const drawer = (
    <div style={{
      background: "white",
      // color: "rgba(255, 255, 255, 0.6)",
      minHeight: "100vh",
      // width: "360px", // Set a fixed width for the sidebar
    }}
      className="element"
    >
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <a className={Classes.logo} onClick={() => handleHomeClick()}>
          <img src={onBoarding.logo} alt="logo" />
        </a>
      </Toolbar>

      <div className="li-items545" >
        {/* <List>
          {[
            "Pitch Deck Dashboard",
            "Pitch Deck Templates",
            "Pitch Deck Analyzer",
            "Business Chatbot"

          ].map((text, index) => (
            <ListItem key={text} style={{
              width: "100%", background:
                selectedButton === text
                  ? "#D6E4F8"
                  : "transparent",
              borderRight: selectedButton === text ? "6px solid #0262DF" : "",
              padding: "0.45rem 0.5rem"

            }}>
              <ListItemButton
                onClick={() => { index === 2 && !s3FileUrl ? setSelectedButton("Pitch Deck Dashboard") : setSelectedButton(text) }}
                style={{
                  background: "transparent",
                  color: "black",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  padding: "0rem 1rem"
                }}
                disabled={index === 10 && !s3FileUrl ? true : false}
                disableRipple // Disables the ripple effect
                disableTouchRipple // Disables the ripple effect on touch devices
              >
                <ListItemIcon style={{ padding: "0rem 0rem" }}>
                  {index === 0 ? (
                    <img src={selectedButton === text ? "/icons/dashboard.png" : "/icons/dashboard-dark.png"} alt="dashboard"
                      style={{
                        width: "17px",
                        height: "17px",

                      }}
                    />
                  ) : index === 1 ? (
                    <img src={selectedButton === text ? "/icons/deck.png" : "/icons/templates-dark.png"} alt="templates"
                      style={{
                        width: "19px",
                        height: "17px",
                      }}
                    />
                  ) : index === 2 ? (
                    <img src={selectedButton === text ? "/icons/analyzer.png" : "/icons/analyzer-dark.png"} alt="analyzer"
                      style={{
                        width: "19px",
                        height: "19px",
                      }}
                    />
                  ) : index === 3 ? (
                    <img src={selectedButton === text ? "/icons/coach.png" : "/icons/coach-dark.png"} alt="coach"
                      style={{
                        width: "17px",
                        height: "18px",
                      }}
                    />
                  ) : index === 4 ? (
                    <img src="/icons/presentation.png" alt="presentation"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : index === 5 ? (
                    <img src="/CustomQA.png" alt="Custom Q&A"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : index === 5 ? (
                    <img src="/inbox.png" alt="Inbox Icon"
                      style={{
                        width: "17px",
                        height: "17px",
                      }}
                    />
                  ) : (
                    <FolderSpecialIcon />
                  )}
                </ListItemIcon>
                <Typography
                  style={{
                    fontWeight: selectedButton === text ? "500" : "500",
                    fontSize: "14px",
                    fontFamily: "Poppins, sans-serif",
                    color: selectedButton === text ? "#0262DF" : 'black',
                    marginLeft: "-1.8rem"
                  }}
                >
                  {text}

                </Typography>
              </ListItemButton>

            </ListItem>
          ))}

        </List> */}
        <div className={Classes.listItem} onClick={() => { navigateTo('/evaluator') }} >
          <ScoringIcon fill={onBoarding?.color} height="12px" />
          <h5 style={{ color: onBoarding?.color }} >Evaluation</h5>
        </div>
        {/* <div className={Classes.listItem} >
          <img src='/onBoarding/star.png' />
          <h5>Select final deck</h5>
        </div> */}
        <br />
      </div>
      <div style={{ paddingBottom: "1rem", position: "absolute", bottom: 0, width: '100%' }}>
        {/* {openUpgradePopup && <UpgradePopup closeUpgradePopup={closeUpgradePopup} closeable={true} />} */}

        {/* {
          user.subscription === 'free' ?
            <div className={Classes.premiumBox} >
              <h5>Unlock all features to perfect your pitch deck</h5>
              <div onClick={() => { setopenUpgradePopup(true) }} className={Classes.premiumBtn} >
                <img src="/icons/premium.png" alt="premium icon" />
                <span>Upgrade to premium</span>
              </div>
            </div> : null
        } */}

        <div style={{ paddingLeft: "1rem", paddingRight: '1rem' }} >
          <Divider style={{ margin: '0 9px 0 9px', marginBottom: "1rem" }} />
          {/* <a onClick={settingClicked} style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <svg fill={'black'} height="17px" style={{ marginLeft: '8px' }} xmlns="http://www.w3.org/2000/svg" cursor="pointer" viewBox="0 0 512 512">
              <path fill="#000000" d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
            </svg>
            <a style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Settings</a>
          </a> */}

          <a href="/contact-us" style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <img src="/icons/support.png" style={{ color: "black", width: "19px", height: "19px", marginLeft: "0.5rem" }} />
            <a style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Support</a>
          </a>

          {/* <div style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }}>
            <img src="/icons/billing.png" style={{ color: "black", width: "19px", height: "19px", marginLeft: "0.5rem" }} />
            <a href="https://billing.stripe.com/p/login/14k7vweFEeiNf9meUU" style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Billing</a>
          </div> */}

          <div style={{ cursor: "pointer", display: "flex", alignItems: "center", marginBottom: "1rem" }} onClick={() => handleLogout()}>
            <img src="/icons/logout.png" style={{ color: "black", width: "17px", height: "18px", marginLeft: "0.7rem" }} />
            <a style={{ fontSize: "15px", paddingLeft: "0.8rem", color: "black", fontWeight: "500" }}>Logout</a>
          </div>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }} className="element">
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ background: "transparent", color: "black", height: "0px" }}
      >
        {/* <Divider /> */}
        <Toolbar sx={{ mr: 2, display: { sm: "none" } }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
        style={{
          // boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.07)", // Adding box shadow to the right
          borderRight: '0px solid transparent',
          border: 'none',
          minHeight: "100vh",
          zIndex: '5',
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: '0px solid transparent',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          background: '#F8F8F8',
          flexGrow: 1,
          p: 0,
          // pt: selectedButton === 'Business Chatbot' ? 0 : 5,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "100vh",
              alignItems: "center",
            }}
          >
            {/* <CircularProgress /> */}
            <img src="/loading-2.gif" alt="gif" />
          </div>
        ) : (
          props.component
        )}
      </Box>
      {/* Include your MenuFooter component here */}
    </Box>

  );
}

ScorerDrawer.propTypes = {
  window: PropTypes.func,
};

export default ScorerDrawer;
