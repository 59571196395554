import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import sessionstorage from 'sessionstorage';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';
import { motion } from 'framer-motion'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NotifyCompanyPopup({ closePopup, openEmailEditor }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const navigateTo = useNavigate();

    console.log(openEmailEditor)

    const handleBgClick = () => {
        closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const sendEmail = () => {

       

    }

    const navigateToSetting = (e) => {
        closePopup();
        sessionstorage.setItem("navBtn", "Setting")
        navigateTo('/setting')
    }

    usePreventScrolling();

    return (
        <>


<motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.close} onClick={closePopup} >
                        <svg height='20px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <h5 className={Classes.title} > Notify? </h5>
                        <div className={Classes.curveCon} >

                        </div>
                    </div>
                    <form>
                        {
                            onBoarding?.email && onBoarding?.app_password ?
                                <div className={Classes.cardCon} >
                                    <h5 className={`${Classes.text2} ${Classes.m1r}`} >
                                        Click continue to email and notify this<br /> company with your decision.
                                    </h5>
                                    <button type='button' onClick={() =>{ openEmailEditor(); closePopup()}} className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} > <h5>Continue</h5> </button>
                                    <h5 className={`${Classes.text2}`} style={{ cursor: 'pointer' }} onClick={closePopup} >
                                        Skip
                                    </h5>
                                </div> :
                                <div className={Classes.cardCon} >
                                    <h5 className={`${Classes.text2} ${Classes.m1r}`} >
                                        Connect your email to send this company a <br /> message with your decision.
                                    </h5>
                                    <button type='button' onClick={() => navigateToSetting()} className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} > <h5>Continue</h5> </button>
                                    <h5 className={`${Classes.text2}`} onClick={closePopup} >
                                        Skip
                                    </h5>
                                </div>
                        }
                    </form>
                </motion.div>
            </motion.div>
        </>
    )
}

export default NotifyCompanyPopup
