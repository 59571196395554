import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AnimatePresence, motion } from 'framer-motion';
import {usePreventScrolling} from '../../../Hooks/usePreventScrolling';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSvg = (props) => (
    <svg
        version="1.1"
        id="fi_179386"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 286.054 286.054"
        className="custom-svg"
        xmlSpace="preserve"
        {...props}

    >
        <g>
            <path
                d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
          c78.996,0,143.027-64.022,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236
          c-64.183,0-116.209-52.026-116.209-116.209S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209
          S207.21,259.236,143.027,259.236z M143.036,62.726c-10.244,0-17.995,5.346-17.995,13.981v79.201c0,8.644,7.75,13.972,17.995,13.972
          c9.994,0,17.995-5.551,17.995-13.972V76.707C161.03,68.277,153.03,62.726,143.036,62.726z M143.036,187.723
          c-9.842,0-17.852,8.01-17.852,17.86c0,9.833,8.01,17.843,17.852,17.843s17.843-8.01,17.843-17.843
          C160.878,195.732,152.878,187.723,143.036,187.723z"
            />
        </g>
    </svg>
);

function SizeLimitReachedPopup({ closePopup, title, description }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    usePreventScrolling();

    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const handleConfirmSubmit = (e) => {
        e.preventDefault();
        closePopup();
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.close} onClick={closePopup} >
                        <svg height='20px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg>
                    </div>
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <CustomSvg height='3.5rem' fill={'white'} />
                        <div className={Classes.curveCon} >

                        </div>
                    </div>
                    <form onSubmit={handleConfirmSubmit} >
                        <div className={Classes.cardCon} >
                            {title &&
                                <h5 className={Classes.title} > {title} </h5>
                            }
                            {description &&
                                <h5 className={`${Classes.text2} ${Classes.m1r}`} >
                                    {description}
                                </h5>
                            }
                            <div className={Classes.flexAlign} >
                                <button type='submit' className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} > <h5>Return</h5> </button>
                            </div>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
        </>
    )
}

export default SizeLimitReachedPopup
