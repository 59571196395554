import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

export default function TextReader() {
    const companyInfo = useSelector((state) => state.companyInfo?.info);
    
    // Initialize editorState
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    useEffect(() => {
        // Check if there is content in regMsg and parse it
        if (companyInfo?.regMsg?.value) {
            const rawContent = JSON.parse(companyInfo.regMsg.value);
            const contentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    }, [companyInfo?.regMsg?.value]);

    return (
        <div>
            {/* Render the Editor in read-only mode */}
            <Editor editorState={editorState} readOnly={true} />
        </div>
    );
}
