import React, { useState } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion'
import backend from '../../api';
import RegMsg from './Comp/RegMsg';
import CloseSubmission from './Comp/CloseSubmission';
import EnableVideo from './Comp/EnableVideo';
import ContinueDashboard from './Comp/ContinueDashboard';
import CriteriaSelection from './Comp/CriteriaSelection';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';

function OnBoardingSuccessPopup({ closePopup, title, description, buttonText }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const companyId = onBoarding?._id;

    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    usePreventScrolling();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (onBoarding?.isFirstInteraction) {
            await backend.put(`/onboarding/${companyId}`, {
                isFirstInteraction: false
            });
        }
        closePopup();
    }

    const [activeMsg, setActiveMsg] = useState(1);
    const [direction, setDirection] = useState("forward"); // Track direction

    const handleNext = () => {
        setDirection("forward");
        setActiveMsg(prev => prev + 1);
    };

    const handleBack = () => {
        setDirection("backward");
        setActiveMsg(prev => prev - 1);
    };

    return (
        <>

            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: -7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    {activeMsg > 4 &&
                        <div className={Classes.close} onClick={handleSubmit} >
                            <img src='/icons/close.png' alt='close icon' />
                        </div>
                    }
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <h5 className={Classes.title} > Let’s get started! </h5>
                        <div className={Classes.curveCon} ></div>
                    </div>
                    <div>
                        {activeMsg === 1 ? (
                            <CriteriaSelection callingFrom="onBoarding" handleNext={handleNext} handleBack={handleBack} activeMsg={activeMsg} direction={direction} />
                        ) : activeMsg === 2 ? (
                            <AnimatePresence>
                                <RegMsg handleNext={handleNext} handleBack={handleBack} activeMsg={activeMsg} direction={direction} />
                            </AnimatePresence>
                        ) : activeMsg === 3 ? (
                            <CloseSubmission handleNext={handleNext} handleBack={handleBack} activeMsg={activeMsg} direction={direction}
                                setActiveMsg={setActiveMsg} />
                        ) : activeMsg === 4 ? (
                            <AnimatePresence>
                                <EnableVideo handleNext={handleNext} handleBack={handleBack} activeMsg={activeMsg} direction={direction} />
                            </AnimatePresence>
                        ) : (
                            <ContinueDashboard handleNext={handleNext} handleBack={handleBack} activeMsg={activeMsg} direction={direction}
                                handleSubmit={handleSubmit} />
                        )}
                    </div>
                </motion.div>
            </motion.div>
        </>
    )
}

export default OnBoardingSuccessPopup
