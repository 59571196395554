import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import Classes from '../styles/style.module.css'
import { motion } from 'framer-motion'
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import backend from '../../../api';
import { setCompanyInfo } from '../../../redux-store/auth/actions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import DateTimePickup from '../../../Pages/Setting/Components/DateTimePickup';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const TickSvg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 448 512"><path fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
)

export default function CloseSubmission({ handleNext, handleBack, activeMsg, direction, setActiveMsg }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [closeSubmission, setCloseSubmission] = useState(onBoarding?.closeSubmission?.isActive ?? false);

    const dispatch = useDispatch();
    const onboardingId = onBoarding?._id;
    const companyId = onBoarding?._id;

    const dateObject = onBoarding?.closeSubmission?.value ?? new Date();
    const dayjsObject = dayjs(dateObject);
    const [DateValue, setDateValue] = useState(dayjsObject);

    const handleToggleCloseSubmission = async () => {
        const newStatus = !closeSubmission;  // Toggle the status
        setCloseSubmission(newStatus);

        // Make the API request to update isActive status
        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                closeSubmission: {
                    isActive: newStatus,
                },
            });
            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))

            }

        } catch (error) {
            console.error("Error updating close submission status:", error);
        }
    };

    const [closeSbmissionSuccess, setcloseSbmissionSuccess] = useState(false);
    const [closeSbmissionLoading, setcloseSbmissionLoading] = useState(false);
    const handleConfirmCloseSubmission = async () => {
        const dateToStore = DateValue.toDate();
        setcloseSbmissionLoading(true);
        try {
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                closeSubmission: {
                    value: dateToStore,
                    isActive: closeSubmission,
                },
            });

            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))
            }
            setActiveMsg(prev => prev + 1);
            setcloseSbmissionLoading(false);
            setcloseSbmissionSuccess(true);
            setTimeout(() => {
                setcloseSbmissionSuccess(false);
            }, 5000);
            // alert('Close Submission updated successfully!');
        } catch (error) {
            console.error("Error updating close submission:", error);
        }
    };

    return (
        <>
            <motion.div
                initial={{ x: direction === "forward" ? 20 : -20, opacity: 0 }} // Forward moves left-to-right, backward moves right-to-left
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: direction === "forward" ? -20 : 20, opacity: 0 }}
                transition={{ duration: 0.25 }}
                className={Classes.cardCon}
            >
                <h5 className={`${Classes.text2} ${Classes.m1r}`}>
                    Set a date and time to stop accepting new <br /> registrations.
                </h5>
                <div className={Classes.miniCon}>
                    <h5 className={Classes.lightText}>Close submissions</h5>
                    <FormGroup>
                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }}
                                checked={closeSubmission}
                                onChange={handleToggleCloseSubmission}
                            />}
                        />
                    </FormGroup>
                </div>
                {closeSubmission &&
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'fit-content', overflow: 'hidden' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{
                            duration: 0.15,
                        }}
                    >
                        <div className={Classes.flexAlign} >
                            <DateTimePickup DateValue={DateValue} setDateValue={setDateValue} sx={{ padding: 0 }} />
                            <button className={Classes.confirmBtn} style={{ background: onBoarding?.color }}
                                onClick={handleConfirmCloseSubmission}
                            >
                                <TickSvg height="14px" width="14px" />
                                <h5> {closeSbmissionLoading ? 'Confirming...' : 'Confirm'} </h5>
                            </button>
                        </div>
                    </motion.div>
                }
                <div className={Classes.flexAlign}>
                    <button type="button" onClick={handleBack} className={Classes.btn1} style={{ border: `1px solid ${onBoarding.color}` }}>
                        <h5 style={{ color: onBoarding.color || "red" }}> Back </h5>
                    </button>
                    <button type="button" onClick={handleNext} className={Classes.btn2} style={{ background: onBoarding.color || "black", border: `1px solid ${onBoarding.color}` }}>
                        <h5> Next </h5>
                    </button>
                </div>
            </motion.div>
        </>
    )
}
