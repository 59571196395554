import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { usePreventScrolling } from '../../Hooks/usePreventScrolling';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PitchDeckSuccessPopup({ closePopup, title, description, buttonText }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    const handleBgClick = () => {
        // closePopup();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    usePreventScrolling();

    const handleSubmit = (e) => {
        e.preventDefault();

        closePopup();
    }

    const SvgComponent = (props) => (
        <svg
            height="40"
            width="40"
            {...props}
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            id="fi_1828640"
            style={{ marginBottom: '0.5rem' }}
        >
            <path
                d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0"
            />
            <path
                d="m385.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0"
                fill={onBoarding?.color}
            />
        </svg>
    )

    return (
        <>
            <div className={Classes.popup} onClick={handleBgClick} >
                <div className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }} >
                        <div></div>
                        <SvgComponent fill={'white'} />
                        <div className={Classes.curveCon} >

                        </div>
                    </div>
                    <form onSubmit={handleSubmit} >
                        <div className={Classes.cardCon} >
                            <h5 className={Classes.title} >{title}</h5>
                            <h5 className={`${Classes.text2} ${Classes.m1r}`} >
                                {description}
                            </h5>
                            <button type='submit' className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }} > <h5>{buttonText}</h5> </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default PitchDeckSuccessPopup
