import React, { useEffect, useState } from 'react';
import Classes from './styles/style.module.css'
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useDispatch, useSelector } from 'react-redux';
import backend from '../../api/index';
import { setCompanyInfo } from '../../redux-store/auth/actions';
import { motion } from 'framer-motion'
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';

const SvgX = (props) => (
    <svg
        id="fi_2723639"
        viewBox="0 0 32 32"
        height="25"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 3"
        {...props}
        fill='white'
        cursor='pointer'
    >
        <path d="M17.414 16l6.293-6.293a1 1 0 0 0-1.414-1.414l-6.293 6.293-6.293-6.293a1 1 0 0 0-1.414 1.414l6.293 6.293-6.293 6.293a1 1 0 1 0 1.414 1.414l6.293-6.293 6.293 6.293a1 1 0 0 0 1.414-1.414z" />
    </svg>
);

const SvgArrow = (props) => (
    <svg {...props} fill='white' height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_10322482"><path d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z"></path></svg>
);

const SvgMinus = (props) => (
    <svg
        id="fi_2801932"
        height="20"
        viewBox="0 0 6.35 6.35"
        width="22"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsSvg="http://www.w3.org/2000/svg"
        {...props}
        fill='white'
        cursor='pointer'
    >
        <g id="layer1" transform="translate(0 -290.65)">
            <path
                id="path2047"
                d="m.79427278 293.56039a.2646485.2646485 0 0 0 0 .52917h4.76146822a.2646485.2646485 0 0 0 0-.52917z"
                fontVariantLigatures="normal"
                fontVariantPosition="normal"
                fontVariantCaps="normal"
                fontVariantNumeric="normal"
                fontVariantAlternates="normal"
                fontFeatureSettings="normal"
                textIndent="0"
                textAlign="start"
                textDecorationLine="none"
                textDecorationStyle="solid"
                textDecorationColor="rgb(0,0,0)"
                textTransform="none"
                textOrientation="mixed"
                whiteSpace="normal"
                shapePadding="0"
                isolation="auto"
                mixBlendMode="normal"
                solidColor="rgb(0,0,0)"
                solidOpacity="1"
                vectorEffect="none"
            />
        </g>
    </svg>
);

const TickSvg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 448 512"><path fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
)

function MsgEditorPopup({ closePopup, enableRegistration }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [subject, setsubject] = useState('');
    const [email, setemail] = useState('');
    const [selection, setselection] = useState('');
    const [loading, setLoading] = useState(false);

    const handleBgClick = () => {
        // closePopup();
    }

    usePreventScrolling();

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const companyInfo = useSelector((state) => state.companyInfo?.info);
    const { user } = useSelector((state) => state.auth);
    const [brochureLoading, setBrochureLoading] = useState(false);

    const [companyName, setcompanyName] = useState(companyInfo?.companyName);
    const onboardingId = companyInfo?._id;

    const dispatch = useDispatch();

    const [registrationMsg, setRegisterationMsg] = useState(companyInfo?.regMsg?.value ?? "");
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    useEffect(() => {
        // Check if there is content in regMsg and parse it
        if (companyInfo?.regMsg?.value) {
            const rawContent = JSON.parse(companyInfo.regMsg.value);
            const contentState = convertFromRaw(rawContent);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    }, [companyInfo?.regMsg?.value]);

    // Handle style changes (e.g., bold, italic)
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    // Apply inline style (bold, italic, underline)
    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    // Apply block type (bullet or numbered list)
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const handleUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };

    const handleRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const [msgLoading, setmsgLoading] = useState(false);
    const [msgSuccess, setmsgSuccess] = useState(false);
    const handleConfirmRegistrationMsg = async () => {
        setmsgLoading(true);
        try {
            const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            console.log(rawContent);
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                regMsg: {
                    value: rawContent,
                    isActive: enableRegistration,
                },
            });
            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))
                setmsgSuccess(true);
                setTimeout(() => {
                    setmsgSuccess(false);
                }, 5000);
            }
        } catch (error) {
            console.error("Error updating registration message:", error);
        } finally {
            setmsgLoading(false);
            closePopup();
        }
    };

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} // Animation when component enters
                animate={{ opacity: 1 }}   // Animation when component is open
                exit={{ opacity: 0 }}    // Animation when component exits
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: -7 }} // Animation when component enters
                    animate={{ y: 0 }}   // Animation when component is open
                    exit={{ y: 7 }}    // Animation when component exits
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={(event) => { handleCardClick(event) }}
                >
                    <div className={Classes.header} style={{ background: onBoarding.color ? onBoarding.color : "black" }}>
                        <div className={Classes.flexAlign} >
                            <SvgArrow />
                            <h5 className={Classes.title} > Enable registration message </h5>
                        </div>
                        <div className={Classes.flexAlignEnd} >
                            {/* <SvgMinus /> */}
                            <SvgX onClick={closePopup} />
                        </div>
                    </div>
                    <div className={Classes.body}  >
                        <div className={Classes.toolbar}>
                            <div className={Classes.toolbarCategory} >
                                <button onClick={() => toggleInlineStyle('BOLD')}>
                                    <img src='/bold.png' alt='bold' />
                                </button>
                                <button onClick={() => toggleInlineStyle('ITALIC')}  >
                                    <img src='/italic.png' alt='italic' style={{ height: '14px' }} />
                                </button>
                                <button onClick={() => toggleInlineStyle('UNDERLINE')} >
                                    <img src='/underline.png' alt='underline' style={{ height: '15px' }} />
                                </button>
                                <button onClick={() => toggleInlineStyle('STRIKETHROUGH')} >
                                    <img src='/strikethrough.png' alt='strikethrough' style={{ height: '14px' }} />
                                </button>
                                <button onClick={() => toggleInlineStyle('CODE')} >
                                    <img src='/coding.png' alt='coding' style={{ height: '16px' }} />
                                </button>
                                <button onClick={() => toggleBlockType('code-block')}>
                                    <img src='/code-block.png' alt='code-block' style={{ height: '17px' }} />
                                </button>
                            </div>
                            <div className={Classes.toolbarCategory} >
                                <button onClick={() => toggleBlockType('header-one')} >
                                    <img src='/h1.png' alt='h1' />
                                </button>
                                <button onClick={() => toggleBlockType('header-two')} >
                                    <img src='/h2.png' alt='h2' />
                                </button>
                                <button onClick={() => toggleBlockType('header-three')} >
                                    <img src='/h3.png' alt='h3' />
                                </button>
                                <button onClick={() => toggleBlockType('header-four')} >
                                    <img src='/h4.png' alt='h4' />
                                </button>
                                <button onClick={() => toggleBlockType('header-five')} >
                                    <img src='/h5.png' alt='h5' />
                                </button>
                                <button onClick={() => toggleBlockType('blockquote')}>
                                    <img src='/quotes.png' alt='quotes' style={{ height: '15px' }} />
                                </button>
                                <button onClick={() => toggleBlockType('unordered-list-item')}>
                                    <img src='/bullet-list.png' alt='bullet-list' style={{ height: '16px' }} />
                                </button>
                                <button onClick={() => toggleBlockType('ordered-list-item')}>
                                    <img src='/ordered-list.png' alt='ordered-list' style={{ height: '17px' }} />
                                </button>
                            </div>
                            <div className={Classes.toolbarCategory} >
                                <button onClick={handleUndo}>
                                    <img src='/undo.png' alt='ordered-list' style={{ height: '17px' }} />
                                </button>
                                <button onClick={handleRedo}>
                                    <img src='/redo.png' alt='ordered-list' style={{ height: '17px' }} />
                                </button>
                            </div>
                        </div>
                        <hr className={Classes.line} />
                        <div className={`${Classes.inputField} ${Classes.editor}`} >
                            <Editor
                                editorState={editorState}
                                handleKeyCommand={handleKeyCommand}
                                onChange={setEditorState}
                                placeholder="Enter your message..."
                            />
                        </div>
                        <hr className={Classes.line} />
                        <div className={Classes.flexBetween} >
                            <div></div>
                            <button onClick={handleConfirmRegistrationMsg} disabled={msgLoading} className={Classes.btn} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                                <TickSvg height="14px" width="14px" />
                                <h5> {msgLoading ? 'Confirming...' : 'Confirm'} </h5>
                            </button>
                            {/* <div className={Classes.flexAlign} >
                                {msgSuccess && <h5 style={{ color: 'green' }} >Successful</h5>}
                            </div> */}
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </>
    )
}

export default MsgEditorPopup
