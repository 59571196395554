import React from 'react';
import Classes from './ReportPopup.module.css';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ApexChart from './ApexChart';
import { motion } from 'framer-motion';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';

export default function ReportPopup({ handleClose, deckData, totalScore }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);

    usePreventScrolling();

    const criteriaList = [
        { name: 'deckStructure', title: 'Deck Structure', icon: '/deck.png' },
        { name: 'marketOpportunity', title: 'Market Potential', icon: '/market.png' },
        { name: 'marketingSales', title: 'Marketing & Sales', icon: '/onBoarding/marketing.png' },
        { name: 'businessModel', title: 'Business Model', icon: '/business.png' },
        { name: 'competitiveLandscape', title: 'Competitive Landscape', icon: '/onBoarding/competitive.png' },
        { name: 'team', title: 'Team', icon: '/onBoarding/team.png' },
        { name: 'traction', title: 'Traction', icon: '/onBoarding/traction.png' },
    ];

    const handleBgClick = () => {
        handleClose();
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    const generatePDF = () => {
        const input = document.getElementById('reportContent');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 295; // A4 height in mm
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('report.pdf');
        });
    };

    return (
        <motion.div
            initial={{ opacity: 0 }} // Animation when component enters
            animate={{ opacity: 1 }}   // Animation when component is open
            exit={{ opacity: 0 }}    // Animation when component exits
            transition={{ duration: 0.2 }}
            className={Classes.popup}
            onClick={handleBgClick}
        >
            <motion.div
                initial={{ y: 7 }} // Animation when component enters
                animate={{ y: 0 }}   // Animation when component is open
                exit={{ y: 7 }}    // Animation when component exits
                transition={{ duration: 0.25 }}
                className={Classes.card}
                onClick={(event) => { handleCardClick(event) }}
            >
                <div className={Classes.close} onClick={handleClose}>
                    <img src='/icons/close.png' alt='close icon' />
                </div>
                <div className={Classes.header}>
                    <img src={onBoarding.logo} style={{ height: '4rem' }} alt='Company logo' />
                    <div>
                        <h5 className={Classes.subtitle}>
                            {onBoarding?.companyName.charAt(0).toUpperCase() + onBoarding?.companyName.slice(1)} Report
                        </h5>
                        <button onClick={generatePDF} className={Classes.downloadBtn} style={{ background: onBoarding.color ? onBoarding.color : "black" }} > <h5>Download</h5> </button>
                    </div>
                </div>
                <div className={Classes.body} id="reportContent">
                    <h5 className={Classes.title}>Overall score</h5>
                    <h5 className={Classes.subtitle}>Overall deck score: <span style={{ fontWeight: '800' }} >{totalScore}</span> </h5>
                    <hr className={Classes.line} />

                    {criteriaList.map((criterion) => {
                        const criterionData = onBoarding?.scoringCriteria?.find(c => c.name === criterion.name);
                        const scoreKey = `${criterion.name}ScoreMatch`;
                        const score = criterion.name === "marketOpportunity"
                            ? deckData?.[`marketPotentialScoreMatch`] || 0
                            : deckData?.[scoreKey] || 0;
                        let text = criterion.name === "marketOpportunity"
                            ? deckData?.[`marketPotentialParagraphMatch`] || ''
                            : deckData?.[`${criterion.name}ParagraphMatch`] || '';
                        if (criterion.name === "businessModel" && text) {
                            const regex = /Marketing Sales score:.*/s;
                            text = text.replace(regex, '').trim(); // Remove the matched text and trim whitespace
                        }

                        return (
                            <div key={criterion.name}>
                                <div className={Classes.flexAlign}>
                                    <img src={criterion.icon} className={Classes.logoImg} />
                                    <h5 className={Classes.subtitle}>
                                        {criterion.title}: <span style={{ color: score >= 75 ? '#00B900' : '#FF9900' }}>{score.slice(0, 2)}</span>
                                    </h5>
                                </div>
                                <h5 className={Classes.text}>
                                    {text}
                                </h5>
                            </div>
                        );
                    })}


                    <hr className={Classes.line} />
                    <h5 className={Classes.title}>Chart</h5>
                    <ApexChart onBoarding={onBoarding} deckData={deckData} />
                </div>
            </motion.div>
        </motion.div>
    )
}
