import React, { useState } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import Classes from '../Setting.module.css' // Add your custom styles
import { height } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import backend from '../../../api';
import { setCompanyInfo } from '../../../redux-store/auth/actions';
const TickSvg = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 448 512"><path fill="white" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
)

const TextEditor = ({ enableRegistration }) => {
    const companyInfo = useSelector((state) => state.companyInfo?.info);
    const { user } = useSelector((state) => state.auth);
    const [brochureLoading, setBrochureLoading] = useState(false);

    const [companyName, setcompanyName] = useState(companyInfo?.companyName);
    const onboardingId = companyInfo?._id;

    const dispatch = useDispatch();

    const [registrationMsg, setRegisterationMsg] = useState(companyInfo?.regMsg?.value ?? "");
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

    // Handle style changes (e.g., bold, italic)
    const handleKeyCommand = (command) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    // Apply inline style (bold, italic, underline)
    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    // Apply block type (bullet or numbered list)
    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const handleUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };

    const handleRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const [msgLoading, setmsgLoading] = useState(false);
    const [msgSuccess, setmsgSuccess] = useState(false);
    const handleConfirmRegistrationMsg = async () => {
        setmsgLoading(true);
        try {
            const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
            console.log(rawContent);
            const response = await backend.put(`/onboarding/${onboardingId}`, {
                regMsg: {
                    value: rawContent,
                    isActive: enableRegistration,
                },
            });
            console.log('Success:', response);
            if (response?.data) {
                await dispatch(setCompanyInfo(response?.data?.data))
                setmsgSuccess(true);
                setTimeout(() => {
                    setmsgSuccess(false);
                }, 5000);
            }
        } catch (error) {
            console.error("Error updating registration message:", error);
        } finally {
            setmsgLoading(false);
        }
    };

    return (
        <div className="editor-container">
            {/* Buttons for text styling */}
            <div className="toolbar">
                {/* Inline Style Buttons */}
                <button onClick={() => toggleInlineStyle('BOLD')}>Bold</button>
                <button onClick={() => toggleInlineStyle('ITALIC')}>Italic</button>
                <button onClick={() => toggleInlineStyle('UNDERLINE')}>Underline</button>
                <button onClick={() => toggleInlineStyle('STRIKETHROUGH')}>Strikethrough</button>
                <button onClick={() => toggleInlineStyle('CODE')}>Code</button>

                {/* Block Type Buttons */}
                <button onClick={() => toggleBlockType('header-one')}>H1</button>
                <button onClick={() => toggleBlockType('header-two')}>H2</button>
                <button onClick={() => toggleBlockType('header-three')}>H3</button>
                <button onClick={() => toggleBlockType('blockquote')}>Blockquote</button>
                <button onClick={() => toggleBlockType('unordered-list-item')}>Bullet List</button>
                <button onClick={() => toggleBlockType('ordered-list-item')}>Numbered List</button>
                <button onClick={() => toggleBlockType('code-block')}>Code Block</button>

                {/* Undo/Redo */}
                <button onClick={handleUndo}>Undo</button>
                <button onClick={handleRedo}>Redo</button>
            </div>

            {/* Draft.js Editor */}
            <div className={`${Classes.inputField} ${Classes.editor}`} >
                <Editor
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={setEditorState}
                    placeholder="Enter your message..."
                />
            </div>
            <div className={`${Classes.flexEnd} ${Classes.mt5}`} >
                <div className={Classes.flexAlign} >
                    {msgSuccess && <h5 style={{ color: 'green' }} >Successful</h5>}
                    <button className={Classes.confirmBtn} style={{ background: companyInfo?.color }}
                        onClick={handleConfirmRegistrationMsg}
                    >
                        <TickSvg height="14px" width="14px" />
                        <h5> {msgLoading ? 'Confirming...' : 'Confirm'} </h5>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TextEditor;
