import React, { useState, useEffect, useRef } from 'react'
import Classes from '../styles/style.module.css'
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import backend from '../../../api';
import CommentElem from './CommentElem';
import { updateDeckInfo } from '../../../redux-store/auth/actions';


const SvgArrow = (props) => (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_10322482"><path d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z"></path></svg>
);

export default function CommentComp({ deck }) {
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const { user } = useSelector((state) => state.auth);
    const userId = user._id;

    const [isCommentText, setisCommentText] = useState(false);
    const CommentText = useRef(null);

    const dispatch = useDispatch();

    const feedback = () => {
        const userFeedback = deck?.feedback?.find(feedback => feedback.userId._id === userId);
        return userFeedback ? { comment: userFeedback.comment, score: userFeedback.score } : null;
    };

    const feedbackData = feedback();

    console.log(feedbackData)

    const [comment, setComment] = useState(null);


    const handleChangeComment = async (e) => {
        e.preventDefault();


        const body = {
            userId: userId,
            comment: comment
        };

        console.log(feedback)

        if (comment == null) {
            console.log('Please enter comment')
            return;
        }
        try {
            // Send a PUT request to update the feedback
            const resp = await backend.post(`/deck/admin/comments/${deck._id}`, body);

            if (resp.status === 200 && resp.data) {
                console.log(resp.data);
                setComment(null)
                await dispatch(updateDeckInfo(resp?.data?.data));
            }
            // closePopup();
        } catch (error) {
            console.error('Error updating feedback:', error);
            // Handle error (e.g., show a notification)
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (CommentText.current && !CommentText.current.contains(event.target)) {
                setisCommentText(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    return (
        <motion.div
            initial={{ opacity: 0 }} // Animation when component enters
            animate={{ opacity: 1 }}   // Animation when component is open
            exit={{ opacity: 0 }}    // Animation when component exits
            transition={{ duration: 0.3 }}
            className={Classes.cardCon}
        >
            <div className={Classes.cardScroll2} >
                <CommentElem deck={deck} />
            </div>
            <motion.div
                initial={{ height: '4rem' }}
                animate={{ height: isCommentText ? '8rem' : '4rem' }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                className={Classes.addComment}
                onClick={() => { setisCommentText(true) }}
                ref={CommentText}
            >
                <textarea
                    placeholder='Add a comment'
                    autoCorrect={false}
                    spellCheck={false}
                    draggable={false}
                    value={comment || ''} // Ensure comment is a string
                    onChange={(e) => setComment(e.target.value)}
                >
                </textarea>

                <SvgArrow height='18px' cursor='pointer' fill={onBoarding?.color} onClick={handleChangeComment} />
            </motion.div>
        </motion.div>
    )
}
