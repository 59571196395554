import React, { useState, useEffect } from 'react'
import Classes from './styles/style.module.css'
import {
    Button,
    ListItem,
    ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from '../../Pages/Home-New/Components/Animations/AnimateTopToBottom';
import StripeCheckout from 'react-stripe-checkout';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import backend from '../../api';
import { setUserData } from "../../redux-store/auth/actions";
import axios from 'axios';
import {usePreventScrolling} from '../../Hooks/usePreventScrolling';

const STRIPE_PUBLISHABLE_KEY = "pk_live_51OpvvzErETeoVarWzeVWtLtgwQOOKUK5HQGhbtbouFXAy03F93yduaJGLsNzhnVS7FRSyyQo7SnIfypJoXrADcQY00tBoarr6w"

function UpgradePopup({ closeUpgradePopup, closeable }) {
    const [isPro, setisPro] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const companyId = onBoarding?._id;

    usePreventScrolling();

    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    // const onToken = (amount, description) => (token) => {
    //     // debugger
    //     const headers = {
    //         "Content-Type": "application/json",
    //     };
    //     backend
    //         .post(
    //             "/payment",
    //             { description, token: token, amount: amount },
    //             { headers: headers }
    //         )
    //         .then((response) => {
    //             alert("payment success");
    //             onPayment();
    //         })
    //         .catch((err) => {
    //             console.log("error: ", err);
    //             alert("payment failure", err);
    //         });
    // };

    const onPaymentBasic = async () => {

        const total_users = onBoarding?.total_users
        const remaining_users = 100;
        const companyName = onBoarding?.companyName

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await backend.post(`/create-checkout-session`, {
            subscription: "yearly_basic",
            total_users: total_users,
            remaining_users: remaining_users,
            companyId: companyId,
            companyName: companyName
        })

        const url = response.data.url;
        window.location.href = url;
    }

    const onPaymentPro = async () => {

        const total_users = onBoarding?.total_users
        const remaining_users = 1000;
        const companyName = onBoarding?.companyName

        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await backend.post(`/create-checkout-session`, {
            subscription: "yearly_pro",
            total_users: total_users,
            remaining_users: remaining_users,
            companyId: companyId,
            companyName: companyName

        })

        const url = response.data.url;
        window.location.href = url;
    }


    const renderFeature = (included, text) => (
        <ListItem style={{ padding: "0.1rem" }}>
            <ListItemIcon>
                {included ? (
                    <>
                        {/* <CheckIcon style={{ color: '#0262DF', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} /> */}
                        <svg height="20px" width="20px" viewBox="0 0 512 512" fill={onBoarding.color} xmlns="http://www.w3.org/2000/svg" id="fi_1828739"><path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm129.75 201.75-138.667969 138.664062c-4.160156 4.160157-9.621093 6.253907-15.082031 6.253907s-10.921875-2.09375-15.082031-6.253907l-69.332031-69.332031c-8.34375-8.339843-8.34375-21.824219 0-30.164062 8.339843-8.34375 21.820312-8.34375 30.164062 0l54.25 54.25 123.585938-123.582031c8.339843-8.34375 21.820312-8.34375 30.164062 0 8.339844 8.339843 8.339844 21.820312 0 30.164062zm0 0"></path></svg>
                    </>
                ) : (
                    <>
                        {/* <ClearIcon style={{ color: '#0262DF', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} /> */}
                        <svg height="21px" width="21px" viewBox="0 0 24 24" fill='red' xmlns="http://www.w3.org/2000/svg" id="fi_3416079"><g id="Layer_2" data-name="Layer 2"><path d="m12 1a11 11 0 1 0 11 11 11.013 11.013 0 0 0 -11-11zm4.242 13.829a1 1 0 1 1 -1.414 1.414l-2.828-2.829-2.828 2.829a1 1 0 0 1 -1.414-1.414l2.828-2.829-2.828-2.829a1 1 0 1 1 1.414-1.414l2.828 2.829 2.828-2.829a1 1 0 1 1 1.414 1.414l-2.828 2.829z"></path></g></svg>
                    </>
                )}
            </ListItemIcon>

            <p className={Classes.card1_p} >{text}</p>
        </ListItem>
    );

    const handleBgClick = () => {
        if (closeable) {
            closeUpgradePopup();
        }
    }

    const handleCardClick = (event) => {
        event.stopPropagation();
    }

    return (
        <div className={Classes.popup} onClick={handleBgClick} >
            <div className={Classes.card} onClick={(event) => { handleCardClick(event) }} >
                {closeable &&
                    <div className={Classes.close} onClick={closeUpgradePopup} >
                        <img src='/icons/close.png' alt='close icon' />
                    </div>
                }
                <div className={Classes.header} >
                    <div className={Classes.crownIcon} style={{ background: onBoarding.color ? onBoarding.color : "black" }} >
                        <svg id="fi_2476413" enable-background="new 0 0 32 32" fill='white' viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m27.488 23-.513 2.225c-.105.454-.509.775-.975.775h-20c-.466 0-.87-.321-.975-.775l-.513-2.225z"></path><path d="m29.975 12.225-2.025 8.775h-23.9l-2.025-8.775c-.089-.387.059-.791.378-1.028.32-.237.749-.262 1.093-.065l6.189 3.537 5.482-8.223c.179-.268.475-.434.796-.446.324-.014.629.132.826.386l6.429 8.266 5.227-3.484c.341-.226.786-.224 1.123.009.338.233.498.649.407 1.048z"></path></svg>
                    </div>
                    <h2 className={Classes.planName} >Upgrade or book demo</h2>
                </div>
                <div className={Classes.flex} >
                    <div className={Classes.pricingBtns} >
                        <button onClick={() => setisPro(false)} className={!isPro ? Classes.activeButton : Classes.inactiveButton}
                            style={{ background: !isPro ? onBoarding.color : '' }}
                        >
                            Basic
                        </button>
                        <button onClick={() => setisPro(true)} className={isPro ? Classes.activeButton : Classes.inactiveButton}
                            style={{ background: isPro ? onBoarding.color : '' }}
                        >
                            Pro
                        </button>
                        {/* <img src='/save15.png' alt='save-15' className={Classes.saveImage} /> */}
                    </div>
                </div>
                <a href='https://calendly.com/pitchscreenerai/30min?primary_color=0262df' target='_blank' >
                    <h5 style={{ color: onBoarding.color ? onBoarding.color : "red" }} className={Classes.demoText} >Book demo call</h5>
                </a>
                {isPro ?
                    <h1 className={Classes.price} > $2,500 <span>/year</span> </h1>
                    :
                    <h1 className={Classes.price} > $500 <span>/year</span> </h1>
                }
                <ul className={Classes.featureList}>
                    {renderFeature(true, `${isPro ? '1,000' : '100'} companies`)}
                    {renderFeature(true, "Unique landing page")}
                    {renderFeature(true, "White-label screening tool")}
                    {renderFeature(true, "Personalized AI scoring")}
                    {renderFeature(true, "Comprehensive deck analysis")}
                </ul>
                {isPro ?
                    onBoarding?.subscription === 'yearly_pro' ?
                        <button
                            className={Classes.premiumBtn}
                            style={{ background: onBoarding.color ? onBoarding.color : "black" }}
                        >
                            Current Plan
                        </button>
                        :
                        <button
                            onClick={onPaymentPro}
                            className={Classes.premiumBtn}
                            style={{ background: onBoarding.color ? onBoarding.color : "black" }}
                        >
                            Upgrade now
                        </button>
                    :
                    onBoarding?.subscription === 'yearly_basic' ?
                        <button
                            className={Classes.premiumBtn}
                            style={{ background: onBoarding.color ? onBoarding.color : "black" }}
                        >
                            Current Plan
                        </button>
                        :
                        <button
                            onClick={onPaymentBasic}
                            className={Classes.premiumBtn}
                            style={{ background: onBoarding.color ? onBoarding.color : "black" }}
                        >
                            Upgrade now
                        </button>
                }
            </div>
        </div>
    )
}

export default UpgradePopup
