import React, { useState, useEffect, useRef } from 'react';
import Classes from './styles/style.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import backend from '../../../api';
import { updateDeckInfo } from '../../../redux-store/auth/actions';

const SvgArrow = (props) => (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="fi_10322482">
        <path d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z"></path>
    </svg>
);

function ScoringRatePopup({ closePopup, deck }) {
    const dispatch = useDispatch(); // For dispatching the feedback update action
    const onBoarding = useSelector((state) => state.companyInfo.info);
    const [updatedDeck, setUpdateDeck] = useState();
    const { user } = useSelector((state) => state.auth);
    const userId = user?._id;

    const feedback = () => {
        const userFeedback = deck?.feedback?.find(feedback => feedback.userId._id === userId);
        return userFeedback ? { comment: userFeedback.comment, score: userFeedback.score } : null;
    };

    const feedbackData = feedback();

    console.log(feedbackData)

    const [score, setScore] = useState(feedbackData?.score ?? null);
    const [comment, setComment] = useState(feedbackData?.comment ?? null);
    
    const inputRef = useRef(null);

    const handleFocus = () => {
        inputRef.current.focus();
    };

    const handleChangeComment = async (e) => {
        e.preventDefault();
    
        const feedback = {
            userId: userId,
            score: score,
            comment: comment
        };
    
        console.log(feedback)
        try {
            // Send a PUT request to update the feedback
            const resp = await backend.put(`/deck/feedback/${deck._id}`, { feedback });
            
            if (resp.status === 200 && resp.data) {
                console.log(resp.data);
                setUpdateDeck(resp.data.data)
                await dispatch(updateDeckInfo(resp?.data?.data));
            }
            // closePopup();
        } catch (error) {
            console.error('Error updating feedback:', error);
            // Handle error (e.g., show a notification)
        }
    };


    const handleChangeScore = async () => {
        const feedback = {
            userId: userId,
            score: score,
            comment: comment
        };
    
        try {
            // Send a PUT request to update the feedback
            const resp = await backend.put(`/deck/feedback/${deck._id}`, { feedback });
    
            if (resp.status === 200 && resp.data) {
                console.log(resp.data);
                setUpdateDeck(resp.data.data);
                await dispatch(updateDeckInfo(resp?.data?.data));
            }
            // closePopup();
        } catch (error) {
            console.error('Error updating feedback:', error);
            // Handle error (e.g., show a notification)
        }
    };
    
    useEffect(() => {
        
        const updateScore = async () => {
            await handleChangeScore();
        };
        if(score !=null ) {
            updateScore();
        }
       
    }, [score]);
    


    const handleBgClick = async() => {
        closePopup();
        await dispatch(updateDeckInfo(updatedDeck));
        
    };

    const handleCardClick = (event) => {
        event.stopPropagation();
    };

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }}   
                exit={{ opacity: 0 }}    
                transition={{ duration: 0.2 }}
                className={Classes.popup}
                onClick={handleBgClick}
            >
                <motion.div
                    initial={{ y: 7 }} 
                    animate={{ y: 0 }}   
                    exit={{ y: 7 }}    
                    transition={{ duration: 0.25 }}
                    className={Classes.card}
                    onClick={handleCardClick}
                >
                    <div className={Classes.close} onClick={closePopup}>
                        <svg height='22px' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                        </svg>
                    </div>
                    <div className={Classes.bgPopup} style={{ background: onBoarding?.color }}>
                        <div></div>
                        <h5 className={Classes.title}>{deck?.userId?.companyName}</h5>
                        <div className={Classes.curveCon}></div>
                    </div>
                    <form>
                        <div className={Classes.cardCon}>
                            <h5 className={`${Classes.text2} ${Classes.m1r}`}>
                                Rate {deck?.userId?.companyName} based on the provided information and scoring criteria.
                            </h5>
                            <input
                                ref={inputRef}
                                type='number'
                                value={score}
                                className={Classes.scoreInputText}
                                onChange={(e) => setScore(e.target.value)}
                                placeholder='00'
                            />
                            <h5 className={Classes.changeScoreTxt} onClick={handleFocus} style={{ color: onBoarding?.color }}>
                                Change score
                            </h5>
                            <h5 className={Classes.text4}>What are the main reasons for your rating?</h5>
                            <div className={Classes.addComment}>
                                <textarea
                                    type='text'
                                    placeholder='Add a comment'
                                    autoCorrect={false}
                                    spellCheck={false}
                                    draggable={false}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                ></textarea>
                                <SvgArrow height='18px' cursor='pointer' fill={onBoarding?.color} onClick={handleChangeComment} />
                            </div>
                            <div className={Classes.flexAlign}>
                                <button onClick={closePopup} type='button' className={Classes.btn2} style={{ background: onBoarding.color ? onBoarding.color : "black", border: `1px solid ${onBoarding.color}` }}>
                                    <h5>Continue to dashboard</h5>
                                </button>
                            </div>
                        </div>
                    </form>
                </motion.div>
            </motion.div>
        </>
    );
}

export default ScoringRatePopup;
